import React, { Component , Fragment } from "react";
import TeamFull from "../elements/Team_full";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import FooterFloat from "../component/footer/FooterFloat";
import Header from "../component/header/Header";
import { FiCheck, FiClock, FiUser, FiMonitor, FiRadio, FiDownload, FiVideo, FiCheckCircle, FiPackage} from "react-icons/fi";
import { GoRocket } from "react-icons/go";
import { GiPlatform } from "react-icons/gi";
import { SiZoom } from "react-icons/si";
import { FaGraduationCap, FaPhone, FaRunning, FaEye, FaWhatsapp, FaPaypal } from "react-icons/fa";
import { BsCircle, BsArrowLeftCircle, BsSun, BsFillPlayCircleFill, BsCloudSunFill } from "react-icons/bs";
import { IoCloseCircleOutline } from "react-icons/io5";
import { AiOutlineDown, AiOutlineRight, AiOutlineClose } from "react-icons/ai";
import { MdWork, MdEmail, MdPayments, MdModeNight } from "react-icons/md";
import ModalVideo from 'react-modal-video';
import Typical from 'react-typical';
import Modal from "react-bootstrap/Modal";
import 'custom-react-accordion/dist/Accordion.css'
import NonPassiveTouchTarget from "./NonPassiveTouchTarget";
import TouchCarousel, { clamp } from "react-touch-carousel";
import touchWithMouseHOC from "react-touch-carousel/lib/touchWithMouseHOC";
import "./styles.css";
import data from "./data";
import data2 from "./data2";
import cx from "classnames";
import AOS from 'aos';
import 'aos/dist/aos.css';
import TestimonialOne from "../blocks/testimonial/TestimonialOne";
import ReactCountryFlag from "react-country-flag";
import axios from 'axios';
import Selectrix from "react-selectrix";
import 'react-toastify/dist/ReactToastify.css';
import TestimonialTree from "../blocks/testimonial/TestimonialTree";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Spinner from 'react-bootstrap/Spinner';
import ReactPlayer from "react-player";
import FlipCountdown from '@rumess/react-flip-countdown';
import Preguntas from "../component/dojopy/Preguntas";
import Plataforma from "../component/dojopy/Plataforma";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PreguntasFlex from "../component/dojopy/PreguntasFlex";


const cardSize = 300;
const cardSize2 = 280;
const cardPadCount = 2;
const carouselWidth = clamp(window.innerWidth, 0, 960);

const FECHAS_EVENTO = [
    'Hoy, 7:00 PM',
    'Jueves, 14 de diciembre, 7:00 PM',
    'Viernes, 15 de diciembre, 7:00 PM',
]


AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 10, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  });

// const then = moment('05 26 2019, 6:00 am', 'MM DD YYYY, h:mm a');
// const now = moment();
// const countdown = moment(then - now);
// const days = countdown.format('D');
// const hours = countdown.format('HH');
// const minutes = countdown.format('mm');
// const seconds = countdown.format('ss');



// const Turnos =

const KeyCountry =  [
    {key: "PE", label: "Perú"},
    {key: "MX", label: "México"},
    {key: "BO", label: "Bolivia"},
    {key: "EC", label: "Ecuador"},
    {key: "PR", label: "Puerto Rico"},
    {key: "SV", label: "El Salvador"},
    {key: "CL", label: "Chile"},
    {key: "CR", label: "Costa Rica"},
    {key: "HN", label: "Honduras"},
    {key: "ES", label: "España"},
    {key: "UY", label: "Uruguay"},
    {key: "CO", label: "Colombia"},
    {key: "GT", label: "Guatemala"},
    {key: "PA", label: "Panamá"},
    {key: "NI", label: "Nicaragua"},
    {key: "PY", label: "Paraguay"},
    {key: "DO", label: "República Dominicana"},
    {key: "BR", label: "Brasil"},
    {key: "US", label: "Estados Unidos"},
    {key: "CA", label: "Canada"},
]

function CarouselContainer(props) {
    const {
      cursor,
      carouselState: { active, dragging },
      ...rest
    } = props;
    let current = -Math.round(cursor) % data.length;
    while (current < 0) {
      current += data.length;
    }
    // Put current card at center
    const translateX =
      (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2;
    return (
      <NonPassiveTouchTarget
        className={cx("carousel-container", {
          "is-active": active,
          "is-dragging": dragging
        })}
      >
        <NonPassiveTouchTarget
          className="carousel-track"
          style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
          {...rest}
        />

        <div className="carousel-pagination-wrapper">
          <ol className="carousel-pagination">
            {data.map((_, index) => (
              <li key={index} className={current === index ? "current" : ""} />
            ))}
          </ol>
        </div>
      </NonPassiveTouchTarget>
    );
}


function CarouselContainer2(props) {
    const {
      cursor,
      carouselState: { active, dragging },
      ...rest
    } = props;
    let current = -Math.round(cursor) % data2.length;
    while (current < 0) {
      current += data2.length;
    }
    // Put current card at center
    const translateX =
      (cursor - cardPadCount) * 300 + (carouselWidth - 300) / 2;
    return (
      <NonPassiveTouchTarget
        className={cx("carousel-container", {
          "is-active": active,
          "is-dragging": dragging,
        })}
        style={{height: "450px"}}
      >
        <NonPassiveTouchTarget
          className="carousel-track"
          style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
          {...rest}
        />

        <div className="carousel-pagination-wrapper">
          <ol className="carousel-pagination">
            {data2.map((_, index) => (
              <li key={index} className={current === index ? "current" : ""} />
            ))}
          </ol>
        </div>
      </NonPassiveTouchTarget>
    );
  }

const Container = touchWithMouseHOC(CarouselContainer);
const Container2 = touchWithMouseHOC(CarouselContainer2);


// const SlideList = [
//     {
//         textPosition: 'text-center',
//         category: '',
//         title: 'Desarrollo <br/> Web Full Stack a medida y escalable',
//         description: '',
//         buttonText: 'INSCRÍBETE HOY',
//         buttonLink: '#project'
//     }
// ]



const InfoAcordeon = {
    nodejs: "Es ideal para manejar aplicaciones de alto tráfico de usuarios y eventos, como por ejemplo Twitter donde a cada segundo se envían cientos de miles de tuits. El desarrollo de aplicaciones es mucho más rápido, las aplicaciones también y por lo tanto el usuario puede acceder a una mejor experiencia de uso",
    database: "Es muy importante saber qué, cómo y dónde se guardarán tus datos. Aprende desde cero los fundamentos y práctica para administrar DBs profesionalmente, aprende bases de datos Relacionales y No Relacionales.",
    web: "En este módulo aprenderás a crear tu sitio web partiendo del prototipo en papel. Te sumergirás en las mejores prácticas del desarrollo web, trabajando con HTML y CSS. Conocerás herramientas para optimizar al máximo tu sitio web, implementando prácticas de versionado de código con GIT, y preprocesadores como SASS. Al finalizar, sabrás cómo aplicar Bootstrap a tus proyectos, y comprenderás lo importante del SEO en tus desarrollos. Subirás tu sitio a un servidor, y aprenderás a interactuar con este servicio. También sabrás cómo presentar un presupuesto y atender a tu cliente final.  ",
    js: "En este módulo aprenderás los fundamentos del lenguaje de programación más usado en la actualidad, con el cual es posible crear aplicaciones de todo tipo. Explorarás inicialmente herramientas propias del mismo, indagando casos prácticos de aplicación y cómo aplicar técnicas de desarrollo para apps modernas con AJAX. Al graduarte, estarás en condiciones de crear soluciones web interactivas, y trasladar los conocimientos del curso a cualquier framework JavaScript.  ",
    react: "En este módulo, que es el tercer nivel del Bootcamp, aprenderás a programar por componentes, mediante Javascript y React, y también conocerás las ventajas de la utilización del flujos de datos. Comprenderás la utilización del virtual DOM mediante los desarrollos de React JS. Al finalizar, podrás crear tus propias aplicaciones SPA, y estarás en condiciones de hacer cualquier desarrollo con uno de los frameworks más populares y avanzados del momento.",
    python: "Python es uno de los lenguajes de programación más valorados y usados a nivel mundial según estadísticas de Stack Overflow la plataforma más grande de programadores, multitud de Startups y empresas lo utilizan en su día a día como Instagram, Spotify, Amazon, Netflix, Google, Facebook, Dropbox, Youtube, etc,  Python es flexible, claro y con un código legible para todos, tambien trabajaremos con el Framework Django.",
    git: "Aprende todas las herramientas necesarias para dominar el mundo de la programación colaborativa. Aprenderás las bases y conceptos esenciales que necesitas para trabajar en proyectos profesionales, ya sea en una empresa, conjunto de programadores. Con este módulo aprenderás todo lo necesario para involucrarte en cualquier grupo de programadores.",
    project: "El proyecto final se desarrolla en el sexto mes y sirve para certificar el aprendizaje poniéndolo en práctica creando una réplica de una empresa o Startup real como MercadoLibre, Instagram o Airbnb, con tus mentores. El proyecto final es una herramienta increíble para agregar a tu portafolio y así mostrar tu trabajo en tus próximas oportunidades laborales 😎!",
    deploy_aws: "Amazon Web Services (AWS) es una de las principales plataformas de servicios de computación en la nube a nivel mundial, en este Bootcamp desplegaremos el proyecto final en esta infraestructura muy potente, con tecnología de vanguardia, seguridad, confiabilidad, y escalabilidad.",
    serverless: "La arquitectura serverless también conocida como FaaS (Functions as a Service), habilita la ejecución de una aplicación mediante contenedores temporales y sin estado; estos son creados en el momento en el que se produce un evento que dispare dicha aplicación.",
    employer: "Al finalizar del bootcamp, nuestro equipo de talento te asesorará para lograr tus objetivos laborales.",
    api: "En este módulo de API REST con Django explorarás los conceptos fundamentales de las APIs REST, como los métodos HTTP, los recursos, los endpoints y la autenticación. Aprenderás cómo diseñar una API RESTful siguiendo las mejores prácticas, manteniendo la coherencia y facilitando la interacción con otras aplicaciones y servicios. ",
    apiSecure: "En este módulo aprenderás a implementar pruebas automatizadas para verificar el correcto funcionamiento de tu código, así como a identificar y solucionar posibles vulnerabilidades y riesgos de seguridad en tu aplicación. Este módulo te dotará de las habilidades necesarias para garantizar la confiabilidad y protección de tus proyectos web, ofreciendo una experiencia segura y libre de errores para los usuarios finales.",
}


class BootcampFlex extends Component{
    constructor(props) {
        super(props);
        this.state = {
            langUS: false,
            isBeca50: false,
            Turnos: [
                {key: "noche", label: "Noche"},
                {key: "dia", label: "Mañana"},
            ],
            bootcampSlug: 'fullstack-flex',
            bootcampSlugPre: 'fullstack-flex',
            bootcampSlugMensual: 'webfullstack-mensual',
            IsEspecial24h: false,
            FooterColor: 'linear-gradient(to right, slateblue, rgb(40, 2, 17))',
            FechaEspecial24h: '',
            timestamp24h: "",
            isPricePais: false,
            semibeca_activo: false,
            semibeca_img: "",
            isMount: false,
            beca: '',
            audio: '',
            isPlayed: false,
            pricingAPI: {},
            fecha_start_end: "",
            isPriceEspecial: false,
            PriceEspecialBody: "",
            PriceEspecialPreBody: "",
            timePrueba: "",
            DataFechas: [],
            mesesInicio: [],
            fechaLimite: "",
            showWasapInput: false,
            Eventos: {},
            selectWebinarFecha: 0,
            utc_offset: '-0500',
            Plan: 1,
            formaPago: 1,
            utc_dif: 0,
            select_mes: "",
            select_turno: "",
            select_modalidad: "",
            Email: "",
            Name: "",
            LastName: "",
            Phone: "",
            fechaSelect: "fecha_1",
            isOpenTemario: false,
            isOpenSemiBeca: false,
            isOpenSuscribe: false,
            isOpen0: false,
            isOpen: false,
            isOpen2: false,
            isOpen3: false,
            isOpenResume: false,
            isOpenResumeFlex: false,
            countryCode: "US",
            price_cuota: "",
            price_cuota_2: "",
            price_cuota_22: "",
            price_completo: "",
            price_completo_regular: "",
            price_mensual: "",
            price_preinscripcion: "",
            price_mensual_regular: "",
            titlePrice: "",
            price_ahorro: "",
            descuento: "",
            price_payment: "1",
            salario_fullstack_jr: "US$ 14,000 ANUAL",
            salario_fullstack_md: "US$ 24,000 ANUAL",
            salario_fullstack_sr: "+US$ 40,000 ANUAL",
            usa_salario_fullstack_jr: "US$ 80,000 ANUAL",
            usa_salario_fullstack_md: "US$ 100,000 ANUAL",
            usa_salario_fullstack_sr: "+US$ 140,000 ANUAL",
            isOpenCheckout: false,
            days: 0,
            mes: 1,
            hours: 0,
            minutes: 0,
            seconds: 0,
            enrollMes: "",
            enrollDay: "",
            photoIndex: 0,
            isOpenImg: false,
            MesAcceso: null,
            loadingEnroll: false,
            isCuota: 1 ,
            showMore: false,
            showMoreA: false,
            showMoreB: false,
            showMoreC: false,
            showMoreD: false,
            showMoreE: false,
            showMoreF: false,
            showMoreG: false,
            showMoreI: false,
            showPhoneField: false,
            VideoPitch: false,
            sendDataForm: false,
            isOpenShowPrueba: false,
            agendarActive: false,
            planPagoUSD: "",
            planPagoPE: "",
            isOpenPlanPay: false,
            DescuentoBody: "",
            price_mount: "",
            selectFecha: false,
            DescuentoBodyUSD: "",
            DescuentoBodyPEN: "",
            isOpenReunion: false,
            cuotaDolar: '',
            cuotaSoles: '',
            isOpenModalInfo: false,
            isModalWS: false,
            isFormSend: false,
            priceConversion: ""
        };
        this.openModalEmail = this.openModalEmail.bind(this);
        this.openModalWebinar = this.openModalWebinar.bind(this);
        this.openModalCheckout = this.openModalCheckout.bind(this);
        // this.openModalSuscribe = this.openModalSuscribe.bind(this);
        this.openModalVid = this.openModalVid.bind(this);
        this.selectBoot = this.selectBoot.bind(this);
        this.openModal0 = this.openModal0.bind(this);
        this.openModal = this.openModal.bind(this);
        this.openModal2 = this.openModal2.bind(this);
        this.openModal3 = this.openModal3.bind(this);
        this.openModal4 = this.openModal4.bind(this);
        this.openModalPrueba = this.openModalPrueba(this);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitBeca = this.onSubmitBeca.bind(this);
        this.onRenderOption = this.onRenderOption.bind(this);
        this.OpenWS = this.OpenWS.bind(this);
        this.GetLinkWS = this.GetLinkWS.bind(this);
        this.FechaEnroll = this.FechaEnroll.bind(this);
        this.setEmailValidate = this.setEmailValidate.bind(this);
        this.searchEvento = this.searchEvento.bind(this);
        this.DynamicCountry = this.DynamicCountry.bind(this);
        this.fetchPricingData = this.fetchPricingData.bind(this);



       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);

    //    window.addEventListener('load', function() {
    //     document.title = 'Desarrollo Web Full Stack Bootcamp | 100% online en vivo | DojoFullStack';
    //     })

    }

    // async customInit(engine) {
    //     // this adds the bundle to tsParticles
    //     await loadFull(engine);
    //   }

    // async particlesLoaded(container) {
    //     console.log('');
    // };


    TrackEventConversion(){
        try {
            window.fbq("track", "contact");
            window.gtag_report_conversion();
        } catch (error) {
            console.log(error);
        }
    }


    DynamicCountry(country='' ){

        window.urlWasi = this.GetLinkWS();

        const newCountry = country || this.state.countryCode;

        if (country){
            this.setState({countryCode: country});
        }

        
        this.setState({
            select_mes:  this.state.pricingAPI[this.state.bootcampSlug]['fechas'][0]['mes'],
            isPricePais: false,
            utc_offset: '-0500',
            utc_dif: 0,
            timestamp24h: this.state.pricingAPI[this.state.bootcampSlug]['timestamp24h'],
            semibeca_img: this.state.pricingAPI[this.state.bootcampSlug]['semibeca_img'],
            DataFechas: this.state.pricingAPI[this.state.bootcampSlug]['fechas'],
            mesesInicio: this.state.pricingAPI[this.state.bootcampSlug]['mesesInicio'],
            Turnos: this.state.pricingAPI[this.state.bootcampSlug]['turnos'],
            fechaLimite: this.state.pricingAPI[this.state.bootcampSlug]['fechaLimite'],
            isPriceEspecial: this.state.pricingAPI[this.state.bootcampSlug]['isPriceEspecial'],
            PriceEspecialBody: this.state.pricingAPI[this.state.bootcampSlug]['PriceEspecialBody'],
            PriceEspecialPreBody: this.state.pricingAPI[this.state.bootcampSlug]['PriceEspecialBody'],
            timePrueba: this.state.pricingAPI[this.state.bootcampSlug]['time_prueba'],
            FooterColor: this.state.pricingAPI[this.state.bootcampSlug]['FooterColor'],
            price_completo: this.state.pricingAPI[this.state.bootcampSlug]['price']['usd']['price_view_base'], //495
            price_completo_regular: this.state.pricingAPI[this.state.bootcampSlug]['price']['usd']['price_view_base_regular'],
            price_mensual: this.state.pricingAPI[this.state.bootcampSlugMensual]['price']['usd']['price_view_final'],
            price_mensual_regular: this.state.pricingAPI[this.state.bootcampSlugMensual]['price']['usd']['price_view_base_regular'],
            titlePrice: this.state.pricingAPI[this.state.bootcampSlug]['titlePrice'],
            titlePricePre: this.state.pricingAPI[this.state.bootcampSlug]['titlePrice'],
            price_payment: "1",
            salario_fullstack_jr: "US$ 14,000 ANUAL",
            salario_fullstack_md: "US$ 24,000 ANUAL",
            salario_fullstack_sr: "+US$ 40,000 ANUAL",
            planPagoUSD: this.state.pricingAPI[this.state.bootcampSlug]['planPagoUSD'],
            planPagoPE: this.state.pricingAPI[this.state.bootcampSlug]['planPagoPE'],
            DescuentoBody: this.state.pricingAPI[this.state.bootcampSlug]['DescuentoBody'],
            price_mount: this.state.pricingAPI[this.state.bootcampSlug]['price']['usd']['mount'],
            DescuentoBodyUSD: this.state.pricingAPI[this.state.bootcampSlug]['DescuentoBodyUSD'],
            DescuentoBodyPEN: this.state.pricingAPI[this.state.bootcampSlug]['DescuentoBodyPEN'],
            cuotaDolar: this.state.pricingAPI[this.state.bootcampSlug]['cuotaDolar'],
            cuotaSoles: this.state.pricingAPI[this.state.bootcampSlug]['cuotaSoles'],
            priceConversion: this.state.pricingAPI[this.state.bootcampSlug]["priceConversion"] || ''
        })

        try {
            if (this.state.pricingAPI[this.state.bootcampSlug].price[country]){
                this.setState({
                    isPricePais: true,
                    price_completo: this.state.pricingAPI[this.state.bootcampSlug].price[country].price_view_base,
                    price_completo_regular: this.state.pricingAPI[this.state.bootcampSlug].price[country].price_view_base_regular,
                    price_mensual: this.state.pricingAPI[this.state.bootcampSlugMensual]['price'][country]['price_view_final'],
                    price_mensual_regular: this.state.pricingAPI[this.state.bootcampSlugMensual]['price'][country]['price_view_base_regular']
                });
            }
        } catch (error) {
            console.log('Log',error);
        }



        if (newCountry === 'PE') {
            this.setState({
                utc_offset: '-0500',
                salario_fullstack_jr: "S/ 45,000 ANUAL",
                salario_fullstack_md: "S/ 79,00 ANUAL",
                salario_fullstack_sr: "+ S/ 135,000 ANUAL"
            });
        } else if (newCountry === 'MX') {
            this.setState({
                utc_dif: -1,
                utc_offset: '-0600',
                salario_fullstack_jr: "$300,000 MXN ANUAL",
                salario_fullstack_md: "$450,000 MXN ANUAL",
                salario_fullstack_sr: "+ $800,000 MXN ANUAL"
                });
        } else if (newCountry === 'CR'){
            this.setState({utc_dif: -1, utc_offset: '-0600'});
        } else if (newCountry === 'CL'){
            this.setState({utc_dif: -1, utc_offset: '-0400'});
        } else if (newCountry === 'BO'){
            this.setState({
                utc_dif: -1,
                utc_offset: '-0400',
                });
        } else if (newCountry === 'CU'){
            this.setState({utc_dif: +1});
        } else if (newCountry === 'SV'){
            this.setState({utc_dif: -1, utc_offset: '-0600'});
        } else if (newCountry === 'GT'){
            this.setState({utc_dif: -1});
        } else if (newCountry === 'HN'){
            this.setState({utc_dif: -1, utc_offset: '-0600'});
        } else if (newCountry === 'NI'){
            this.setState({utc_dif: -1});
        } else if (newCountry === 'PY'){
            this.setState({utc_dif: +1, utc_offset: '-0300'});
        } else if (newCountry === 'PR'){
            this.setState({utc_dif: +1, utc_offset: '-0300'});
        } else if (newCountry === 'DO'){
            this.setState({utc_dif: +1});
        } else if (newCountry === 'UY'){
            this.setState({
                utc_dif: +2,
                utc_offset: '-0300'
            });
        } else if (newCountry === 'BR'){
            this.setState({
                utc_dif: +2,
                utc_offset: '-0300'
            });
        }

        if (newCountry === 'ES') {
            this.setState({
                utc_offset: '+0100',
            });
        }

    }


    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    OpenMeet(){
        if (this.state.Phone){
            window.open(`https://calendly.com/dojopy/bootcamp-fullstack?name=${this.state.Name}&email=${this.state.Email}&phone=${this.state.Phone}`, '_blank').focus();
        } else {
            window.open("https://calendly.com/dojopy/bootcamp-fullstack", '_blank').focus();
        }
    }

    OpenWS(){
        fbq("track", "contact");
        if (window.type_device){
            window.open("https://api.whatsapp.com/send?phone=51935489552&text=Hola%2C%20información%20sobre%20el%20Bootcamp%20Web%20Full%20Stack%20online%20Flexible.", '_blank').focus();
        } else {
            window.open("https://web.whatsapp.com/send?phone=51935489552&text=Hola%2C%20información%20sobre%20el%20Bootcamp%20Web%20Full%20Stack%20online%20Flexible.", '_blank').focus();
        }
    }

    GetLinkWS(){
        let texto = "Hola%2C%20información%20sobre%20el%20Bootcamp%20Web%20Full%20Stack%20online%20Flexible.";

        if (window.type_device){
            return `https://api.whatsapp.com/send?phone=51935489552&text=${texto}`;
        } else {
            return `https://web.whatsapp.com/send?phone=51935489552&text=${texto}`;
        }
    }


    onRenderOption ( option, complete ) {
        return (
            <li>
            <ReactCountryFlag
                countryCode={option.key}
                svg
                style={{
                    width: '1.5em',
                    height: '1.5em',
                    padding: '1px'
                }}
                title="país"
            />  {option.key }
            </li>
                )
    }

    onRenderSelection( selected, complete ){
        return 	(<span style={{ padding: 2 }}>
		{ selected ?
            <span style={{"color": "black"}}>
                <ReactCountryFlag
                countryCode={selected.key}
                svg
                style={{
                    width: '2em',
                    height: '2em',
                    padding: '2px'
                }}
                title="país"
        />
            </span>
            :
               <span style={{padding: 5}}></span>
        }
	</span>)
    }

    FechaEnroll(e){
        let fecha_enroll = e.currentTarget.value;
        this.setState({fechaSelect: fecha_enroll});

        // if (fecha_enroll === "fecha_3"){
        //     localStorage.setItem('modalidad', 'full_time');
        // } else {
        //     localStorage.setItem('modalidad', 'part_time');
        // }

        window.location.href = '#fechas';
    }


    onSubmit(e) {
        e.preventDefault();

        // if (this.state.isFormSend){
        //     toast.success('¡Gracias por enviar tus datos! Pronto un asesor especializado estará en contacto contigo. ¡Muchas gracias!.');
        //     this.setState({isOpenTemario: false, isOpenModalInfo : false, selectFecha: false, loadingEnroll: false, isFormSend: true});
        //     return;
        // }

        // if(this.state.Email.trim() === "" ){
        //     toast.warning('El campo Email es requerido.');
        //     return;
        // }

        if(this.state.Phone.trim() === "" ){
            toast.warning('El campo WhatsApp es requerido, para ser atendido por un asesor.');
            return;
        }

        if(this.state.Phone.length <= 4 ){
            toast.warning('El campo WhatsApp es requerido, para ser atendido por un asesor.');
            return;
        }

        this.setState({loadingEnroll: true, sendDataForm: true});

        localStorage.setItem('email', this.state.Email.trim());
        localStorage.setItem('phone', this.state.Phone.trim());

        if (this.state.Name.trim()){
            localStorage.setItem('name', this.state.Name.trim());
        }

        const source = window.location.search.split('source=')[1] || 'home';


        
        this.sleep(2000).then(r => {
            toast.success('¡Gracias por enviar tus datos! Pronto un asesor especializado estará en contacto contigo. ¡Muchas gracias!.');
            this.setState({isOpenTemario: false, isOpenModalInfo : false, selectFecha: false, loadingEnroll: false, isFormSend: true});
            window.fbq("track", "contact");
        })



        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        {evento: false, lead: true, bootcamp: 'web full stack flex', email: this.state.Email, name: this.state.Name, country: this.state.countryCode,
            Token: "c3b4b89c",
            landing: 'home-flex',
            source: source,
            phone: this.state.Phone
    },)
        .then(res => {

            this.OpenWS();
            // if (this.state.isOpenSuscribe){
            //     toast.success('¡Gracias por registrarte al webinar de introducción!, pronto recibirás un correo electrónico con la información de acceso.')
            // } else if (this.state.isOpenReunion){
            //     window.Calendly.initPopupWidget({url: `https://calendly.com/dojofullstack/agendar?hide_event_type_details=1&hide_gdpr_banner=1&email=${this.state.Email}&name=${this.state.Name}`});
            // } else {
            //     toast.success("Hola!, enviamos el programa por Email, gracias!");
            //     window.open("https://media.dojofullstack.com/temario-pdf/BOOTCAMP-FULLSTACK_5M-2024.pdf", '_blank');
            // }

        })
    }




    onSubmitBeca(e) {
        e.preventDefault();
        if(this.state.Email === "" ){
            toast.warning('El campo Email es requerido.');
            return;
        }
        if(this.state.Phone === "" ){
            toast.warning('El campo WhatsApp es requerido, para ser atendido por un asesor.');
            return;
        }
        this.setState({loadingEnroll: true, sendDataForm: true});

        localStorage.setItem('email', this.state.Email);
        localStorage.setItem('phone', this.state.Phone);

        // toast.success(`${this.state.Name} enviamos tus datos al equipo de Dojopy, gracias!`);
        // let cupon = window.location.search.split('ref=')[1];
        this.setState({isOpenSuscribe : false, isOpenSemiBeca: false, isOpenTemario: false, loadingEnroll: false});

        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        {beca: true , lead: true, bootcamp: 'Web full stack', email: this.state.Email, name: this.state.Name, phone: this.state.Phone, country: this.state.countryCode, fecha: this.state.selectWebinarFecha},)
        .then(res => {
            window.gtag_report_conversion();


            toast.success(`Hola!, "¡Gracias por aplicar! Hemos recibido tu solicitud y estamos emocionados de brindarte un descuento adicional. Nuestro equipo revisará tu solicitud y te enviaremos un código de descuento único en un plazo de 24 horas. ¡No olvides revisar tu bandeja de entrada! Si tienes alguna pregunta, no dudes en contactarnos. "`);
            // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);

        })
    }



    searchEvento(myArray, key){
        for (let i=0; i < myArray.length; i++) {
            if (myArray[i].key === key) {
                return myArray[i].value;
            }
        }
    }


renderCard(index, modIndex) {
    const item = data[modIndex];
    return (
        <div
        key={index}
        className="carousel-card"
        >
        <div
            className="carousel-card-inner"
            style={{backgroundImage: item.background , backgroundSize: "cover"}}
        >
            {item.title}
        </div>
        </div>
    );
    }

renderCard2(index, modIndex) {
    const item = data2[modIndex];
    return (
        <div
        key={index}
        className="carousel-card"
        >
        <div style={{width: "275px"}}>
           <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "23px", "background": "white"}}>
                <h4>
                {item.title}
                </h4>
                <img alt={item.title} height="130px" src={item.background} />
            </div>
        </div>
        </div>
    );
    }


renderBeca() {
    return (
        <>

 {/* Start PRicing Table Area  */}
 <div className="col-12 mt-1" style={{paddingBottom: "13px", paddingRight: "15px", paddingLeft: "15px", maxWidth: "470px", marginRight: window.type_device ? "auto": "1px" }} >


<div className="rn-pricing" style={{boxShadow: "rgba(140, 82, 255, 0.9) 4px 4px 15px 3px", border: "none", background: "linear-gradient(135deg, #C56CD6 0%,#3425AF 100%)", borderRadius: "20px"}} >
    <div className="pricing-table-inner">

        <div className="pricing-header pb-0 mb-2 mt-1">

  
        
        <span style={{padding: "6px 10px", margin: "5px", "background-color": "slateblue", "border-radius": "7px", "color": "white", "font-size": "20px", fontWeight: "bold"}}>
                {this.state.countryCode === 'PE' ? this.state.DescuentoBodyPEN: this.state.DescuentoBodyUSD}
        </span>


        <h3 className="text-white mt-1 mb-0">Monto Total con la Beca50</h3>
             
            <span style={{color: "white", display: "inline-block"}}>
            <del style={{color: "lightgray", fontSize: "25px"}}>
            &nbsp; {this.state.price_completo_regular} &nbsp;
            </del>
            </span>


            <h3 className="pricing" style={{marginBottom: "5px"}} >
            {this.renderPrice(this.state.price_completo)}
            </h3>

            <p className="text-white" style={{fontSize: '20px', marginTop: '10px'}}>
                ¡Ofrecemos la posibilidad de pagar en 3 partes de {this.state.countryCode === 'PE' ? this.state.cuotaSoles: this.state.cuotaDolar} cada una!
            </p>

            {this.state.countryCode === 'MX' &&
            <div className="d-flex" style={{justifyContent: "center"}}>

            <a style={{color: "white", fontStyle: "italic", fontSize: "16px", textDecoration: "underline"}} href={`https://www.google.com/search?q=convertir+${this.state.price_mount}+d%C3%B3lares+a+pesos+mexicanos`} target="_blank">
            Convertir a pesos mexicanos
            </a>
            </div>}


            <div className="slide-btn" style={{marginTop: "15px", textAlign: "center"}}>
        <a href={`/inscripcion/${this.state.bootcampSlug}/${this.state.countryCode}`}>
                <button
                onClick={this.TrackEventConversion}
                id="enroll_bootcamp_2"
                style={{lineHeight: "28px", fontSize: "23px", marginBottom: "13px", padding: "15px 20px" }}
                className=" btn-efect">
                EMPEZAR INSCRIPCIÓN
                 </button>
                </a>
        </div>




        </div>

    </div>
</div>
</div>
{/* End PRicing Table Area  */}


        </>
    )
}


renderPais(complete=false){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > {!this.state.langUS ? "Zona horaria:": "Time zone:"} </span>
            <Selectrix
        height={300}
        onRenderOption={(option) => this.onRenderOption(option, complete)}
        onRenderSelection={(option) => this.onRenderSelection(option, complete)}
        placeHolderInside={true}
        placeholder={"País"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.countryCode}
        options={KeyCountry}
        onChange={value => {
            // if (value.key === 'US'){ window.location.href = '/us'}
            this.DynamicCountry(value.key)}
        }
    />
        </>

    )
}




renderMes(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > {!this.state.langUS ? "Mes:": "Month:"} </span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={!this.state.langUS ? "Todos": "All" }
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_mes}
        options={this.state.mesesInicio.map((item) => ({key: item, label: item})) }
        onChange={value => this.setState({select_mes: value.key})}
    />
        </>

    )
}

renderTurno(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} >{!this.state.langUS ? "Turno": "Shift"}:</span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={!this.state.langUS ? "Todos": "All" }
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_turno}
        options={this.state.Turnos}
        onChange={value => this.setState({select_turno: value.key})}
    />
        </>

    )
}


renderPrice(price,  prueba=false){
        return (
            <>
                <div className="row" style={{placeContent: "center", fontFamily: "'Poppins',sans-serif"}}>
                <div className="col-12">

                    <p style={{marginTop: '5px', fontFamily: "'Poppins',sans-serif", fontSize: window.type_device ? '35px': '39px', "color": prueba ? "black": "white", fontWeight: "bold", paddingTop: "7px", paddingBottom: "7px"}}>
                    {price}
                    </p>
                </div>
                </div>
           
            </>
        )
}


renderPriceFooter(price){
        return (
            <>
                <span style={{fontSize: "17px", "color": "white", fontWeight: 500, paddingTop: "5px", paddingBottom: "5px"}}>
                    2 pagos de {price}
                </span>
                <ReactCountryFlag
                        countryCode={this.state.countryCode}
                        svg
                        style={{
                            width: '3.5em',
                            height: '3.5em',
                            padding: '9px'
                        }}
                        title="país"
                    />
            </>
        )
      }

      renderHorario(){
            if (this.state.utc_offset === "-0500") {
                return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:35 PM</p>
            } else if (this.state.utc_offset === "-0600") {
                return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 9:35 PM</p>
            } else if (this.state.utc_offset === "-0400"){
                return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 PM a 11:35 PM</p>
            }  else {
                return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:35 PM</p>
            }
      }

      renderHorarioFinSemana(){
        if (this.state.utc_offset === "-0500") {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:35 PM</p>
        } else if (this.state.utc_offset === "-0600"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">09:00 AM a 11:35 AM</p>
        } else if (this.state.utc_offset === "-0400"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">11:00 AM a 13:35 PM</p>
        } else if (this.state.utc_offset === "+0100"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">16:00 PM a 18:35 PM</p>
        } else {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:35 PM</p>
        }

    }

    renderHorarioFinSemanaTarde(){
        if (this.state.utc_offset === "-0500") {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">03:00 PM a 05:35 PM</p>
        } else if (this.state.utc_offset === "-0600"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">02:00 PM a 04:35 PM</p>
        } else if (this.state.utc_offset === "-0400"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">04:00 PM a 06:35 PM</p>
        } else if (this.state.utc_offset === "+0100"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">09:00 PM a 11:35 PM</p>
        } else {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">03:00 PM a 05:35 PM</p>
        }
    }


    renderFechaSelect(item, key){
        return (
            <div key={key} className="row row--35 align-items-center rn-address" data-aos="fade-up"  style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}} >
            <div className="col-12 mb-3">

                <h2 style={{marginBottom: "0px", fontSize: window.type_device && "39px" }}>
                {item.fecha_inicio}
                </h2>
                <div style={{width: window.type_device ? "200px": "450px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "20px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                <div className="row">
                    <div className="col-lg-2 col-md-3 col-12  mt-2">
                    <span style={{padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                    <FiVideo/> {!this.state.langUS ? "Flexible": "Live"}
                                    </span>
                    </div>
                    <div className="col-md-4 col-lg-3 col-12  mt-2">
                        <span style={{"padding-top": "4px","padding-bottom": "4px", "font-size": "18px", "color":"white", "background-color": "slateblue", "border-radius": "8px", "padding-left": "8px", "padding-right": "8px"}}>
                        	Plazas limitadas
                        </span>
                    </div>
                </div>

            </div>

            <div className="col-md-4 col-12" style={{height: "110px", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
                <div> <h5 className="mb-2" ><b> {!this.state.langUS ? "Duración": "Duration"} </b></h5> </div>
                <div> <p style={{fontSize: "18px"}}>
                100 horas <br/> (aprende a tu propio ritmo)
                <span style={{display: "inline-block"}}></span>
                </p> </div>
            </div>



            <div className="col-md-4 col-12" style={{height: "110px" }}>
                <div> <h5 className="mb-2" ><b> {!this.state.langUS ? "Grupo": "Duration"} </b></h5> </div>
                <div> <p style={{fontSize: "18px"}}>
                Comunidad y grupo privado de WhatsApp.
                <span style={{display: "inline-block"}}></span>
                </p> </div>
            </div>


        </div>
        )
      }


      renderFechas() {
        return (
            <div>
                {!this.state.select_mes && this.state.DataFechas.map((item, key) => (

                    this.state.select_turno ?
                     this.state.select_turno == item.turno && this.renderFechaSelect(item, key)
                    :
                    this.renderFechaSelect(item, key)

                ))}

                {this.state.select_mes && this.state.DataFechas.map((item, key) => (

                    this.state.select_turno ?
                    this.state.select_turno == item.turno && this.state.select_mes == item.mes && this.renderFechaSelect(item, key)
                   :
                    this.state.select_mes == item.mes && this.renderFechaSelect(item, key)


                ))}
          </div>

        )}


    selectBoot(plan){
        this.setState({Plan: plan});
        window.location.href='#enroll';
    }

    openModalVid (){
        this.setState({isOpenVid: !this.state.isOpenVid});
    }
    openModal0 () {
        this.setState({isOpen0: !this.state.isOpen0});
    }
    openModalEmail (){
        this.setState({isOpenTemario: !this.state.isOpenTemario});
    }

    openModalWebinar (){
        this.setState({isOpenSuscribe: true});
    }

    openModalCheckout (){
        this.setState({isOpenCheckout: !this.state.isOpenCheckout});
        this.setState({price_payment: "1"});
        // if (type_bootcamp === 1){
        //     this.setState({price_payment: "95"});
        // } else {
        //     this.setState({price_payment: "175"});
        // }

    }

    // openModalSuscribe (e){
    //     // window.location.href = "/Inscripcion";
    //     // if (e){
    //     //     this.setState({fechaSelect: e.target.value});
    //     // }
    //     this.setState({isOpenSuscribe: !this.state.isOpenSuscribe});
    // }

    openModal () {
        this.setState({isOpen: !this.state.isOpen});
    }

    openModal2 () {
        this.setState({isOpen2: !this.state.isOpen2});
    }
    openModal3 () {
        this.setState({isOpen3: !this.state.isOpen3});
    }
    openModal4(){
        this.setState({isOpenResume: !this.state.isOpenResume});
    }

    openModalPrueba(){
        this.setState({isOpenShowPrueba: !this.state.isOpenShowPrueba});
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open');
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    stickyHeader () {}


    validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    setEmailValidate (email){
        this.setState({ Email: email});
        if (this.validateEmail(email)){
            this.setState({showPhoneField: true});
        }
    }



    render(){
        // const PostList = BlogContent.slice(0 , 5);

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        const style = {
            marginTop: '12px',
            color: '#2F2F2F',
            border: '2px solid #2F2F2F'
          };

          const TypingAnimation =  React.memo(()=>{
            return !this.state.langUS ?
            <Typical
            className={'my-tipical'}
            steps={['Domina el arte del desarrollo web Full Stack', 500, 'consigue el trabajo de tus sueños.']}
            loop={true}
            wrapper="h2"
                />:
                <Typical
                className={'my-tipical'}
                steps={['Create Full Stack Web Platforms', 500, 'Get your dream job.', 500, 'The best Full Stack Bootcamp in 5 months.']}
                loop={true}
                wrapper="h2"
                />
          },(props,prevProp)=> true ); // this line prevent re rendering

          const TypingAnimation2 =  ()=> {
            return !this.state.langUS ?
            <Typical
            steps={['Aprende online a tu propio ritmo ', 500 ,'con la guía de tu Mentor.', 1000]}
            loop={Infinity}
            wrapper="h2"/>
            :
            <Typical
            steps={['Learn 100% online live', 500 ,'with the guidance of your Mentor.', 1000]}
            loop={Infinity}
            wrapper="h2"
        />
          }; // this line prevent re rendering

        
          const ButonsFechas = () => {
            return (
                <div data-aos="fade-right" className="col-12 col-md-6"
                style={{marginBottom: "10px",
                      paddingLeft: window.type_device ? "5px": "50px",
                       paddingRight: window.type_device ? "5px": "50px"}}
              >
                  <div style={{textAlign: "center", padding: "5px", borderRadius: "20px"}}>

                  <h3 className="mb-1 mt-1" style={{fontWeight: "bold"}}>
                  Elige la fecha
                  </h3>

                  <div className="mb-1">
                   {/* <span className="bg-white m-1" style={{padding: "5px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "16px"}}>
                   Hora local (UTC-5)
                   </span> */}
                   <span className="bg-white m-1" style={{padding: "5px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "16px"}}>
                   Duración 2 horas
                   </span>
                  </div>


                    {FECHAS_EVENTO.map((fecha, index) => (
                            <div className="my-3" key={index} >
                            <button  onClick={() => this.setState({selectFecha: true})}  className="btn-efect" style={{width: "100%", boxShadow: "2px 2px 5px 1px rgba(140, 82, 255, 0.9)"}}>
                                {fecha}
                            </button>
                            </div>
                    ))}
                

                  {/* <div className="my-3" >
                  <button  onClick={() => this.setState({selectFecha: true})}  className="btn-efect" style={{width: "100%"}}>
                    Viernes, 8 de diciembre, 7:00 PM
                  </button>
                  </div>

                  <div className="my-3" >
                  <button  onClick={() => this.setState({selectFecha: true})}  className="btn-efect" style={{width: "100%"}}>
                  Sábado, 9 de diciembre, 12:00M
                  </button>
                  </div> */}

                  </div>
      </div>
            )
          }


        return (
            <Fragment>

                <Helmet>
                    <title>
                        {!this.state.langUS ? "Bootcamp Web Full Stack | 100% online en vivo | DojoFullStack": "Full Stack Web Bootcamp | 100% live online | DojoFullStack"}
                           </title>
                    <meta name="description"
                        content="Conviértete en Desarrollor Web Full Stack en nuestro Bootcamp de a tu propio ritmo online en vivo, domina el Frontend y el Backend de un sitio web usando HTML, CSS, JavaScript, React, Python y AWS, recibe el Programa de empleabilidad al finalizar. ¡Crea proyectos web reales y aprende las mejores prácticas de programación en DojoFullStack!"
                        />
                    <meta
                        property="og:description"
                        content="Conviértete en Desarrollor Web Full Stack en nuestro Bootcamp de a tu propio ritmo online en vivo, domina el Frontend y el Backend de un sitio web usando HTML, CSS, JavaScript, React, Python y AWS, recibe el Programa de empleabilidad al finalizar. ¡Crea proyectos web reales y aprende las mejores prácticas de programación en DojoFullStack!"
                        />
                   <meta
                        name="twitter:description"
                        content="Conviértete en Desarrollor Web Full Stack en nuestro Bootcamp de a tu propio ritmo online en vivo, domina el Frontend y el Backend de un sitio web usando HTML, CSS, JavaScript, React, Python y AWS, recibe el Programa de empleabilidad al finalizar. ¡Crea proyectos web reales y aprende las mejores prácticas de programación en DojoFullStack!"
                    />
                </Helmet>

                {/* Start Header Area  */}
                <Header bootcampSlug={this.state.bootcampSlug}  langES={this.state.countryCode === 'ES' ? true : false}  countryCode={this.state.countryCode} DynamicCountry={this.DynamicCountry} />
                {/* End Header Area  */}

                {/* Start Slider Area   */}

                <div className="slider-activation slider-creative-agency with-particles" id="home">

                { false &&
                <div className="frame-layout__particles">
                <Particles
                    init={this.customInit}
                    options={{
                        fullScreen: { enable: false },
                        fpsLimit: 120,
                        interactivity: {
                        events: {
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                        },
                    particles: {
                        color: {
                            value: "#ffffff",
                        },
                        links: {
                            color: "#ffffff",
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 1,
                        },
                        collisions: {
                            enable: true,
                        },
                        fullScreen: { enable: false },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 2,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 800,
                            },
                            value: 20,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        },
                    },
                }}

                />;

    </div>
    }

                    <div className="bg_image" style={{backgroundImage: 'linear-gradient(rgb(0 0 0 / 50%), rgb(91 18 237 / 95%)), url(/assets/images/bg/bg-image-27.webp)' }}>
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center">
                                    <div className="container">

                                        <div className="row" style={{flexWrap: 'nowrap'}}>

                                            <div className="col-12 col-md-8">

                                                <div className="inner text-left" style={{"text-align-last": "left"}}>

                                        {window.type_device ?
                                            <span style={{borderRadius: "10px", padding: "3px", color: "white" ,fontWeight:"bold", height: "30px", fontSize: "15px" ,"width": "143px","border": "1px solid white", "text-align-last": "center"}}>
                                            BOOTCAMP
                                            </span>:
                                            <span style={{borderRadius: "10px", padding: "3px", color: "white" ,fontWeight:"bold", height: "35px", fontSize: "17px" ,"width": "155px","border": "1px solid white", "text-align-last": "center"}}>
                                            BOOTCAMP
                                            </span>
                                        }


{this.state.fechaLimite &&
<div className="pricing-header pb-0 mb-1 mt-1">
                    <p className="my-0 py-0" style={{color: 'white', fontSize: '20px', textAlign: 'center'}}>Inicia en</p>
                <div className="mb-3">
                    <FlipCountdown
                        size={'small'}
                        yearTitle='Year'
                        dayTitle='Días'
                        hourTitle='Horas'
                        minuteTitle='Min'
                        secondTitle='Segs'
                        monthTitle='Mes'
                        hideYear
                        hideMonth
                        endAt={this.state.fechaLimite} // Date/Time
                    />
                </div>
                </div>}

                                                    {!this.state.langUS ?
                                                    <h1 className="title" style={{"text-align": "left", marginBottom: "10px"}}>
                                                        DESARROLLO WEB
                                                         <span className="h1-dojopy title title-gradient">
                                                            FULL STACK
                                                            </span>
                                                    </h1>:
                                                    <h1 className="title" style={{"text-align": "left", marginBottom: "10px"}}>
                                                        <span className="h1-dojopy title title-gradient" >FULL STACK</span>
                                                        WEB DEVELOPMENT
                                                    </h1>}

                                                    <div className="row col-12 mb-3" style={{textAlign: "left", height: "60px"}}>
                                                    <TypingAnimation/>
                                                    </div>


                                                    <p className="description" style={{"padding-top": "9px", fontSize: "18px"}}>



                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1"><FiCheck style={{"color": "yellow"}}/></div>
                                                        <div className="col-10" style={{paddingBottom: "9px", textAlign: "left" }}>
                                                            100% online
                                                            <span style={{display: 'inline-block', padding: "3px",marginLeft: "7px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/>  {!this.state.langUS ? "Flexible": "Live"}
                                                            </span>
                                                         </div>
                                                    </div>


                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1"><FiCheck style={{"color": "yellow"}}/></div>
                                                        <div className="col-10" style={{paddingBottom: "9px", textAlign: "left" }}>
                                                            Completa el bootcamp a tu propio ritmo.
                                                         </div>
                                                    </div>


                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1"><FiCheck style={{"color": "yellow"}}/></div>
                                                            <div className="col-10" style={{paddingBottom: "9px", textAlign: "left" }}>
                                                            Retroalimentación y <br/> Tutorías 1 a 1.
                                                            </div>
                                                    </div>



                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1"><FiCheck style={{"color": "yellow"}}/></div>
                                                        <div className="col-10" style={{paddingBottom: "5px", textAlign: "left" }}>
                                                            {!this.state.langUS ? "Crea múltiples proyectos web reales.": "Create multiple real web projects."}
                                                         </div>
                                                    </div>



                                                    </p>
                                        
                                        <a href="#informes">
                                        <button  className="btn-efect" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "23px", marginTop: '5px', fontWeight: "bold"}}>
                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "5px"}}
                                                />}
                                Solicitar información
                                </button>
                                        </a>
                            

                                                    <span style={{"font-size": "10px", "paddingTop": "7px"}}>  {!this.state.langUS ? "Calidad 100% online asegurada": "Live quality 100% guaranteed"}  </span>

                                                    {/* <div className="slide-btn">
                                                     <button value="" style={{textAlignLast: "center", fontSize: "15px"}} className="rn-button-style--2 btn-primary-color-ws" onClick={this.OpenWS}>
                                                     <FaWhatsapp style={{fontSize: "20px", marginRight: "10px"}}/>
                                                          {!this.state.langUS ? "¿Dudas? Escríbenos": "Doubts? write to us"}

                                                    </button>
                                                </div> */}

                                                </div>
                                            </div>
                                        
                    
                    {false &&
                    <div style={{marginTop: '120px'}}>
                                            <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "370px"}} id="informes">
                            <div className="row row--35 align-items-center justify-content-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                                {/* <div className="thumbnail" style={{paddingBottom: "8px", maxWidth: window.type_device ? "310px": "370px" }}>
                                <img style={{boxShadow: "#424241 6px 5px 15px 0px"}} className="image_temario" src="/assets/images/dojopy/beca-parcial-flex.webp" alt="temario bootcamp"/>
                                </div> */}


                                <p style={{
                                    marginTop: '15px',
                                    color: "white",
                                    fontSize: '33px',
                                    fontWeight: 'bold',
                                    fontFamily: "'Poppins',sans-serif"
                                }} className="subtitle-info">
                                    Recibir más Información
                                </p>

                                <p className="my-1 text-white">
                                    Obtén información detallada sobre el programa online, beneficios exclusivos y testimonios de nuestros alumnos.
                                </p>

                     <form onSubmit={this.onSubmit}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        <label class="col-12 text-left font-weight-bold px-4 text-white" htmlFor="nameDojo">
                        <span style={{fontSize: "17px", color: "white"}}>Nombre:</span>
                            <input
                            id='nameDojo'
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"👋 Hola! ¿Cuál es tu nombre?"}
                            />
                        </label>

                        {/* <label class="col-12 text-left font-weight-bold px-4 text-white" htmlFor="emailDojo">
                            <span style={{fontSize: "17px", color: "white"}}>Email:</span>
                            <input
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="email"
                            name="email"
                            id="emailDojo"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder="📧 ¿Cuál es tu correo electrónico?"
                            />
                        </label> */}


                        <div className="text-left" >
    <b style={{fontSize: "17px", marginLeft: "20px", color: "white"}}>
        WhatsApp:
    </b>
    
</div> 
<div className="d-flex justify-content-center mx-3">

<div className="mx-2 mt-2">
    <Selectrix
    className="pais-inscription"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    onChange={(value) => {
        if (this.state.Email){
            localStorage.setItem('email', this.state.Email);
        }
        if (this.state.Name){
            localStorage.setItem('name', this.state.Name);
        }
        if (this.state.Phone){
            localStorage.setItem('phone', this.state.Phone);
        }
    } }
    />

</div>

<div style={{width: "80%"}}>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="text"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="N.º de WhatsApp"
    />
 </label>
</div>

</div>


                                <button className="btn-efect" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "23px", marginTop: '5px', fontWeight: "bold"}}>
                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "5px"}}
                                                />}
                                                <FaWhatsapp style={{fontSize: "23px", marginRight: "10px", color: '#25D366'}}/>
                                Solicitar información
                                </button>

                                <div className="col-12 mt-2 text-center" style={{color: "white", fontSize: "13px", textAlign: "center"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "black"}}>
                        <input
                            style={{width: 16, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span className="text-white">{!this.state.langUS ? "Protección de datos y privacidad.": "Data protection and privacy."} </span>
                    </label>
                    </div>

                        </div>
                        </div>

                        </form>

                   

                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                                            </div>
    }                                      

                                        </div>
                                    </div>
                                </div>
                        </div>
                   
                </div>
                {/* End Slider Area   */}




{true &&
<div className="py-3" style={{background: 'linear-gradient(135deg, #C56CD6 0%, #3425AF 100%)'}} id="informes">

            <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "450px"}}>
                            <div className="row row--35 align-items-center justify-content-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                                {/* <div className="thumbnail" style={{paddingBottom: "8px", maxWidth: window.type_device ? "310px": "370px" }}>
                                <img style={{boxShadow: "#424241 6px 5px 15px 0px"}} className="image_temario" src="/assets/images/dojopy/beca-parcial-flex.webp" alt="temario bootcamp"/>
                                </div> */}



                                <p style={{
                                    marginTop: '15px',
                                    color: "white",
                                    fontSize: '33px',
                                    fontWeight: 'bold',
                                    fontFamily: "'Poppins',sans-serif",
                                }} className="subtitle-info">
                                   Recibir más Información
                                </p>

                                <p className="my-1 text-white">
                                    Obtén información detallada sobre el programa online, beneficios exclusivos y testimonios de nuestros alumnos.
                                </p>

                     <form onSubmit={this.onSubmit}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        <label class="col-12 text-left font-weight-bold px-4 text-white" htmlFor="nameDojo">
                        <span style={{fontSize: "17px", color: "white"}}>Nombre:</span>
                            <input
                            id='nameDojo'
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"👋 Hola! ¿Cuál es tu nombre?"}
                            />
                        </label>

                        {/* <label class="col-12 text-left font-weight-bold px-4 text-white" htmlFor="emailDojo">
                            <span style={{fontSize: "17px", color: "white"}}>Email:</span>
                            <input
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="email"
                            name="email"
                            id="emailDojo"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder="📧 ¿Cuál es tu correo electrónico?"
                            />
                        </label> */}


     <div className="text-left" >
    <b style={{fontSize: "17px", marginLeft: "20px", color: "white"}}>
        WhatsApp:
    </b>
    
</div> 

 <div className="d-flex justify-content-center mx-3">

<div className="mx-2 mt-2">
    <Selectrix
    className="pais-inscription"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    onChange={(value) => {
        if (this.state.Email){
            localStorage.setItem('email', this.state.Email);
        }
        if (this.state.Name){
            localStorage.setItem('name', this.state.Name);
        }
        if (this.state.Phone){
            localStorage.setItem('phone', this.state.Phone);
        }
    } }
    />

</div>

<div style={{width: "80%"}}>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="text"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="N.º de WhatsApp"
    />
 </label>
</div>

</div>




                                <button className="btn-efect" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "23px", marginTop: '5px', fontWeight: "bold"}}>
                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "5px"}}
                                                />}
                                Recibir más información
                                </button>

                                <div className="col-12 mt-2 text-center" style={{color: "white", fontSize: "13px", textAlign: "center"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "black"}}>
                        <input
                            style={{width: 16, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span className="text-white">{!this.state.langUS ? "Protección de datos y privacidad.": "Data protection and privacy."} </span>
                    </label>
                    </div>

                        </div>
                        </div>

                        </form>

                   


                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        
    
</div>
}




  {/* Start About Area */}
  <div data-aos="fade-up" className="about-area ptb--60 bg_color--5" id="about_dojopy">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">

                                                {!this.state.langUS ? "Bootcamp Web Full Stack": "Full Stack Web Bootcamp"}

                                            <span style={{padding: "3px", marginLeft: "8px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "22px"}}>
                                                <FiVideo/> {!this.state.langUS ? "Flexible": "Live"}
                                            </span>
                                            </h2>
                                            <p>
                                           <b>
                                        Somos una Startup educativa, el aliado que te acompaña para desarrollarte como programador y desarrollador web full stack, durante y después del Bootcamp.
                                        </b>
                                            </p>
                                            <p>
                                            Aprenderás a crear plataformas web modernas y escalables desde cero como <b>Rappi, Airbnb o Twitter. 🔥</b>. <br/>
                                            Domina el <b>Frontend</b> y el <b>Backend</b> de un sitio web usando las mejores prácticas conocidas y la programación. <br/>

                                                <a style={{"cursor": "pointer", "text-decoration": "underline"}} onClick={this.openModal3}>
                                                {!this.state.langUS ? "Conoce más": "Learn more"}
                                                </a>
                                            </p>


                                        </div>

                                    <div className="row mt--30 rn-address" style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}} >
                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <div className="about-us-list">
                                                    <h3 className="title"><FiClock/> {!this.state.langUS ? "Duración": "Duration"}</h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    100 horas <br/> (aprende a tu propio ritmo)
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <div className="about-us-list">
                                                    <h3 className="title"><FiUser/>  {!this.state.langUS ? "Modalidad": "Modality"} </h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span> 100% online
                                                    <span style={{padding: "3px", marginLeft: "7px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                        <FiVideo/> {!this.state.langUS ? "Flexible": "Live"}
                                                    </span>
                                                     <br/>
                                                        <div className="pt-2">
                                                        <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>  {!this.state.langUS ? "Retroalimentación y tutorías 1 a 1." : "Receive Mentoring 1 to 1"}<br/>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>




                                      


                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <div className="about-us-list">
                                                    <h3 className="title"> {!this.state.langUS ? "Requisitos": "Requirements"} </h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    <a style={{"cursor": "pointer", "text-decoration": "underline"}} onClick={this.openModal2}> {!this.state.langUS ? "Ver": "View"}</a>

                                                    </p>
                                                </div>
                                            </div>


                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <div className="about-us-list">
                                                    <h3 className="title"><GiPlatform/>  {!this.state.langUS ? "Plataforma": "Modality"} </h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>

                                                    Acceso garantizado durante 12 meses.
                                                 
                                                     <br/>
                                                        <div className="pt-2">
                                                        <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span> 
                                                         {!this.state.langUS ? "Plataforma interactiva." : "Receive Mentoring 1 to 1"}<br/>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>



                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <div className="about-us-list">
                                                    <h3 className="title"> {!this.state.langUS ? "Temario": "Syllabus"} </h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <button className="rn-button-style--2 btn-solid" style={{fontSize: "18px", padding: "10px"}} onClick={() => {
                                                        window.open("https://plataforma-dojopy-media.s3.amazonaws.com/temario-pdf/BOOTCAMP-FULLSTACK-2024-flex_compressed.pdf", '_blank');
                                                    } }>
                                                <FiDownload style={{fontSize: "18px", margin: "3px"}}/>

                                                    {!this.state.langUS ? "Ver Temario": "View Syllabus"}
                                                </button>
                                                    </p><br/>
                                                </div>
                                            </div>


                                            <div className="col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <h3 className="title"><FaGraduationCap/> Certificación </h3>
                                                <div className="row">
                                                <div className="about-us-list col-12 col-md-4"  >
                                                    <img onContextMenu={(e) => e.preventDefault()} alt="Certificado Web Full Stack Developer" src={"/assets/images/dojopy/CERTIFICADO-FS.png"}></img>
                                                </div>
                                                </div>
                                            </div>


                                    </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}






    {/* Start About Area */}
    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="benefits" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                            {!this.state.langUS ? "¿Para quién es este Bootcamp?": "Who is this Bootcamp for?"}
                                            </h2>

                                        </div>


                                    {window.type_device ?
                                        <TouchCarousel
                                        component={Container2}
                                        cardSize={cardSize2}
                                        cardCount={data2.length}
                                        cardPadCount={cardPadCount}
                                        loop={true}
                                        autoplay={2e3}
                                        renderCard={this.renderCard2}
                                        /> :

                                        <div className="row mt--30">
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                {!this.state.langUS ? "Quieres cambiar de carrera": "Do you want to change careers?"}
                                                </h4>
                                                <img alt="Quieres cambiar de carrera" height="150px" src="/assets/images/icons/change.webp"></img>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                {!this.state.langUS ? "Mejora tu currículo con nuevas habilidades": "Enhance your resume with new skills"}
                                                </h4>
                                                <img alt="Mejora tu currículo con nuevas habilidades" height="150px" src="/assets/images/icons/curriculum.webp"></img>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                {!this.state.langUS ? "Emprendedores y visionarios": "Entrepreneurs and visionaries"}
                                                </h4>
                                                <img alt="Emprendedores y visionarios" height="150px" src="/assets/images/icons/idea.webp"></img>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                    {!this.state.langUS ? "Si aún no sabes sobre tecnologías web": "If you still don't know about web technologies"}
                                                </h4>
                                                <img alt="Si aún no sabes sobre tecnologías web" height="150px" src="/assets/images/dojopy/web.webp"></img>
                                            </div>
                                        </div>

                                    </div>
                                    }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




    {/* Start About Area */}
    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="benefits" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12 col-lg-8">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title mb-0">
                                            ¿Qué es el desarrollo <br/> Web Full Stack?
                                            </h2>

                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                        </div>
                                        <div>
                                            <p>
                                            ¿Alguna vez has soñado con crear tus propias aplicaciones web y tener el control total sobre cada aspecto del proceso de desarrollo? Si es así, entonces el mundo del desarrollo web Full Stack está esperando para deslumbrarte.

Un desarrollador web Full Stack es un maestro de múltiples disciplinas en el mundo del desarrollo web. Como arquitectos de la web, son capaces de crear aplicaciones completas, desde el diseño y la interfaz de usuario, hasta la lógica del servidor y la gestión de bases de datos. Imagina tener el poder de construir sitios web interactivos, aplicaciones web y plataformas en línea desde cero.

Con las habilidades de un desarrollador web Full Stack, podrás crear experiencias digitales únicas y personalizadas que cautivarán a los usuarios.
<br/>
Los desarrolladores web se necesitan en todo el mundo y además están muy bien pagados porque todas las empresas necesitan un sitio o plataforma web.

                                            </p>

                                            <h5>
                                            {!this.state.langUS ? "El sector está prosperando. El número de vacantes está creciendo. La industria tecnológica de América Latina está buscando más 500,000 especialistas en TI.":
                                             "The sector is thriving. The number of vacancies is growing. The American technology industry is looking for more than 500,000 IT specialists."}
                                            </h5>
                                        </div>

                                    <br/>
                                    <div className="text-center">
                                    <button onClick={() => this.setState({VideoPitch: true})} style={{textAlignLast: "center", "font-weight": "600"}} className="rn-button-style--2 btn-primary-color-4 mt-2">
                                            <BsFillPlayCircleFill style={{marginRight: "7px", fontSize: "23px"}} />
                                            Ver Introducción</button>
                                    </div>

                                    </div>
                                </div>

                        
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




    {/* Start About Area */}
    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="profile" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12 col-md-9">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                            {!this.state.langUS ? "Un vistazo a tu futuro perfil ": "A look at your future profile"}
                                            </h2>
                                            <h3 className="title mt-4">
                                             {!this.state.langUS ? "Puesto prestigioso": "prestigious position"}
                                            </h3>
                                        </div>
                                        <div>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}> {!this.state.langUS ? "Desarrollador Web Full stack ": "Full stack web developer"} </span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>{!this.state.langUS ? "Desarrollador Frontend ": "Front End Developer"} </span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}> {!this.state.langUS ? "Desarrollador Backend": "Backend Developer"} </span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>{!this.state.langUS ? "Desarrollador de Software ": "Software developer"} </span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}> {!this.state.langUS ? "Desarrollador Cloud de AWS": "cloud developer"} </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                            <h3 className="title mt-4">
                                            {!this.state.langUS ? "Perfil profesional al finalizar el Bootcamp": "Interesting activities"}
                                            </h3>
                                            <div>
                                                <p style={{fontWeight: "bold", fontSize: "18px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Tendrás la capacidad de desarrollar, diseñar y prototipar interfaces Web modernas trabajando con  tecnologías de vanguardia,
                                                    lo cual te permitirá construir aplicaciones más complejas, dinámicas y escalables.
                                                </p>
                                                <p style={{fontWeight: "bold", fontSize: "18px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Desplegar e implementar aplicaciones Web en la nube de AWS.
                                                </p>
                                                <p style={{fontWeight: "bold", fontSize: "18px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Definir las funcionalidades requeridas y el alcance de las librerías externas de una aplicación Web.
                                                </p>
                                                <p style={{fontWeight: "bold", fontSize: "18px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Construir interfaces Web fáciles de usar para el usuario.
                                                </p>

                                                <p style={{fontWeight: "bold", fontSize: "18px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Desarrollar Software  para las empresas.
                                                </p>

                                            </div>
                                    </div>

                                    <div className="col-12 col-md-9">
                                            <h3 className="title mt-4">
                                             {!this.state.langUS ? "Habilidades claves para el mercado laboral": "Key skills for the labor market"}
                                            </h3>
                                            <div>
                                            <TouchCarousel
                                                component={Container}
                                                cardSize={cardSize}
                                                cardCount={data.length}
                                                cardPadCount={cardPadCount}
                                                loop={true}
                                                autoplay={2e3}
                                                renderCard={this.renderCard}
                                                />
                                            </div>
                                    </div>


                                    <Tabs>
    <TabList style={{textAlign: "center"}}>
        <Tab style={{fontSize: "21px", padding: "10px 10px", fontWeight: "bold" }}>
            <MdPayments color="#2f6299"  style={{fontSize: "30px"}}/> Salario en Latinoamérica
            </Tab>
        <Tab style={{fontSize: "21px", padding: "10px 10px", fontWeight: "bold" }}>
            <MdPayments color="#0000ff" style={{fontSize: "30px"}}/> Salario en Estados Unidos
            </Tab>
    </TabList>

        <TabPanel>
            <div className="row">
            <div className="col-12 col-md-8">
            <h3 className="title mt-4">
            {!this.state.langUS ? "Salario alto y perspectivas seguras": "High salary and secure prospects"}
            </h3>
            <p style={{fontSize: "13px"}}>{!this.state.langUS ? "Estos salarios mensuales promedio están tomados de Computrabajo y Glassdoor, el portal de empleo líder en Latinoamérica. Pero tú puedes ser mejor que el promedio.":
            "These average monthly salaries are taken from Indeed and Glassdoor, the leading job portal in the United States. But you can be better than average."} </p>
            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-10 col-md-6">
                <h3 style={{fontWeight: "500"}}>{!this.state.langUS ? this.state.salario_fullstack_jr: "$70,000 / year"}</h3>
                <p>{!this.state.langUS ? "Desarrollador Full Stack Web Junior": "Junior Full Stack Web Developer"}  <span className="text-primary d-inline-block"> {!this.state.langUS ? "después del Bootcamp": "after bootcamp"}  </span> </p>
            </div>
            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-11 col-md-8">
                <h3 style={{fontWeight: "500"}}>{!this.state.langUS ? this.state.salario_fullstack_md: "$90,000 / year"}</h3>
                <p>{!this.state.langUS ? "Desarrollador Full Stack Web Middle ": "Full Stack Middle Web Developer"} <span className="text-primary d-inline-block">{!this.state.langUS ? "en 1 año": "in 1 year"} </span> </p>
            </div>
            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-12 col-md-10">
                <h3 style={{fontWeight: "500"}}>{!this.state.langUS ? this.state.salario_fullstack_sr: "$120,000 / year"}</h3>
                <p>{!this.state.langUS ? "Desarrollador Full Stack Web Senior": "Senior Full Stack Web Developer"}  <span className="text-primary d-inline-block">{!this.state.langUS ? "en 2 años": "in 2 years"} </span> </p>
            </div>
        </div>
        <div className="col-12 col-md-4" style={{placeSelf: "center"}}>
            <img alt="El sector de la programacion web está prosperando" src="/assets/images/dojopy/employer-2024.png"/>
        </div>
            </div>

        </TabPanel>

        <TabPanel>
            <div className="row">

        <div className="col-12 col-md-8">
            <h3 className="title mt-4">
            {!this.state.langUS ? "Salario alto y perspectivas seguras": "High salary and secure prospects"}
            </h3>
            <p style={{fontSize: "13px"}}>{!this.state.langUS ? "Estos salarios mensuales promedio están tomados de Glassdoor, el portal de empleo líder en Estados Unidos. Pero tú puedes ser mejor que el promedio.":
            "These average monthly salaries are taken from Indeed and Glassdoor, the leading job portal in the United States. But you can be better than average."} </p>
            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-10 col-md-6">
                <h3 style={{fontWeight: "500"}}>{this.state.usa_salario_fullstack_jr}
                <ReactCountryFlag
                countryCode={'US'}
                svg
                style={{
                    marginLeft: "8px",
                    width: '1.2em',
                    height: '1.2em',
                    padding: '1px'
                }}
                title="país"
            />
                </h3>
                <p>{!this.state.langUS ? "Desarrollador Full Stack Web Junior": "Junior Full Stack Web Developer"}  <span className="text-primary d-inline-block"> {!this.state.langUS ? "después del Bootcamp": "after bootcamp"}  </span> </p>
            </div>
            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-11 col-md-8">
                <h3 style={{fontWeight: "500"}}>{this.state.usa_salario_fullstack_md}
                <ReactCountryFlag
                countryCode={'US'}
                svg
                style={{
                    marginLeft: "8px",
                    width: '1.2em',
                    height: '1.2em',
                    padding: '1px'
                }}
                title="país"
            />
                </h3>
                <p>{!this.state.langUS ? "Desarrollador Full Stack Web Middle ": "Full Stack Middle Web Developer"} <span className="text-primary d-inline-block">{!this.state.langUS ? "en 1 año": "in 1 year"} </span> </p>
            </div>
            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-12 col-md-10">
                <h3 style={{fontWeight: "500"}}>{this.state.usa_salario_fullstack_sr}
                <ReactCountryFlag
                countryCode={'US'}
                svg
                style={{
                    marginLeft: "8px",
                    width: '1.2em',
                    height: '1.2em',
                    padding: '1px'
                }}
                title="país"
            />
                </h3>
                <p>{!this.state.langUS ? "Desarrollador Full Stack Web Senior": "Senior Full Stack Web Developer"}  <span className="text-primary d-inline-block">{!this.state.langUS ? "en 2 años": "in 2 years"} </span> </p>
            </div>
        </div>
        <div className="col-12 col-md-4" style={{placeSelf: "center"}}>
            <img alt="El sector de la programacion web está prosperando" src="/assets/images/dojopy/employer-2024.png"/>
        </div>
        </div>
        </TabPanel>
    </Tabs>


                            </div>
                </div>
            </div>
        </div>
        {/* End About Area */}






         {/* Start About Area */}
          <div data-aos="fade-right" className="about-area ptb--30 bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            <div className="text-center col-12">
                                <h3 className="title"> {!this.state.langUS ? "Tu portafolio de proyectos": "Your portfolio of projects"}</h3>
                            </div>

                                <div className="col-md-4 col-6">

                                    <div className="thumbnail zoom">
                                        <img alt="proyecto web personal" style={{maxHeight: "470px", maxWidth: "470px", transition: "0.2s"}} className="w-100" src="/assets/images/dojopy/project1.webp"/>
                                        <p style={{padding: "13px"}}>{!this.state.langUS ? "Web Personal": "Personal website"} </p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-6">
                                    <div className="thumbnail zoom">
                                        <img alt="proyecto Web para negocios" style={{maxHeight: "470px", maxWidth: "470px", transition: "0.2s"}} className="w-100" src={!this.state.langUS ? "/assets/images/dojopy/project2.webp" : "/assets/images/dojopy/doctor-web-us.webp"} />
                                        <p style={{padding: "13px"}}>{!this.state.langUS ? "Web para empresas": "Website for companies"} </p>
                                    </div>
                                </div>



                                <div className="col-md-4 col-12">
                                    <div className="thumbnail zoom">
                                        <img  alt="proyecto Web de Pedidos inspirada en Rappi" style={{maxHeight: "470px", maxWidth: "470px", transition: "0.2s"}} className="w-100" src={!this.state.langUS ? "/assets/images/dojopy/project4.webp": "/assets/images/dojopy/amazon_project.png"}/>
                                        <p style={{padding: "13px"}}>{!this.state.langUS ? <>Web de Pedidos inspirada en <b>Rappi.com</b> </>: <>Commerce website inspired by <b>Amazon.com</b> </>} </p>
                                    </div>
                                </div>

                            </div>




                        </div>
                    </div>
                </div>
                {/* End About Area */}





 {/* Start About Area */}
 {/* <div data-aos="fade-right" className="about-area ptb--60 bg_color--5" id="bonus2" style={{marginTop: "1px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            <div className="text-center mb--30 col-12">
                                <h3 className="title">Proyectos Startup</h3>
                            </div>

                                <div className="col-md-4 col-12">

                                    <div className="thumbnail zoom">
                                        <img style={{maxHeight: "450px", maxWidth: "450px", transition: "0.2s"}} className="w-100" src="/assets/images/dojopy/project1.webp" alt="shirt"/>
                                        <p style={{padding: "13px"}}>Web Personal</p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12">
                                    <div className="thumbnail zoom">
                                        <img style={{maxHeight: "450px", maxWidth: "450px", transition: "0.2s"}} className="w-100" src="/assets/images/dojopy/project2.webp" alt="shirt"/>
                                        <p style={{padding: "13px"}}>Web para negocios</p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12">
                                    <div className="thumbnail zoom">
                                        <img style={{maxHeight: "450px", maxWidth: "450px", transition: "0.2s"}} className="w-100" src="/assets/images/dojopy/project3.webp" alt="shirt"/>
                                        <p style={{padding: "13px"}}>Web Tienda Virtual</p>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End About Area */}


   {/* Start About Area */}
   <div data-aos="fade-right" className="about-area ptb--50 bg_color--5"  style={{marginTop: "1px", color: "black"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            <div className="text-center col-12">
                            <span style={{padding: "3px", marginLeft: "7px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> {!this.state.langUS ? "Flexible": "Live"}
                                                            </span>
                                <h2 className="title text-black" style={{padding: "5px"}}>
                                ¿Cómo funciona el Bootcamp?
                                    </h2>

                            </div>
                               <div className="p-3">
                                   <p>
                                   Un bootcamp flexible funciona proporcionando a los estudiantes acceso a los materiales de estudio y las tareas en línea, lo que les permite avanzar a su propio ritmo y organizar su horario de estudio de acuerdo a sus necesidades. Los estudiantes pueden acceder a los recursos en cualquier momento y lugar, lo que les brinda la flexibilidad de aprender cuando les sea conveniente. Además, suelen contar con sesiones de apoyo con instructores o tutores para resolver dudas y recibir retroalimentación. Este enfoque permite a los estudiantes adaptar su aprendizaje a sus horarios y responsabilidades, lo que les permite alcanzar sus objetivos educativos de manera más flexible y personalizada.

                                    </p>

                                    <div className="row mt--30">
                            
                                        <div className="col-12 col-md-6 col-lg-3 mt-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                {!this.state.langUS ? "Prácticas y actividades asíncronas cada semana en la Plataforma.": "Practice with new challenges and activities every week"}
                                                </h4>
                                                <img alt="Practica con nuevos retos y actividades cada semana" height="130px" src="/assets/images/dojopy/coding.webp" />
                                            </div>
                                        </div>

                                      

                                        <div className="col-12 col-md-6 col-lg-3 mt-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                {!this.state.langUS ? "Recibe tutorías personalizadas 1 a 1 ": "Receive personalized advice 1 to 1"}
                                                </h4>
                                                <img alt="Recibe asesoría personalizada 1 a 1" height="130px" src="/assets/images/dojopy/mentor_.webp" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3 mt-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                {!this.state.langUS ? <>Presenta y comparte tus proyectos, <br/> así todos aprenden más</> : <>Present and share your projects, <br/> so everyone learns more</> }
                                                </h4>
                                                <img alt="Presenta y comparte tus proyectos, así todos aprenden más" height="130px" src="/assets/images/dojopy/programming _.webp"></img>
                                            </div>
                                        </div>
                                    </div>
                               </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}






                {/* Start About Area */}
                <div data-aos="fade-right" className="about-area ptb--60 bg_color--5" id="frontend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12 mt-3 mb-3">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h3 className="title pb-3">{!this.state.langUS ? "Módulos principales ": "Main modules"}</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/7.webp" alt="primer módulo web frontend"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span style={{display: 'inline-block', padding: "3px",marginLeft: "7px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> {!this.state.langUS ? "Flexible": "Live"}
                                                            </span>
                                            <h2 className="title mb-0">WEB FRONTEND</h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                            <p>
                                                <b> {!this.state.langUS ? "Conviértete en quien crea las interfaces que los usuarios aman.": "Become the one who creates the interfaces that users love."} </b>
                                            </p>

                                            {!this.state.showMoreC &&
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreC: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                 {!this.state.langUS ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}
                                            </div>

                            {this.state.showMoreC &&
                            <>
                            <p>
                            Cada vez más empresas buscan programadores frontend capaces de comprender y aportar aplicaciones web con interfaces de usuario amigables y modulares para lograr un desarrollo ágil y una usabilidad adecuada. <br/>

Este perfil se especializa en diseñar y construir todo aquello que se ve y con lo que una persona interactúa al entrar a una aplicación web: botones, imágenes, formularios, etc. Es una habilidad fundamental para garantizar impacto en la experiencia de los usuarios con los productos digitales. <br/>

En este programa aprenderás desde los fundamentos de la programación hasta el lanzamiento de aplicaciones web con las últimas tecnologías del mercado, como ReactJS.
                                            </p>
                                    <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> HTML</h4>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> CSS/SASS</h4>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Javascript</h4>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> React JS</h4>

                                                </div>
                                            </div>
                                        </div>

                                        </>}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




                {/* Start About Area */}
                <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="backend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/8.webp" alt="segundo módulo web Backend"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        <span style={{padding: "3px", marginLeft: "7px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> {!this.state.langUS ? "Flexible": "Live"}
                                                            </span>
                                            <h2 className="title mb-0">WEB BACKEND</h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                            <p>
                                                <b>{!this.state.langUS ? "Conviértete en quien crea la lógica detrás de todas las aplicaciones web": "Become the one who creates the logic behind all web applications"} .</b>
                                            </p>

                                        </div>


                                        {!this.state.showMoreD &&
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreD: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {!this.state.langUS ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}

                {this.state.showMoreD &&
                <>


                                            <p>
        Cada vez más empresas buscan programadores backend capaces de comprender y crear aplicaciones web que brinden todo lo que estas necesiten para
        dar respuesta a sus usuarios.<br/>

        Este perfil se especializa en la creación de APIs, bases de datos y arquitecturas escalables. Es decir, en todo aquello que
        no se ve, pero que es fundamental para el funcionamiento de las aplicaciones y sitios web que usamos todos los días.<br/>

        En este bootcamp aprenderás desde los fundamentos de la programación hasta el lanzamiento de aplicaciones web sólidas haciendo uso de las últimas tecnologías
        de la industria.
                                            </p>


                                    <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Python</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> REST / RESTfull / APIs</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Django Framework</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Autenticación JWT</h4>
                                                </div>
                                            </div>
                                        </div>
                                        </>}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}



                {/* Start About Area */}
                <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="backend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-80" height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/9.webp" alt="tercer módulo bases de datos"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span style={{padding: "3px", marginLeft: "7px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> {!this.state.langUS ? "Flexible": "Live"}
                                                            </span>
                                            <h2 className="title mb-0">{!this.state.langUS ? "BASES DE DATOS": "DATABASES"} </h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                            <p>
                                         <b>{!this.state.langUS ? " Conviértete en quien crea la lógica detrás de todas las aplicaciones web.": "Become the one who creates the logic behind all web applications."}</b>  </p>

                                        </div>

                                        {!this.state.showMoreE &&
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreE: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {!this.state.langUS ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}

            {this.state.showMoreE &&
                <>

                                            <p>
        Antes de utilizar cualquier tecnología para bases de datos debes entender los fundamentos de diseño y modelado
         requeridos para que tengas la mejor arquitectura posible.
         Construye tus propios modelos de bases de datos con las mejores prácticas desde cero

                                            </p>
                                    <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Fundamentos</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Bases de Datos Relacionales (PostgreSQL)</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Bases de Datos no Relacionales (mongoDB/Mongoose)</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Cloud Databases</h4>
                                                </div>
                                            </div>
                                        </div>
                                        </>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}



    {/* Start About Area */}
    <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="backend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-80" height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/10.webp" alt="Desplieque en Amazon Web Services"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                                        <span style={{padding: "3px", marginLeft: "7px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> {!this.state.langUS ? "Flexible": "Live"}
                                                            </span>

                                                            <span style={{padding: "3px", marginLeft: "7px", "background-color": "rgb(106, 90, 205)", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                             Programa optativo
                                                            </span>
                                            <h2 className="title mb-0">{!this.state.langUS ? "SERVICIOS EN LA NUBE CON AWS": "DEPLOYMENT IN THE AWS CLOUD"}</h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                                            <p>
                                        <b>{!this.state.langUS ? "Sube tus aplicaciones web en la moderna arquitectura de AWS.": "Upload your web applications on the modern architecture of AWS."}  </b>  </p>

                                        {!this.state.showMoreF &&
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreF: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {!this.state.langUS ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}

                                        </div>

                {this.state.showMoreF &&
                <>
                                        <p>
        AWS es una de las principales plataformas de servicios de computación
        en la nube a nivel mundial, AWS proporciona un amplio conjunto de servicios de
        infraestructura tal como potencia de cómputo, opciones de almacenamiento, redes y bases de datos ofertados
        con una utilidad bajo demanda, disponibles en cuestión de segundos y pagando solo por lo que utiliza. <br/>
        Al finalizar el bootcamp full stack, tendrás acceso al programa optativo Cloud Services con AWS.
                                            </p>

                                    <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Bases de datos</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Servidor privado</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Almacenamiento</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Arquitectura Serverless</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




    {/* Start About Area */}
    <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="backend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/startups_logo.webp" alt="proyecto startups"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span style={{padding: "3px", marginLeft: "7px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> {!this.state.langUS ? "Flexible": "Live"}
                                                            </span>
                                            <h2 className="title mb-0">{!this.state.langUS ? "PROYECTO WEB FULL STACK ": "FULL STACK PROJECT"} </h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                                            <p>
                                        <b> {!this.state.langUS ? "¿Quieres aprender a crear desde cero una Startup o producto digital?": "Do you want to learn how to create a Startup or digital product from scratch?"}  </b>  </p>
                                        {!this.state.showMoreG &&
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreG: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {!this.state.langUS ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}
                            {this.state.showMoreG && (

                                         <p>
                Nosotros somos el único Bootcamp que propone como proyecto final desarrollar un MVP de una startup o empresa digital
                aprende a crear una plataforma como Rappi o Airbnb con nuestro equipo de mentores full stack desde cero. <br/>
                💪 tú puedes ser el próximo fundador de la siguiente red social o marketplace innovadora 🚀.
                                            </p> )}
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}







 {/* Start About Area */}
 <div className="about-area ptb--60 bg_color--5" id="platform">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img height="250px" src="/assets/images/dojopy/TALENTO_search.webp" alt="ROGRAMA DE EMPLEO y ACELERACIÓN LABORAL"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                     
                                            <h2 className="title">
                                               {!this.state.langUS ? "PROGRAMA DE EMPLEO": "EMPLOYMENT PROGRAM"}
                                            </h2>

                                            <div style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                                            <p>
                                                Al culminar el bootcamp podrás acceder al módulo de preparación profesional. <br/>
                                            </p>
                                            <p>
                                            Aquí te ayudaremos a reunir todos los documentos y habilidades que necesitas para conseguir un trabajo.<br/>
                                            Elaborarás un currículo, un portafolio con tus proyectos más exitosos y escribirás una carta de presentación.
                                            </p>
                                        

                                </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}







                <Plataforma langUS={this.state.langUS} />



                <div data-aos="fade-right" className="about-area ptb--50 bg_color--5" id="bonus2" style={{marginTop: "1px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                             
                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <img height={"125px"} src="/assets/images/dojopy/bonus_logo.webp"  />
                                            <h2 className="title" style={{fontSize: "38px", display: 'inline-block'}}>
                                            AMPLÍA TUS HABILIDADES CON BONUS EXCLUSIVOS
                                            </h2>
                                        <p>
                                        ¡Descubre los increíbles bonus que incluye nuestro bootcamp Full Stack! Además de brindarte una formación completa en desarrollo web, te ofrecemos la oportunidad de potenciar tus habilidades con tres cursos adicionales que te llevarán al siguiente nivel. 
                                        </p>
                                        <p>
                                        <b>Bonus exclusivo para aquellos que se inscriban anticipadamente antes de la fecha estipulada.</b>

                                        </p>
                                        <div>
                                        <a target="_blank" href={"https://plataforma-dojopy-media.s3.amazonaws.com/bootcamp/ads/BONUS-BOOTCAMP-2024.pdf"}>
                                        <button
                style={{lineHeight: "20px", fontSize: "18px", marginBottom: "13px", padding: "15px 30px" }}
                className=" btn-efect">
                    <FiPackage style={{marginRight: "5px", fontSize: "20px"}} />
                VER LOS BONUS
                </button>
                </a>
                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/* Start About Area */}
                <div data-aos="fade-right" className="about-area ptb--80 " id="methodology">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">


                            {window.type_device ? (
                                        <div className="col-12" style={{textAlign: "-webkit-center", marginLeft: "-45px", marginBottom: "-40px"}}>
                                            <ReactPlayer
                                            muted={true}
                                            loop={true}
                                            url={"/assets/video/demo_lite_1.mp4"}
                                            playing={true}
                                            width={400}
                                            height={250}
                                                />
                                        </div>
                                    ):(
                                        <div className="col-12" style={{textAlign: "-webkit-center"}}>
                                        <ReactPlayer
                                        muted={true}
                                        loop={true}
                                        url={"/assets/video/demo_lite_1.mp4"}
                                        playing={true}
                                        width={520}
                                        />
                                        </div>
                                    )}




                                <div className="col-12" style={{textAlign: "-webkit-center", paddingTop: "30px"}}>
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        {window.type_device ? (
                                            <h2>{!this.state.langUS ? "Aprende 100% online en vivo": "Learn 100% online live"} </h2>
                                        ): (
                                            <TypingAnimation2/>
                                        )}

                                        </div>

                                        <h4>
                                        {!this.state.langUS ? "En DojoFullStack proponemos un nuevo modelo educativo que incluye entornos de aprendizaje sincrónicos y asincrónicos con un enfoque que vincula la teoría y la práctica, mediante un aprendizaje activo.":
                                         "At DojoFullStack we propose a new educational model that includes synchronous and asynchronous learning environments with an approach that links theory and practice, through active learning."}

                                            </h4>

                                    <div className="row mt--30">

                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> {!this.state.langUS ? "100% online a tu propio ritmo.": "100% online live"} </h4>
                                                </div>
                                            </div>


                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/>
                                                    Retroalimentación y Tutorías 1 a 1.
                                                    </h4>

                                                </div>
                                            </div>

                                     

                                            <div className="col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/>
                                                    {!this.state.langUS ? "Aprende, comparte y crea proyectos con miembros de todo el mundo.": "Learn, share and create projects with members from all over the world."}
                                                    </h4>
                                                </div>

                                            
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                {/* End About Area */}











                <div>
            </div>





                <div className="rn-pricing-table-area ptb--40 bg_color--5" data-aos="fade-right" id="inversion">

                        <div className="container px-1" >
                            <div className="row" style={{justifyContent: "center", textAlign: "-webkit-center"}}>


 <div className="col-12 mt-1" style={{paddingBottom: "13px", paddingRight: "15px", paddingLeft: "15px", maxWidth: "470px", marginRight: window.type_device ? "auto": "1px" }} >

    <div className="rn-pricing" style={{boxShadow: "rgba(140, 82, 255, 0.9) 4px 4px 15px 3px", border: "none", background: "linear-gradient(135deg, #C56CD6 0%,#3425AF 100%)", borderRadius: "20px"}} >
        <div className="pricing-table-inner">

            <div className="pricing-header pb-0 mb-2 mt-1">
                    
                    <p className="my-0 py-0" style={{color: 'white', fontSize: '23px', textAlign: 'center'}}>Inicia en</p>
                <div className="mb-3">
                    <FlipCountdown
                        size={'small'}
                        yearTitle='Year'
                        dayTitle='Días'
                        hourTitle='Horas'
                        minuteTitle='Min'
                        secondTitle='Segs'
                        monthTitle='Mes'
                        hideYear
                        hideMonth
                        endAt={this.state.fechaLimite} // Date/Time
                    />
                </div>


            <h3 className="text-white p-0 m-0">Bootcamp</h3>
            <h3 className="text-white">Web Full Stack</h3>

            
            <span style={{padding: "6px 10px", margin: "5px", "background-color": "slateblue", "border-radius": "7px", "color": "white", "font-size": "20px", fontWeight: "bold"}}>
                    Aprende a tu propio ritmo
            </span>


            <h3 className="text-white mt-1 mb-0">Costo Total</h3>
                 
                <span style={{color: "white", display: "inline-block"}}>
                <del style={{color: "lightgray", fontSize: "23px"}}>
                &nbsp; {this.state.price_completo_regular} &nbsp;
                </del>
                </span>


                <h3 className="pricing" style={{marginBottom: "5px", marginTop: '1px'}} >
                {this.renderPrice(this.state.price_completo)}
                </h3>

                {/* {this.state.countryCode == "MX" &&  <span style={{fontSize: "20px", color: "white", marginLeft: "3px"}}>  (Conversión) <br/> {this.state.priceConversion?.mx} </span>}
            {this.state.countryCode == "BO" &&  <span style={{fontSize: "20px", color: "white", marginLeft: "3px"}}>  (Conversión) <br/> {this.state.priceConversion?.bo}</span>} */}



                <div className="slide-btn" style={{marginTop: "15px", textAlign: "center"}}>
            <a href={`/inscripcion/${this.state.bootcampSlug}/${this.state.countryCode}`}>
                    <button
                    onClick={this.TrackEventConversion}
                    id="enroll_bootcamp_2"
                    style={{lineHeight: "28px", fontSize: "20px", marginBottom: "13px", padding: "15px 30px" }}
                    className=" btn-efect">
                    <GoRocket style={{fontSize: "20px", marginRight: "10px"}}/>
                    EMPEZAR INSCRIPCIÓN
                     </button>
                    </a>
            </div>


                <div className="col-12 text-center mt-2">
                    <button
                    onClick={() => this.setState({isOpenResume: !this.state.isOpenResume})}
                    name="submit"
                    id="mc-embedded-subscribe-"
                    style={{color: "white", marginTop: "5px", marginBottom: "5px", fontSize: "22px",
                    "border-radius": "20px",
                    "border": "2px solid white",
                    "padding": "9px 20px"
                }}>
                    Ver detalles
                    <AiOutlineRight style={{padding: "5px", fontSize: "30px"}}/>
                    </button>
                </div>


            </div>

        </div>
    </div>
</div>

{/* End PRicing Table Area  */}



{this.state.bootcampSlug.includes('beca') &&

<>

    <p style={{width: '100%', textAlign: 'center', fontSize: '23px', color: 'black', marginBottom: '5px'}}>Finaliza en</p>
    <div style={{width: '100%', borderRadius: '30px', height: '70px', backgroundColor: 'black', maxWidth: '380px'}}>
    <FlipCountdown
                size={'small'}
                yearTitle='Year'
                dayTitle='Días'
                hourTitle='Horas'
                minuteTitle='Min'
                secondTitle='Segs'
                monthTitle='Mes'
                hideYear
                hideMonth
                endAt={this.state.fechaLimite} // Date/Time
            />
    </div>



        <div className="mb-3 w-100" >
            <p style={{textAlign: 'center', fontSize: '23px', color: 'black', marginTop: '5px', width: '100%'}}>¡Últimas becas disponibles!</p>
        </div>

    {this.renderBeca()}

</>
}






                            </div>



                        </div>
                    </div>





{/* Start About Area */}
{false &&
<div className="about-area text-center  bg_color--5" id="" style={{paddingTop: "30px", paddingBottom: "25px"}}>
                   <div className="about-wrapper">
                       <div className="container" style={{maxWidth: "650px"}}>
                           <div className="row row--35 align-items-center">
                               <div className="col-12">
                                   <div className="about-inner inner">
                            

                   <div className="row align-items-center" style={{justifyContent: "center"}}>
                       <div>
                           <h3>Regístrate al webinar informativo</h3>

                           <div className="text-center">
                                   <button onClick={() => this.openModalWebinar() } style={{textAlignLast: "center", "font-weight": "600"}} className="rn-button-style--2 btn-primary-color-4 mt-2">
                                           <GoRocket style={{marginRight: "7px", fontSize: "30px"}} />
                                           Regístrate
                                    </button>

                           </div>


                       </div>

                                   </div>

                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
   }
               {/* End About Area */}






               <div style={{marginTop: "20px"}} className="section-title service-style--3 text-center mb--25 mb_sm--0 text-center">
                    <h2 className="title">{!this.state.langUS ? "Testimonios": "Testimonials"} </h2>
                </div>

                <div className="rn-testimonial-area bg_color--1 ptb--80">
                    <div className="container">
                        <TestimonialOne langUS={this.state.langUS} />
                    </div>
                </div>


               <Modal show={this.state.isOpenPlanPay} size="md" onHide={() => this.setState({isOpenPlanPay: false})} style={{zIndex: "9999999999"}} >
                    <span style={{textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.setState({isOpenPlanPay: false}) } style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>
                    <Modal.Body>
                    <div className="">
                        <div className="section-title">
                            <h3 className="title"></h3>

                            <div className="pricing-body mb-3">

                                     <img style={{borderRadius: "20px"}} src={this.state.countryCode === 'PE' ? this.state.planPagoPE :this.state.planPagoUSD } height={'600px'} />
                                    
                                      </div>
                                      <div className="mb-1 text-center">
                                        <button style={{fontSize: "18px"}} className="rn-button-style--2 btn-solid" onClick={() => this.setState({isOpenPlanPay: false})}>{!this.state.langUS ? "Cerrar": "Close"}</button>
                                      </div>
                    </div>

                    </div>

                    </Modal.Body>
                </Modal>





                <Modal show={this.state.isOpenResume} size="md" onHide={() => this.setState({isOpenResume: false})} style={{zIndex: "9999999999"}} >
                    <span style={{textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.setState({isOpenResume: false}) } style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="">
                        <div className="section-title">
                            <h3 className="title"></h3>

                            <div className="pricing-body mb-3">

                                      <ul className="list-style--1" style={{marginTop: "5px", color: 'black'}}>

                                        <div className="row">
                                            <div className="col-1">
                                                <span style={{"color":"slateblue", "fontSize": "22px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "4px", color: "slateblue"}} /> </span>
                                            </div>
                                            <div className="col-10 pl-2">
                                            <p style={{fontSize: "16px", "margin-bottom": "5px", fontWeight: "bold"}}>
                                                <b style={{color: "black"}}> Te ayudamos a despegar y potenciar tus habilidades, esto puede ahorrarle años valiosos de su tiempo.</b> <br/>
                                            </p>
                                            </div>
                                            </div>

                                            <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Aprende 100% online desde cualquier parte del mundo. <br/>
                                          </p>
                                    

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          100 horas (aprende a tu propio ritmo)<br/>
                                          </p>
                                   

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                           Seguimiento y tutorías 1 a 1. <br/>
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Prácticas cada semana.<br/></p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Aprende con proyectos Full Stack reales <br/> (Tienda Online, Web de pedidos)
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Grupo privado de WhatsApp.
                                          </p>

                                        
                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Certificación<br/></p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Una comunidad de apoyo profesional de por vida.<br/></p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Portafolio de proyectos y carta de presentación optimizada 👌.
                                          <br/></p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Programa de empleo, acceso a la bolsa laboral con empresas aliadas a nivel local e internacional. <br/></p>

                                      </ul>
                                      </div>
                                      <div className="mb-1 text-center">
                                        <button style={{fontSize: "18px"}} className="rn-button-style--2 btn-solid" onClick={() => this.setState({isOpenResume: false})}>{!this.state.langUS ? "Cerrar": "Close"}</button>
                                      </div>
                    </div>


                    </div>


                    </Modal.Body>
               
                </Modal>



                <Modal show={this.state.isBeca50} size="md" onHide={() => this.setState({isBeca50: false})} style={{zIndex: "9999999999"}} >
                <span style={{textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.setState({isBeca50: false})} style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                <p style={{textAlign: 'center', fontSize: '23px', color: 'white', marginBottom: '5px'}}>Finaliza en</p>
                <FlipCountdown
                            size={'small'}
                            yearTitle='Year'
                            dayTitle='Días'
                            hourTitle='Horas'
                            minuteTitle='Min'
                            secondTitle='Segs'
                            monthTitle='Mes'
                            hideYear
                            hideMonth
                            endAt={this.state.fechaLimite} // Date/Time
                        />

                    <Modal.Body>

                    <div className="mb-3">
                        <p style={{textAlign: 'center', fontSize: '23px', color: 'white', marginTop: '5px'}}>¡Últimas becas disponibles!</p>
                    </div>

                    <div>
                        {this.renderBeca()}
                    </div>

                    </Modal.Body>
            
                </Modal>







                <Modal show={this.state.isOpen} size="lg" onHide={this.openModal} >
                    <Modal.Body>
                        <img alt="certificado Desarrollador Web Full Stack" src="/assets/images/dojopy/CERTIFICADO_fullstack_js.webp"></img>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal}>{!this.state.langUS ? "Cerrar": "Close"}</button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.isOpen2} size="md" onHide={this.openModal2} style={{zIndex: "9999999999"}} >
                <span style={{textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.openModal2()} style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                            <p>
No te preocupes. Nuestros programas son aptos para principiantes y no requieren ningún conocimiento previo ni hay limite de edad.
Además, no estarás solo. Nuestro equipo estará a tu lado para guiarte en el proceso, ayudarte a establecer objetivos y a tener éxito.
                            </p>
                         
                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal2}>{!this.state.langUS ? "Cerrar": "Close"}</button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.isOpen3} size="md" onHide={this.openModal3} style={{zIndex: "9999999999"}} >
                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                            <h3 className="title"></h3>
                            <p>
                                Las aplicaciones web y los productos digitales cambiaron la forma en la que nos conectamos,
                             comunicamos y vivimos. <br/>
                              Conocer las bases de la programación y adquirir una base sólida de herramientas para desenvolverse en el mundo del desarrollo web es clave. <br/>
                               Hoy, la tecnología atraviesa todos los trabajos y, saber programar, se convirtió en una habilidad clave. <br/>
                                Aprenderás desde los fundamentos de la programación, hasta el lanzamiento de aplicaciones web completas; a resolver problemas aplicando las mejores prácticas de la industria.
                            </p>
                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal3}> {!this.state.langUS ? "Cerrar": "Close"} </button>
                    </Modal.Footer>
                </Modal>


        <Modal show={this.state.isOpenTemario} size={window.type_device ? "sm": "md"} onHide={this.openModalEmail} style={{zIndex: "999999", alignSelf: "center"}} >
                <div style={{marginTop: "15px"}}>
                <span style={{fontSize: "20px", "text-align": "left", fontWeight: "600", marginBottom: "1px", color: "black"}}>
                    <IoCloseCircleOutline onClick={() => this.openModalEmail()} style={{marginRight: "10px", cursor: "pointer", fontSize: "39px", marginLeft: "12px", color: "slateblue"}} />
                    Plan del Bootcamp
                    </span>
                </div>

            <Modal.Body className="modal-dojopy p-0">
                <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "370px"}}>
                            <div className="row row--35 align-items-center justify-content-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                                <div className="thumbnail" style={{paddingBottom: "8px", maxWidth: window.type_device ? "310px": "370px" }}>
                                <img style={{boxShadow: "#424241 6px 5px 15px 0px"}} className="image_temario" src={!this.state.langUS ? "/assets/images/dojopy/syllabus-compress.png": "/assets/images/dojopy/syllabus-compress.png"} alt="temario bootcamp"/>
                                </div>


                    <form onSubmit={this.onSubmit}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        <label class="col-12 text-left font-weight-bold px-4 text-white" htmlFor="nameDojo">
                            Nombre:
                            <input
                            id='nameDojo'
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"Hola! ¿Cuál es tu nombre?"}
                            />
                        </label>

                        {/* <label class="col-12 text-left font-weight-bold px-4 text-white" htmlFor="emailDojo">
                            Email:
                            <input
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="email"
                            name="email"
                            id="emailDojo"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder= {!this.state.langUS ? "¿Cuál es tu Email?": "What is your email?"}
                            />
                        </label> */}



             

                                <button className="rn-button-style--2 btn-solid" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "18px", marginTop: '1px', fontWeight: "bold"}}>
                                {!this.state.loadingEnroll  && <MdEmail style={{fontSize:"25px", marginRight: "5px"}} />}
                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "5px"}}
                                                />}
                                {!this.state.langUS ? "Descargar programa": "Send program"}
                                </button>

                                <div className="col-12 mt-2" style={{color: "white", fontSize: "13px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "black"}}>
                        <input
                            style={{width: 16, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span>{!this.state.langUS ? "Protección de datos y privacidad.": "Data protection and privacy."} </span>
                    </label>
                    </div>

                        </div>
                        </div>

                        </form>

                   

                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
        </Modal>



        <Modal show={this.state.isModalWS} size={window.type_device ? "md": "lg"} onHide={() => this.setState({isModalWS: true})} style={{zIndex: "9999999999"}} >

            <Modal.Body>
                <div className="about-inner inner">
                    <div className="section-title">
                        <h3 className="mt-3 p-2 text-white">¡Gracias {this.state.Name}, un paso más!</h3>
                    <p className="p-2 text-white">
                    ¿Deseas recibir información personalizada o aclarar alguna duda? ¡Envíanos un mensaje por WhatsApp y estaremos encantados de ayudarte!
                    </p>

                    <div className="w-100 m-2" onClick={() => {
                        this.sleep(3000).then(r => {
                            this.setState({isModalWS: false, isOpenModalInfo: false});
                        })
                    }}>
                    <a target="_blank" href="https://api.whatsapp.com/send?phone=51935489552&text=M%C3%A1s%20informaci%C3%B3n%20sobre%20el%20Bootcamp%20Full%20Stack%20Beca50%2C%20online.">
                    <button className="btn-grupo-ws">
                        <FaWhatsapp style={{marginRight: "3px", width: "25px", height: "25px"}}/>
                        Escríbenos por WhatsApp
                        </button>
                    </a>
                
                    </div>

            <p style={{fontSize: "17px", fontStyle: "italic", fontWeight: "bold", textAlign: "center", marginTop: "5px", color: 'white'}}>
                Recibirás un obsequio a través de whatsapp.
            </p>
                
            </div>
            </div>
            </Modal.Body>
        </Modal>





        <Modal id="modal-info" show={this.state.isOpenModalInfo} size={window.type_device ? "sm": "md"} onHide={() => this.setState({isOpenModalInfo: false}) } style={{zIndex: "999999", alignSelf: "center"}} >
                <div style={{marginTop: "20px", marginBottom: '10px'}}>
           
                </div>

            <Modal.Body className="modal-dojopy p-0" >
                <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "370px"}}>
                            <div className="row row--35 align-items-center justify-content-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                                {/* <div className="thumbnail" style={{paddingBottom: "8px", maxWidth: window.type_device ? "310px": "370px" }}>
                                <img style={{boxShadow: "#424241 6px 5px 15px 0px"}} className="image_temario" src="/assets/images/dojopy/beca-parcial-flex.webp" alt="temario bootcamp"/>
                                </div> */}



                                <p style={{
                                    marginTop: '15px',
                                    color: "white",
                                    fontSize: '33px',
                                    fontWeight: 'bold',
                                    fontFamily: "'Poppins',sans-serif"
                                }} className="subtitle-info">
                                    Obtener más información
                                </p>

                                <p className="my-1 text-white">
                                Obtén información detallada sobre el programa online, beneficios exclusivos y testimonios de nuestros alumnos.
                                </p>

                     <form onSubmit={this.onSubmit}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        {/* <label class="col-12 text-left font-weight-bold px-4 text-white" htmlFor="nameDojo">
                        <span style={{fontSize: "17px", color: "white"}}>Nombre:</span>
                            <input
                            id='nameDojo'
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"👋 Hola! ¿Cuál es tu nombre?"}
                            />
                        </label> */}

                        {/* <label class="col-12 text-left font-weight-bold px-4 text-white" htmlFor="emailDojo">
                            <span style={{fontSize: "17px", color: "white"}}>Email:</span>
                            <input
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="email"
                            name="email"
                            id="emailDojo"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder="📧 ¿Cuál es tu correo electrónico?"
                            />
                        </label> */}


                        {/* <div className="text-left" >
    <b style={{fontSize: "17px", marginLeft: "20px", color: "white"}}>
        WhatsApp:
    </b>
    
</div> */}
{/* 
<div className="d-flex justify-content-center mx-3">

<div className="mx-2 mt-2">
    <Selectrix
    className="pais-inscription"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    onChange={(value) => {
        if (this.state.Email){
            localStorage.setItem('email', this.state.Email);
        }
        if (this.state.Name){
            localStorage.setItem('name', this.state.Name);
        }
        if (this.state.Phone){
            localStorage.setItem('phone', this.state.Phone);
        }
    } }
    />

</div>

<div style={{width: "80%"}}>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="text"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="N.º de WhatsApp"
    />
 </label>
</div>

</div> */}




                                <button className="btn-efect" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "23px", marginTop: '5px', fontWeight: "bold"}}>
                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "5px"}}
                                                />}
                                <FaWhatsapp style={{fontSize: "23px", marginRight: "10px", color: '#25D366'}}/>
                                Solicitar información
                                </button>

                                <div className="col-12 mt-2 text-center" style={{color: "white", fontSize: "13px", textAlign: "center"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "black"}}>
                        <input
                            style={{width: 16, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span className="text-white">{!this.state.langUS ? "Protección de datos y privacidad.": "Data protection and privacy."} </span>
                    </label>
                    </div>

                        </div>
                        </div>

                        </form>

                   

                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
        </Modal>




        <Modal show={this.state.isOpenReunion} size={window.type_device ? "sm": "md"} onHide={() => this.setState({isOpenReunion: false})} style={{zIndex: "999999", alignSelf: "center" }} >
                <div style={{marginTop: "15px", position: 'relative'}}>
                <span style={{fontSize: "20px", "text-align": "left", fontWeight: "600", marginBottom: "1px", color: "black"}}>
                    <IoCloseCircleOutline onClick={() => this.setState({isOpenReunion: false}) } style={{marginRight: "10px", cursor: "pointer", fontSize: "39px", marginLeft: "12px", color: "slateblue"}} />
                    </span>
                    <img src="/assets/images/dojopy/50-off.png" style={{height: '90px', position: 'absolute', right: 0, margin: '5px'}} />
                </div>

            <Modal.Body className="modal-dojopy p-0" >
                <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "370px"}}>

                            <div className="row row--35 align-items-center justify-content-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                            
                            <h3 className="mb-1 mt-5 text-white">
                            Agenda una reunión informativa y obtén una beca del 50%
                            </h3>

                    <form onSubmit={this.onSubmit}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        <label class="col-12 text-left font-weight-bold px-4" htmlFor="nameDojo">
                            
                            <p style={{color: "white", marginBottom: '3px', marginTop: '1px', fontSize: '15px', textAlign: 'left', fontWeight: 'bold'}}>Nombre:</p>
                            <input
                            id='nameDojo'
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"Hola! ¿Cuál es tu nombre?"}
                            />
                        </label>

                        <label class="col-12 text-left font-weight-bold px-4" htmlFor="emailDojo">
                           
                        <p style={{color: "white", marginBottom: '3px', marginTop: '1px', fontSize: '15px', textAlign: 'left', fontWeight: 'bold'}}>Email:</p>

                            <input
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="email"
                            name="email"
                            id="emailDojo"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder= {!this.state.langUS ? "¿Cuál es tu Email?": "What is your email?"}
                            />
                        </label>


    <p style={{color: "white", marginBottom: '3px', marginTop: '1px', fontSize: '15px', marginLeft: '1.5rem', textAlign: 'left', fontWeight: 'bold'}}>
    N.º de WhatsApp:
    </p>

<div className="d-flex justify-content-center mx-4">
<div className="mx-1 mt-2">
    <Selectrix
    className="pais-inscription"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    onChange={(value) => {
        if (this.state.Email){
            localStorage.setItem('email', this.state.Email);
        }
        if (this.state.Name){
            localStorage.setItem('name', this.state.Name);
        }
        if (this.state.Phone){
            localStorage.setItem('phone', this.state.Phone);
        }
    } }
    />

</div>

<div>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="text"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="N.º Celular"
    />
 </label>
</div>

</div>

             

                                <button className="btn-efect" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "23px", marginTop: '1px', fontWeight: "bold"}}>
                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "5px"}}
                                                />}
                                Agendar reunión
                                </button>

                                <div className="col-12 mt-2" style={{color: "white", fontSize: "13px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "black"}}>
                        <input
                            style={{width: 16, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span>{!this.state.langUS ? "Protección de datos y privacidad.": "Data protection and privacy."} </span>
                    </label>
                    </div>

                        </div>
                        </div>

                        </form>

                   

                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
        </Modal>



        {false &&
        <Modal show={this.state.isOpenSemiBeca} size="md" onHide={() => this.setState({isOpenSemiBeca: false})} style={{zIndex: "999999", alignSelf: "center"}}>
                <div style={{marginTop: "15px"}}>
                <span style={{fontSize: "20px", "text-align": "left", fontWeight: "600", marginBottom: "1px", color: "black"}}>
                    <IoCloseCircleOutline onClick={() => this.setState({isOpenSemiBeca: false})} style={{marginRight: "10px", cursor: "pointer", fontSize: "39px", marginLeft: "12px", color: "slateblue"}} />
                    </span>

                    <span style={{alignSelf: "center", marginRight: "15px", marginTop: "15px", fontWeight: "bold", fontSize: "16px" }}>
                            Finaliza en:
                        </span>

                    <span style={{display: "inline-flex", marginLeft: "10px"}}>
                      
                    <FlipCountdown
                            size='small'
                            yearTitle='Year'
                            dayTitle='Días'
                            hourTitle='Horas'
                            minuteTitle='Min'
                            secondTitle='Segs'
                            monthTitle='Mes'
                            hideYear
                            hideMonth
                            hideDay
                            endAt={this.state.timestamp24h} // Date/Time
                            />
                    </span>


                </div>

            <Modal.Body >
                <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "370px"}}>
                            <div className="row row--35 align-items-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                                <div className="thumbnail" style={{paddingBottom: "8px" }}>
                                <img style={{boxShadow: "#424241 6px 5px 15px 0px"}} className="image_temario" src={this.state.semibeca_img} alt="Descuento Especial"/>
                                </div>


                    <form onSubmit={this.onSubmitBeca}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        <label class="col-12" htmlFor="item02">
                            <input
                            style={{marginBottom: "5px"}}
                            type="email"
                            name="email"
                            id="item02"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder="¿Cuál es tu correo electrónico?"
                            />
                        </label>


                    <div className="row p-2">
                        <div className="col-5" style={{maxWidth: "145px"}}>
                            <Selectrix
                            height={300}
                            onRenderOption={this.onRenderOption}
                            onRenderSelection={this.onRenderSelection}
                            placeHolderInside={true}
                            placeholder={"País"}
                            customScrollbar={true}
                            searchable={false}
                            materialize={false}
                            defaultValue={this.state.countryCode}
                            options={KeyCountry}
                            onChange={(value) => {
                                // if (value.key === 'US'){ window.location.href = '/us'}
                                this.DynamicCountry(value.key)}
                            }
                            />
                        </div>


                        <label className="col-7" htmlFor="Phone" style={{paddingLeft: "1px"}}>
                            <input
                            style={{marginBottom: "5px"}}
                            type="text"
                            name="phone"
                            id="Phone"
                            value={this.state.Phone}
                            onChange={(e) => {
                                this.setState({ Phone: e.target.value });
                            }}
                            placeholder="N.º WhatsApp"
                            />
                        </label>

                    </div>

                    <div className="col-12" style={{color: "white", fontSize: "12.5px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "black"}}>
                        <input
                            style={{width: 18, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span style={{fontSize: "12.5px"}}>Protección de datos y privacidad.
                        </span>
                    </label>
                    </div>

                                <button className="rn-button-style--2 btn-solid" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "18px", marginTop: '1px', fontWeight: "bold"}}>
                                <GoRocket style={{fontSize:"25px"}} /> Obtener descuento adicional
                                </button>
                        </div>
                        </div>

                        </form>

                        <div>
                        <button onClick={() => this.setState({isOpenSemiBeca: false}) } className="rn-button-style--1 btn-solid" type="submit" name="submit"  style={{marginTop: '13px', fontSize: "18px"}}>
                        {!this.state.langUS ? "Cerrar": "Close"}
                        </button>
                        </div>

                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
        }



                <Modal show={this.state.isOpenSuscribe} size="xl" onHide={() => this.setState({isOpenSuscribe: false, selectFecha: false})} style={{top:  window.type_device ? "1%": "15%", zIndex: "999999"}}>

                <span style={{marginLeft: "5px", marginTop: "5px", textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.setState({isOpenSuscribe: false, selectFecha: false}) } style={{ cursor: "pointer", fontSize: "41px", marginLeft: "12px", marginRight: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                <Modal.Body className="body-evento">

                <div className="inner">
                    <div className="contact-form--1">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                <h3 className="mb-1" style={{fontSize: window.type_device ? "33px": "43px"}}>
                                Regístrate al Webinar de Introducción
            <span
                  style={{padding: "3px", marginLeft: "7px", "background-color": "black", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": window.type_device ? "25px": "29px" }}>
                    Gratuito
            </span>
                                {/* <img style={{marginLeft: "9px"}} alt="canal zoom" height="70px" src="/assets/images/dojopy/zoom_.webp"></img> */}
                                </h3>

                                <div style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                                    <>
                                    <h5 className="mb-2 mt-1" style={{fontWeight: "bold"}}>
                                    ¡Únete a la revolución tecnológica y desarrolla tu futuro! <br/>
                                    Regístrate para acceder a una sesión demostrativa exclusiva.
                                    </h5>


                                    {window.type_device &&
                                    <>
                                     <div data-aos="fade-right" className="col-12 col-md-6 px-1" style={{marginBottom: "25px"}}>
                <div style={{textAlign: "center", padding: "5px", boxShadow: "rgba(140, 82, 255, 0.9) 4px 4px 15px 3px", border: "none", background: "linear-gradient(135deg, #C56CD6 0%,#3425AF 100%)", borderRadius: "20px" }}>

            <form onSubmit={this.onSubmit} className="px-1" style={{paddingTop: "10px", paddingBottom: "10px" }}>

            <span  style={{color: "white", marginTop: "1px", marginBottom: "5px", padding: "5px 7px", border: "1px solid white", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>
                    Duración 2 horas
                    </span>

        <label class="col-12 text-left font-weight-bold" htmlFor="nameDojo">
            <span style={{fontSize: "16px", color: "white"}}>
            Nombre:
            </span>
            <input
            id='nameDojo'
            style={{background: "white", marginBottom: "15px"}}
            type="text"
            name="name"
            value={this.state.Name}
            onChange={(e) => this.setState({ Name: e.target.value})}
            placeholder= {"👋 Hola! ¿Cuál es tu nombre?"}
            />
        </label>

            <label className="col-12 text-left font-weight-bold" htmlFor="item02">
            <span style={{fontSize: "16px", color: "white"}}>
            Email:
            </span>
                <input
                    style={{background: "white"}}
                    type="email"
                    name="email"
                    id="item02"
                    value={this.state.Email}
                    onChange={(e)=>{this.setState({Email: e.target.value});}}
                    placeholder="📧 ¿Cuál es tu Email?"
                    />
            </label>


<div className="text-left" >
    <b style={{fontSize: "16px", marginLeft: "16px", color: "white"}}>
        N.º de WhatsApp:
    </b>
</div>

<div className="d-flex justify-content-center mx-3">

<div className="mx-2 mt-2">
    <Selectrix
    className="pais-inscription"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    onChange={(value) => {
        if (this.state.Email){
            localStorage.setItem('email', this.state.Email);
        }
        if (this.state.Name){
            localStorage.setItem('name', this.state.Name);
        }
        if (this.state.Phone){
            localStorage.setItem('phone', this.state.Phone);
        }
    } }
    />

</div>

<div style={{width: "80%"}}>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="text"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="N.º Celular"
    />
 </label>
</div>

</div>



                <button
                id="submit-contacto"
className="btn-efect"
type="submit" name="submit-contacto"  style={{width: "90%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>

{!this.state.loadingEnroll  && <b style={{fontSize: "25px"}}>Regístrate <AiOutlineRight style={{marginLeft: "6px", marginBottom: "3px"}} /></b> }

{this.state.loadingEnroll  &&
                <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{fontSize: "20px", marginRight: "8px"}}
                />
}

</button>

   
   
<div className="row mt-3">
                    <div className="col-12 mx-3" style={{color: "white", fontSize: "13px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left"}}>
                        <input
                            style={{width: 15, float: "left", marginTop: "-10px", marginRight: "5px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span>Protección de datos y privacidad.</span>
                    </label>
                    </div>
                </div>


            </form>
                    </div>
            

        </div>
                                    </>
                                    }


                                    <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    ¿Qué es el Desarrollo Web Full Stack y qué aprenderás en el BootCamp?
                                    
                                    </p>
                                    <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    ¿Cómo es la metodología de aprendizaje online en vivo?
                                    </p>
                                    <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    ¿Cuál es la RoadMap o ruta de aprendizaje?
                                    
                                    </p>
                                    <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    ¿Qué es Frontend y Backend?
                                    
                                    </p>
                                    <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    Empleabilidad Full Stack
                                    </p>
                                    <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    Dinámica de prácticas grupales en vivo.
                                    </p>
                                    <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                    Introducción a HTML, CSS Y JavaScript.
                    </p>
                                    <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    Recibe una beca con un descuento exclusivo.
                                    </p>
                                    </>
                                </div>


                            {!window.type_device && <>
                                <div data-aos="fade-right"  className="col-12 col-md-6" style={{marginBottom: "25px"}}>
                <div style={{textAlign: "center", padding: "5px", boxShadow: "rgba(140, 82, 255, 0.9) 4px 4px 15px 3px", border: "none", background: "linear-gradient(135deg, #C56CD6 0%,#3425AF 100%)", borderRadius: "20px" }}>

            <form onSubmit={this.onSubmit} className="px-1" style={{paddingTop: "10px", paddingBottom: "10px" }}>

            <span  style={{color: "white", marginTop: "1px", marginBottom: "5px", padding: "5px 7px", border: "1px solid white", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>
                    Duración 2 horas
                    </span>

        <label class="col-12 text-left font-weight-bold" htmlFor="nameDojo">
            <span style={{fontSize: "16px", color: "white"}}>
            Nombre:
            </span>
            <input
            id='nameDojo'
            style={{background: "white", marginBottom: "15px"}}
            type="text"
            name="name"
            value={this.state.Name}
            onChange={(e) => this.setState({ Name: e.target.value})}
            placeholder= {"👋 Hola! ¿Cuál es tu nombre?"}
            />
        </label>

            <label className="col-12 text-left font-weight-bold" htmlFor="item02">
            <span style={{fontSize: "16px", color: "white"}}>
            Email:
            </span>
                <input
                    style={{background: "white"}}
                    type="email"
                    name="email"
                    id="item02"
                    value={this.state.Email}
                    onChange={(e)=>{this.setState({Email: e.target.value});}}
                    placeholder="📧 ¿Cuál es tu Email?"
                    />
            </label>


<div className="text-left" >
    <b style={{fontSize: "16px", marginLeft: "16px", color: "white"}}>
        N.º de WhatsApp:
    </b>
</div>

<div className="d-flex justify-content-center mx-3">

<div className="mx-2 mt-2">
    <Selectrix
    className="pais-inscription"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    onChange={(value) => {
        if (this.state.Email){
            localStorage.setItem('email', this.state.Email);
        }
        if (this.state.Name){
            localStorage.setItem('name', this.state.Name);
        }
        if (this.state.Phone){
            localStorage.setItem('phone', this.state.Phone);
        }
    } }
    />

</div>

<div style={{width: "80%"}}>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="text"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="N.º Celular"
    />
 </label>
</div>

</div>



                <button
className="btn-efect"
type="submit" name="submit"  style={{width: "90%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>

{!this.state.loadingEnroll  && <b style={{fontSize: "25px"}}>Regístrate <AiOutlineRight style={{marginLeft: "6px", marginBottom: "3px"}} /></b> }

{this.state.loadingEnroll  &&
                <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{fontSize: "20px", marginRight: "8px"}}
                />
}

</button>

   
   
<div className="row mt-3">
                    <div className="col-12 mx-3" style={{color: "white", fontSize: "13px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left"}}>
                        <input
                            style={{width: 15, float: "left", marginTop: "-10px", marginRight: "5px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span>Protección de datos y privacidad.</span>
                    </label>
                    </div>
                </div>


            </form>
                    </div>
            

        </div>
        </> }


                                </div>
                        </div>
                        </div>


                    </Modal.Body>

                </Modal>








<div className="rn-testimonial-area bg_color--1 ptb--80" style={{"padding": "10px"}}>
    <div className="container">
            <div className="rn-testimonial-area bg_color--1 ptb--80" style={{"padding-bottom": "30px"}}>
                    <div className="container">
                        <TestimonialTree langUS={this.state.langUS} />
                    </div>
                </div>
    </div>
</div>





                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo langUS={this.state.langUS} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}







  {/* Start Team Area  */}
  <div className="rn-team-area ptb--80 bg_color--1" id="team" data-aos="fade-right" >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                        <h2 className="title">{!this.state.langUS ? "Nuestro Equipo": "Our team"} </h2>
                                        <p>{!this.state.langUS ? "Nos apasiona la tecnológica y los desafíos de este mundo cambiante.": "We are passionate about technology and the challenges of this changing world."}  </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{justifyContent: "center"}}>
                                <TeamFull column="col-4 col-md-2" />
                            </div>
                        </div>
                </div>
                {/* End Team Area  */}





                {/* Start Team Area  */}
                {/* <div data-aos="fade-up" className="rn-team-area ptb--80 bg_color--1" id="modules">
                    <div className="container" style={{"maxWidth": "900px"}}>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center mb--30">
                                    <h3 className="title">
                                    {!this.state.langUS ? "El Bootcamp está formado por los siguientes módulos":
                                     "The Bootcamp is made up of the following modules"}
                                        </h3>
                                </div>
                                <div>
                            <AccordionWrapper>
                                <AccordionItem  index={0} title={!this.state.langUS ? "Fundamentos del Desarrollo Web": "Fundamentals of Web Development"} description={!this.state.langUS ? InfoAcordeon.web: InfoAcordeonUS.web}></AccordionItem>
                                <AccordionItem  index={1} title={!this.state.langUS ? "Git & Github": "Git & Github"} description={!this.state.langUS ? InfoAcordeon.git: InfoAcordeonUS.git}></AccordionItem>
                                <AccordionItem index={2} title={!this.state.langUS ? "JavaScript": "JavaScript"} description={!this.state.langUS ? InfoAcordeon.js: InfoAcordeonUS.js}></AccordionItem>
                                <AccordionItem index={3} title={!this.state.langUS ? "Frontend con React JS": "Frontend with ReactJS"} description={!this.state.langUS ? InfoAcordeon.react: InfoAcordeonUS.react}></AccordionItem>
                                <AccordionItem index={4} title={!this.state.langUS ? "Bases de Datos": "Databases"} description={!this.state.langUS ? InfoAcordeon.database: InfoAcordeonUS.database}></AccordionItem>
                                <AccordionItem index={5} title={!this.state.langUS ? "Backend con Python y Django": "Backend with Python and Django"} description={!this.state.langUS ? InfoAcordeon.python: InfoAcordeonUS.python}></AccordionItem>
                                <AccordionItem index={6} title={!this.state.langUS ? "API REST": "API REST"} description={!this.state.langUS ? InfoAcordeon.api: InfoAcordeonUS.api}></AccordionItem>
                                <AccordionItem index={7} title={!this.state.langUS ? "Testing & Seguridad": "Testing & Security"} description={!this.state.langUS ? InfoAcordeon.apiSecure: InfoAcordeonUS.apiSecure}></AccordionItem>
                                <AccordionItem index={8} title={!this.state.langUS ? "Servicios en la nube con Amazon Web Services": "Deployment in the AWS cloud"} description={!this.state.langUS ? InfoAcordeon.deploy_aws: InfoAcordeonUS.deploy_aws}></AccordionItem>
                                <AccordionItem index={9} title={!this.state.langUS ? "Proyecto Full Stack": "Full Stack Project"} description={!this.state.langUS ? InfoAcordeon.project: InfoAcordeonUS.project}></AccordionItem>
                                <AccordionItem index={10} title={!this.state.langUS ? "Empleabilidad": "Employability"} description={!this.state.langUS ? InfoAcordeon.employer: InfoAcordeonUS.employer}></AccordionItem>
                            </AccordionWrapper>
                                </div>
                            </div>
                        </div>

                    </div>


                </div> */}
                {/* End Team Area  */}



  {/* Start Team Area  */}
  <div data-aos="fade-up" className="rn-team-area ptb--20 bg_color--1" id="roadmap" style={{marginBottom: "-5px" }}>
                    <div className="container" style={{"maxWidth": "900px"}}>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center mb--30">
                                    <h2 className="title">RoadMap</h2>
                                    <h5>
                                    El Bootcamp está formado por los siguientes módulos
                                    </h5>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}


<VerticalTimeline
    lineColor="slateblue"
>
<VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'darkgreen', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  darkgreen' }}
    iconStyle={{ background: 'darkgreen', color: 'white' }}
    icon={<GoRocket style={{fontSize: "25px"}}/>}
  >
    <h3 className="vertical-timeline-element-title"> {!this.state.langUS ? "Acceso Plataforma" : "Platform Access"} </h3>
    <p>
    {!this.state.langUS ? <>
        Como miembro de DojoFullStack, tendrás acceso inmediato a la
    Plataforma innovadora para prepararse y practicar antes de las sesiones en vivo del Bootcamp.
    La plataforma está impulsada por Inteligencia Artificial y ofrece lecciones y retroalimentación personalizadas
    según el progreso del estudiante.
    También hay acceso a múltiples módulos, proyectos y soluciones compartidas de la comunidad.

    </> : <>
    As a DojoFullStack member, you will have immediate access to the
     Innovative platform to prepare and practice before live Bootcamp sessions.
     The platform is powered by Artificial Intelligence and offers personalized lessons and feedback
     based on student progress.
     There is also access to multiple modules, projects and shared solutions from the community, as well as a DojoFullStack own community.
    </> }

    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    iconStyle={{ background: 'midnightblue', color: 'white' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title"> {!this.state.langUS ? "Fundamentos del Desarrollo Web" : "Fundamentals of Web Development"} </h3>
    <p>
    <>
        Te familiarizarás con los conceptos y herramientas fundamentales del desarrollo web y adquirirás los conocimientos esenciales para abordar el resto del bootcamp.
        Trabajando con HTML y CSS, implementando prácticas de versionado de código con GIT, desplegarás tu sitio a un servidor y aprenderás a interactuar con este servicio.
    </>
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">Git & Github</h3>
    <p>
    Aprende las herramientas esenciales que necesitas para trabajar en proyectos profesionales,
    un sistema de control de versiones es indispensable, aprende Git el SCV más popular y más utilizado.
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">JavaScript</h3>
    <p>
    {!this.state.langUS ? <>
        Aprenderemos ECMAScript 2023 la última actualización de JavaScript y profundizaremos en cuestiones como el paradigma de programación orientada a objetos, la gestión de asincronía. Además introduciremos el concepto de APIs y comprenderás cómo consultarlas.
    </> : <>
    We will learn ECMAScript 2023 the latest JavaScript update and we will delve into issues such as the object-oriented programming paradigm, asynchrony management. We will also introduce the concept of APIs and you will understand how to consult them.
    </>}
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">{!this.state.langUS ? "Frontend con React JS" : "Frontend with ReactJS"} </h3>
    <p>
    {!this.state.langUS ? <>
        En este módulo, que es el tercer nivel del bootcamp, aprenderás a programar por componentes, mediante Javascript y React, y también conocerás las ventajas de la utilización del flujos de datos.  Comprenderás la utilización del virtual DOM mediante los desarrollos de React JS. Al finalizar, podrás crear tus propias aplicaciones SPA, y estarás en condiciones de hacer cualquier desarrollo con uno de los frameworks más populares y avanzados del momento.
    </> : <>
    In this module, which is the third level of the bootcamp, you will learn to program by components, using Javascript and React, and you will also learn the advantages of using data flows. You will understand the use of the virtual DOM through React JS developments. Upon completion, you will be able to create your own SPA applications, and you will be able to do any development with one of the most popular and advanced frameworks of the moment.
    </> }
    </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    contentStyle={{ background: 'purple', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  purple' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'purple', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title"> {!this.state.langUS ? "Bases de Datos" : "Databases"} </h3>
    <p>
    Es muy importante saber qué, cómo y dónde se guardarán tus datos. Aprende desde cero los fundamentos, el lenguaje de consultas SQL y prácticas para administrar DBs profesionalmente.
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'slateblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  slateblue' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'slateblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">{!this.state.langUS ? "Backend con Python" : "Backend with Python"} </h3>
    <p>
    El backend es una parte esencial de cualquier aplicación web, encargada de procesar y almacenar datos, así como de gestionar la lógica y la comunicación con el frontend. Python se ha establecido como una opción popular y confiable para el desarrollo de backend gracias a su elegante sintaxis, su amplia variedad de bibliotecas y frameworks, y su eficiencia en el manejo de tareas complejas. En este sentido, Python se destaca por su capacidad para manejar de manera eficiente aplicaciones de alto rendimiento y escalar de manera efectiva a medida que crece la demanda de usuarios y eventos.
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'slateblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  slateblue' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'slateblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">{!this.state.langUS ? "Backend con Django" : "Backend with Django"} </h3>
    <p>
        El framework de aplicaciones web para construir aplicaciones web robustas y escalables, Django es el framework backend más popular en Python, Instagram y Dropbox lo utilizan.
    </p>
  </VerticalTimelineElement>




  <VerticalTimelineElement
    contentStyle={{ background: 'purple', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  slateblue' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'purple', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">{!this.state.langUS ? "API REST" : "API REST"}  </h3>
    <p>
    En este módulo de API REST con Django explorarás los conceptos fundamentales de las APIs REST, como los métodos HTTP, los recursos, los endpoints y la autenticación. Aprenderás cómo diseñar una API RESTful siguiendo las mejores prácticas, manteniendo la coherencia y facilitando la interacción con otras aplicaciones y servicios.
    </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    contentStyle={{ background: 'purple', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  purple' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'purple', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">{!this.state.langUS ? "Testing & Seguridad" : "Testing & Security"}  </h3>
    <p>
    En este módulo aprenderás a implementar pruebas automatizadas para verificar el correcto funcionamiento de tu código, así como a identificar y solucionar posibles vulnerabilidades y riesgos de seguridad en tu aplicación. Este módulo te dotará de las habilidades necesarias para garantizar la confiabilidad y protección de tus proyectos web, ofreciendo una experiencia segura y libre de errores para los usuarios finales.
    </p>
  </VerticalTimelineElement>


  <VerticalTimelineElement
    contentStyle={{ background: 'mediumseagreen', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  mediumseagreen' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'mediumseagreen', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">{!this.state.langUS ? "Proyecto Web Full Stack" : "Full Stack Web Project"} </h3>
    <p>
        El proyecto final sirve para certificar todo el aprendizaje poniéndolo en práctica creando una aplicación funcional inspirada en Rappi o Airbnb.
         El proyecto final es una herramienta increíble para agregar a tu portafolio y así mostrar tu trabajo en tus próximas oportunidades laborales 😎!
       </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    contentStyle={{ background: 'darkorange', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  darkorange' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'darkorange', color: '#fff' }}
    icon={<FaRunning />}
    date="Programa optativo (4 semanas)"
  >
    <h3 className="vertical-timeline-element-title">{!this.state.langUS ? "Servicios en la nube con Amazon Web Services" : "Deployment in the AWS cloud"}  </h3>
    <h4 className="vertical-timeline-element-subtitle">Programa optativo  <br/> (16 horas)</h4>
    <p>
    Implementa una Aplicación Web completa en la infraestructura de AWS y SERVERLESS.
     Aplicado para aplicaciones web y aplicaciones móviles.
    </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">{!this.state.langUS ? "Empleabilidad" : "Employability"} </h3>
    <p>
    Al finalizar del bootcamp, nuestro equipo de talento te asesorará para lograr tus objetivos laborales.
    </p>

  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'goldenrod', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  goldenrod' }}
    iconStyle={{ background: 'goldenrod', color: '#fff' }}
    icon={<FaGraduationCap />}
  >
    <h3 className="vertical-timeline-element-title">{!this.state.langUS ? "Graduación BootCamp 2024" : "BootCamp Graduation 2023"}  </h3>
    <p>
        <b>
        🚀 Soy Desarrollador Web Full Stack! 😎
        </b>
    </p>

  </VerticalTimelineElement>



    </VerticalTimeline>






    <PreguntasFlex langUS={this.state.langUS} bootcampSlug={this.state.bootcampSlug} />






                {/* End About Area */}

                <ModalVideo style={{zIndex: 99999999}} channel='youtube' isOpen={this.state.VideoPitch} videoId='XCS9vKVUBiI' onClose={() => this.setState({VideoPitch: false})} />


                {window.location.search.split('source=')[1]?.includes('fb') &&
                <div className="footer_banner" style={{borderTopLeftRadius: "30px", borderTopRightRadius: "30px", zIndex: "99", padding: "1px", background: this.props.FooterColor }}>
                    <div className="row dojofoter" style={{
                        marginTop: !window.type_device ? "3px" : "2px",
                        marginBottom: !window.type_device ? "3px": "3px",
                        paddingLeft: !window.type_device ? "250px" : "15px",
                        paddingRight: !window.type_device ? "250px": "15px",
                        paddingTop: !window.type_device ? "7px": "3px",
                        paddingBottom: !window.type_device ? "7px": "3px",
                        placeContent: 'center'
                        }}>
                    <div className={"d-flex"} style={{ alignItems: "center", textAlign: "center", alignSelf: "center", flexDirection: 'column'}} >
            
                        
                   {window.type_device &&     <p className="mb-0 text-white py-1" style={{fontSize: '25px', fontWeight: 'bold'}}>Mensualidad</p>}
                        <div className="d-flex" style={{alignItems: 'center'}} >

                        {!window.type_device &&     <p className="mb-0 text-white py-1 px-1" style={{fontSize: '25px', fontWeight: 'bold'}}>Mensualidad:</p>}

                            <div className="m-1">
                                <del className="text-white" style={{fontSize: '19px'}}>
                                    &nbsp;  {this.state.price_mensual_regular} &nbsp;
                                </del>                                
                            </div>
                            {this.renderPrice(this.state.price_mensual)}
                        </div>
                    
                    <div className="text-center mt-2 mb-2">
                        <button
                            id="footer_info"
                            style={{lineHeight: "25px", fontSize: window.type_device ? "20px": "25px", padding: "12px 27px" }}
                            className=" btn-efect">
                            Recibir información
                        </button>
                    </div>
           
                    </div>
                    </div>
                </div>
                }


   
                <div className="footer_banner" style={{borderTopLeftRadius: "30px", borderTopRightRadius: "30px", zIndex: "99", padding: "1px", background: this.props.FooterColor }}>
                    <div className="row dojofoter" style={{
                        marginTop: !window.type_device ? "3px" : "2px",
                        marginBottom: !window.type_device ? "3px": "3px",
                        paddingLeft: !window.type_device ? "250px" : "15px",
                        paddingRight: !window.type_device ? "250px": "15px",
                        paddingTop: !window.type_device ? "7px": "3px",
                        paddingBottom: !window.type_device ? "7px": "3px"
                        }}>
                    <div className={"col-12 py-0 px-0 m-1"} style={{ alignItems: "center", textAlign: "center", alignSelf: "center"}} >
                    <a href="#informes">
                    <button
                        id="footer_info_"
                        style={{lineHeight: "25px", fontSize: window.type_device ? "23px": "25px", padding: "12px 27px" }}
                        className=" btn-efect">
                            Recibir información
                    </button>
                    </a>

                    </div>
                    </div>
                </div>
    

          
                

                 {/* Start Footer Style  */}
                 <FooterTwo langUS={this.state.langUS} />
                {/* End Footer Style  */}

            </Fragment>
        )
    }

    getDaysElapsed(fechaCaducidad) {
        const fechaActual = new Date();
        const diferenciaMilisegundos = fechaActual - fechaCaducidad;
        const milisegundosEnDia = 1000 * 60 * 60 * 24;
        const diasPasados = Math.floor(diferenciaMilisegundos / milisegundosEnDia);
        return diasPasados;
      }

    initDiscount(){
        const fechaActual = new Date();
        let keyDiscount = localStorage.getItem('keyDiscountFullStack');
        // crear descuento, caduca en  24 horas en milisegundos
        const milisegundosEn24Horas = 24 * 60 * 60 * 1000;
        const fechaSumada = new Date(fechaActual.getTime() + milisegundosEn24Horas );

        if (!keyDiscount){
            // console.log('crear keyDiscount', fechaSumada);
            localStorage.setItem('keyDiscountFullStack', fechaSumada);
            this.setState({FechaEspecial24h:fechaSumada});
            return true
        } else {
            keyDiscount = new Date(keyDiscount);
            // console.log('ya existe keyDiscount', keyDiscount);
            if (fechaActual > keyDiscount ){
                // ya caduco
                const diasTranscurridos = this.getDaysElapsed(keyDiscount);
                if (diasTranscurridos >= 7){
                    // renovar descuento
                    console.log('renovar descuento');
                    localStorage.setItem('keyDiscountFullStack', fechaSumada);
                    this.setState({FechaEspecial24h: fechaSumada});
                    return true
                }
                return false
            } else {
                // sigue activo la fecha
                this.setState({FechaEspecial24h: keyDiscount});
                return true
            }
        }
    }

    componentDidMount() {
        this.fetchPricingData();
    }

    async fetchPricingData() {

        const pathdata = window.location.pathname;
        // console.log('pathdata', pathdata);

        if (pathdata.includes('beca50')){
            this.setState({bootcampSlug: 'webfullstack-beca50', bootcampSlugMensual: 'webfullstack-beca50'});

            this.sleep(20000).then(r => {
                this.setState({isBeca50: true});
            })

        }

        
        let fechaActual = new Date();

        try {
            const response = await axios.get("https://hiuaik2gocmwdeh2ub6lqz3hxq0ycvbe.lambda-url.us-east-1.on.aws/");
            let dataPrice = response.data;
            this.setState({pricingAPI: dataPrice})


            this.DynamicCountry();

 

            this.sleep(1000).then(r => {
                document.getElementById('whatsapp').style.display='block';
                // document.getElementById('iframe_chat').src = 'https://ai.dojopy.com/?config=dojopy-asesor&agente=1&corpus_id=dojopy_data.txt';
                // document.getElementById('chat-ai').style.display='block';
            })


      
            try {
                const responseIP = await axios.get("https://ipapi.co/json/");
                let dataPriceIP = responseIP.data;
                if (!dataPriceIP.country_code) throw "api error";

                this.setState({countryCode: dataPriceIP.country_code});
                this.DynamicCountry(dataPriceIP.country_code);

            } catch {
                axios.get('https://bdzpehnjfmdq56bcrp6gb4mcru0nxqci.lambda-url.us-east-1.on.aws/').then((response) => {
                    let data = response.data;

                    this.setState({countryCode: data.country_code});
                    this.DynamicCountry(data.country_code);
                    }).catch((error) => {
                        console.log("error api");
                    });
            }



        } catch (error) {
            console.log(error);
          }



        this.sleep(25000).then(r => {
            toast.success("Geiler y Jhon ya se inscribieron, tu tambien puedes formar parte!");
        })



        // this.sleep(10000).then(r => {
        //     this.setState({isOpenModalInfo: true});
        // })


        var url = window.location.href;


        const email = localStorage.getItem('email');
        const name = localStorage.getItem('name');
        const phone = localStorage.getItem('phone');

        if (email){
            this.setState({Email: email});
        }
        if (name){
            this.setState({Name: name});
        }
        if (phone){
            this.setState({Phone: phone});
        }


    }

}


export default BootcampFlex;
