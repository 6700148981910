import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import axios from "axios";
import Selectrix from "react-selectrix";
import Modal from "react-bootstrap/Modal";

import "react-toastify/dist/ReactToastify.css";
import ReactCountryFlag from "react-country-flag";
import FlipCountdown from "@rumess/react-flip-countdown";
import { GoRocket } from "react-icons/go";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import TestimonialOne from "../../blocks/testimonial/TestimonialOne";
import Typical from 'react-typical';
import { FiCheck, FiClock, FiUser, FiMonitor, FiRadio, FiDownload, FiVideo, FiCheckCircle, FiPackage} from "react-icons/fi";
import { FaRegCalendarAlt, FaWhatsapp } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";
import BrandTwo from "../../elements/BrandTwo";
import cx from "classnames";
import NonPassiveTouchTarget from "../NonPassiveTouchTarget";
import TouchCarousel, { clamp } from "react-touch-carousel";
import touchWithMouseHOC from "react-touch-carousel/lib/touchWithMouseHOC";
import data2 from "../data2";
import { AiOutlineDown, AiOutlineRight, AiOutlineClose } from "react-icons/ai";


const KeyCountry =  [
  {key: "PE", label: "Perú", countryNumber: "+51"},
  {key: "MX", label: "México", countryNumber: "+52"},
  {key: "BO", label: "Bolivia", countryNumber: "+591"},
  {key: "EC", label: "Ecuador", countryNumber: "+593"},
  {key: "CO", label: "Colombia", countryNumber: "+57"},
  {key: "CL", label: "Chile", countryNumber: "+56"},
  {key: "SV", label: "El Salvador", countryNumber: "+503"},
  {key: "CR", label: "Costa Rica", countryNumber: "+506"},
  {key: "PR", label: "Puerto Rico", countryNumber: "+1"},
  {key: "HN", label: "Honduras", countryNumber: "+504"},
  {key: "UY", label: "Uruguay", countryNumber: "+598"},
  {key: "PY", label: "Paraguay", countryNumber: "+595"},
  {key: "GT", label: "Guatemala", countryNumber: "+502"},
  {key: "PA", label: "Panamá", countryNumber: "+507"},
  {key: "ES", label: "España", countryNumber: "+34"},
  {key: "NI", label: "Nicaragua", countryNumber: "+505"},
  {key: "DO", label: "República Dominicana", countryNumber: "+1"},
  {key: "BR", label: "Brasil", countryNumber: "+55"},
  {key: "US", label: "Estados Unidos", countryNumber: "+1"}
]

const Container2 = touchWithMouseHOC(CarouselContainer2);

const cardSize = 300;
const cardSize2 = 280;
const cardPadCount = 2;
const carouselWidth = clamp(window.innerWidth, 0, 960);


function CarouselContainer2(props) {
  const {
    cursor,
    carouselState: { active, dragging },
    ...rest
  } = props;
  let current = -Math.round(cursor) % data2.length;
  while (current < 0) {
    current += data2.length;
  }
  // Put current card at center
  const translateX =
    (cursor - cardPadCount) * 300 + (carouselWidth - 300) / 2;
  return (
    <NonPassiveTouchTarget
      className={cx("carousel-container", {
        "is-active": active,
        "is-dragging": dragging,
      })}
      style={{height: "450px"}}
    >
      <NonPassiveTouchTarget
        className="carousel-track"
        style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
        {...rest}
      />

      <div className="carousel-pagination-wrapper">
        <ol className="carousel-pagination">
          {data2.map((_, index) => (
            <li key={index} className={current === index ? "current" : ""} />
          ))}
        </ol>
      </div>
    </NonPassiveTouchTarget>
  );
}

class Funnel5 extends Component {
  constructor() {
    super();

    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    this.state = {
      pricingAPI: {},
      slugBootcamp: "webfullstack",
      bootcampSlugMensual: "webfullstack-2p",
      Temario: "",
      Programa: "",
      isOpen: false,
      countryCode: "PE",
      Email: "",
      Phone: "",
      Name: "",
      isOpenTemario: false,
      isPdfPrice: false,
      langUS: false,
      isPlaying: false,
      isOpenPlanPay: false,
      price_mensual_regular: "",
      price_completo_regular: "",
      price_completo: "",
      price_mensual: "",
      titlePricePre: "",
      demoTitle: "",
      demoUrl: "",
      demoPreview: "",
      fechaLimite:  '2024-07-12 19:00:00',
      DescuentoBody: "",
      visitasNumber: this.getRandomVisitasNumber(),
      videoRef: React.createRef(),
      isOpenLead: false,
      loadingEnroll: false,
      demo_alt: false,
      body_intro: false,
      select_mes: "",
      DataFechas: [],
      select_turno: "",
      isOpenResume: false,
      Turnos: [
        {key: "noche", label: "Noche"},
        {key: "tarde", label: "Tarde"},
    ],
      durationVideo: 0,
      durationPercen: 0,
      registerRequerid: false,
      videoInit: false,
      selectFecha: false,
      isModalWS: false,
      optionPlanPago: "0",
      showMore: false,
      KeysPlanes: [
        {"label": "Elegir Entrada", "key": "0"},
        {"label": "Entrada Gratuita", "key": "1"},
        {"label": "⭐ Entrada VIP ⭐ ", "key": "2"}
      ]
    };
    this.GetLinkWS = this.GetLinkWS.bind(this);
    this.openApp = this.openApp.bind(this);
    this.openModalEmail = this.openModalEmail.bind(this);
    this.onRenderOption = this.onRenderOption.bind(this);
    this.DownloadPdf = this.DownloadPdf.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.DynamicCountry = this.DynamicCountry.bind(this);
    
  }


  onRenderSelectionPais( selected, settings, deselect ){
    return 	(<span style={{ marginRight: 10, padding: 5 }}>
{ selected ?
        <span style={{"color": "black"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2.3em',
                height: '2.3em',
                padding: '7px'
            }}
            title="país"
    />  {selected.countryNumber}
        </span>
        :
           <span style={{padding: 5}}>País</span>
    }
    
<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
</span>)
}



  onRenderOptionPlan ( option, index ) {
    return (<li key={index} style={{padding: "8px", fontSize: "20px", fontWeight: "bold"}}>
            { option.label }
            </li>)
}



onRenderSelectionPlan( selected, settings, deselect ){
    return 	(<span style={{ marginRight: 10, padding: 5 }}>
{ selected ?
        <span style={{"color": "white", padding: "10px", fontSize: "20px", fontWeight: "bold"}}>
           {selected.label}
        </span>
        :
           <span style={{padding: 5}}>Selecciona tu forma de pago</span>
    }
<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
</span>)
}


  onSubmit(e) {
    // e.preventDefault();
    // if (this.state.Email.trim() === "") {
    //   toast.warning("El campo Email es requerido.");
    //   return;
    // }

    // if (this.state.Phone.trim() === "") {
    //   toast.warning("El campo WhatsApp es requerido.");
    //   return;
    // }


    // if (this.state.optionPlanPago === "0") {
    //   toast.warning("Selecciona tu entrada");
    //   return;
    // }

    this.setState({ loadingEnroll: true, sendDataForm: true });

    // localStorage.setItem("email", this.state.Email);
    localStorage.setItem('name', this.state.Name);
    localStorage.setItem('phone', this.state.Phone);

    setTimeout(() => {
      const link = this.GetLinkWS();
      this.setState({loadingEnroll: false, isModalWS: true });
      window.fbq("track", "contact");
      window.open(link, '_blank').focus();
    }, 2000);

    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: true,
        bootcamp: "Web full stack",
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        phone: this.state.Phone,
        Token: "c3b4b89c",
        landing: 'funnel-5'
      })
      .then((res) => {
        
        console.log("");
        // const link = this.GetLinkWS();
        // this.setState({loadingEnroll: false });
        // window.fbq("track", "contact");
        // window.open(link, '_blank').focus();
 
      });
  }

  getRandomVisitasNumber() {
    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    return rango[Math.floor(Math.random() * rango.length)];
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  DynamicCountry(country = "") {
    if (country) {
      this.setState({ countryCode: country });
    }
    this.setState({
      isPricePais: false,
      utc_offset: "-0500",
      utc_dif: 0,
      price_mensual:
        this.state.pricingAPI[this.state.bootcampSlugMensual]["price"]["usd"][
          "price_view_final"
        ],
      price_mensual_regular:
        this.state.pricingAPI[this.state.bootcampSlugMensual]["price"]["usd"][
          "price_view_base_regular"
        ],
      titlePrice: this.state.pricingAPI[this.state.slugBootcamp]["titlePrice"],
      price_completo:
        this.state.pricingAPI[this.state.slugBootcamp]["price"]["usd"][
          "price_view_base"
        ], //495
      price_completo_regular:
        this.state.pricingAPI[this.state.slugBootcamp]["price"]["usd"][
          "price_view_base_regular"
        ],
        DataFechas: this.state.pricingAPI[this.state.slugBootcamp]['fechas'],
        select_mes:  this.state.pricingAPI[this.state.slugBootcamp]['fechas'][0]['mes'],
        select_turno: this.state.pricingAPI[this.state.slugBootcamp]['fechas'][0]['turno'],
    });

    try {
      if (this.state.pricingAPI[this.state.slugBootcamp].price[country]) {
        this.setState({
          isPricePais: true,
          price_completo:
            this.state.pricingAPI[this.state.slugBootcamp]["price"][country][
              "price_view_base"
            ], //495
          price_completo_regular:
            this.state.pricingAPI[this.state.slugBootcamp]["price"][country][
              "price_view_base_regular"
            ],
          price_mensual:
            this.state.pricingAPI[this.state.bootcampSlugMensual]["price"][
              country
            ]["price_view_final"],
          price_mensual_regular:
            this.state.pricingAPI[this.state.bootcampSlugMensual]["price"][
              country
            ]["price_view_base_regular"],
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  renderPrice(price, prueba = false) {
    return (
      <>
        <div className="row" style={{ placeContent: "center" }}>
          <div className="col-12">
            <p
              style={{
                fontSize: "45px",
                color: prueba ? "black" : "white",
                fontWeight: "bold",
                paddingTop: "9px",
                paddingBottom: "9px",
              }}
            >
              {price}
              <span
                style={{
                  display: "inline-block",
                  fontSize: window.type_device ? "20px" : "25px",
                  marginLeft: "5px",
                }}
              >
                {" "}
              </span>
            </p>
          </div>
        </div>
      </>
    );
  }
  onRenderOption(option, complete) {
    return (
      <li>
        <ReactCountryFlag
          countryCode={option.key}
          svg
          style={{
            width: "2em",
            height: "2em",
            padding: "1px",
          }}
          title="país"
        />{" "}
        {complete ? option.label : option.key}
      </li>
    );
  }

  onRenderSelection(selected, complete) {
    return (
      <span style={{ padding: 2 }}>
        {selected ? (
          <span style={{ color: "black" }}>
            <ReactCountryFlag
              countryCode={selected.key}
              svg
              style={{
                width: "2.3em",
                height: "2.3em",
                padding: "2px",
              }}
              title="país"
            />{" "}
            {complete ? selected.label : selected.key}
          </span>
        ) : (
          <span style={{ padding: 5 }}></span>
        )}
      </span>
    );
  }

  GetLinkWS() {
    if (window.type_device) {
      return `https://api.whatsapp.com/send?phone=51935489552&text=Hola, me gustaría acceder al programa online 'Introducción al Desarrollo Web Full Stack'. ¡Gracias!`;
    } else {
      return `https://web.whatsapp.com/send?phone=51935489552&text=Hola, me gustaría acceder al programa online 'Introducción al Desarrollo Web Full Stack'. ¡Gracias!`;
    }
  }

  openModalEmail() {
    this.setState({ Phone: "" });
    this.setState({ isOpenTemario: !this.state.isOpenTemario });
  }

  openApp(){
    const link = this.GetLinkWS();
    window.open(link, '_blank').focus();
  }

  DownloadPdf(e) {
    e.preventDefault();

    if (this.state.Email === "") {
      toast.warning("El campo Email es requerido.");
      return;
    }

    localStorage.setItem("email", this.state.Email);

    toast.success(
      !this.state.langUS
        ? "Hola!, enviamos el programa por Email, gracias!"
        : "Hello! We sent the program by Email, thank you!"
    );

    this.openModalEmail();

    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: true,
        bootcamp: this.state.slugBootcamp,
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        Token: "c3b4b89c",
      })
      .then((res) => {
        window.gtag_report_conversion();
        // window.location.href = `/inscripcion/fullstack/${this.state.countryCode}`;
        // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);
      });
  }


  onRenderOption ( option, complete ) {
    return (
        <li>
        <ReactCountryFlag
            countryCode={option.key}
            svg
            style={{
                width: '1.5em',
                height: '1.5em',
                padding: '1px'
            }}
            title="país"
        />  {option.key }
        </li>
            )
}

onRenderSelection( selected, complete ){
    return 	(<span style={{ padding: 2 }}>
    { selected ?
        <span style={{"color": "black"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2em',
                height: '2em',
                padding: '2px'
            }}
            title="país"
    />
        </span>
        :
           <span style={{padding: 5}}></span>
    }
</span>)
}

renderCard2(index, modIndex) {
  const item = data2[modIndex];
  return (
      <div
      key={index}
      className="carousel-card"
      >
      <div style={{width: "275px"}}>
         <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "23px", "background": "white"}}>
              <h4>
              {item.title}
              </h4>
              <img alt={item.title} height="130px" src={item.background} />
          </div>
      </div>
      </div>
  );
  }


renderPais(complete=false){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > Zona horaria </span>
            <Selectrix
        height={300}
        onRenderOption={(option) => this.onRenderOption(option, complete)}
        onRenderSelection={(option) => this.onRenderSelection(option, complete)}
        placeHolderInside={true}
        placeholder={"País"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.countryCode}
        options={KeyCountry}
  
    />
        </>

    )
}




renderMes(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > {!this.state.langUS ? "Mes:": "Month:"} </span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={"Todos"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_mes}
        options={this.state.DataFechas.map((item) => ({key: item.mes, label: item.mes})) }
        onChange={value => this.setState({select_mes: value.key})}
    />
        </>

    )
}

renderTurno(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} >{!this.state.langUS ? "Turno": "Shift"}:</span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={!this.state.langUS ? "Todos": "All" }
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_turno}
        options={this.state.Turnos}
        onChange={value => this.setState({select_turno: value.key})}
    />
        </>

    )
}

    renderHorario(){
    if (this.state.utc_offset === "-0500") {
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 9:35 PM</p>
    } else if (this.state.utc_offset === "-0600") {
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">6:00 PM a 8:35 PM</p>
    } else if (this.state.utc_offset === "-0400"){
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:35 PM</p>
    }  else {
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 9:35 PM</p>
    }
}
      renderHorarioFinSemana(){
        if (this.state.utc_offset === "-0500") {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 AM a 11:35 AM</p>
        } else if (this.state.utc_offset === "-0600"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 AM a 10:35 AM</p>
        } else if (this.state.utc_offset === "-0400"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:35 M</p>
        } else {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 AM a 11:35 AM</p>
        }
    }

    renderHorarioFinSemanaTarde(){
        return (
        <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">05:00 PM a 7:35 PM</p>
        )
    }

renderFechaSelect(item, key){
    return (
        <div key={key} className="row row--35 align-items-center rn-address" data-aos="fade-up"  style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}} >
        <div className="col-12 mb-3">

            <h2 style={{marginBottom: "0px", fontSize: window.type_device && "39px" }}>
                {item.fecha_inicio}
            </h2>
            <div style={{width: window.type_device ? "200px": "450px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "20px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

            <div className="row">
                <div className="col-lg-2 col-md-3 col-12  mt-2">
                <span style={{padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                <FiVideo/> {!this.state.langUS ? "En vivo Gratuito": "Live"}
                                </span>
                </div>
                <div className="col-md-4 col-lg-3 col-12  mt-2">
                    <span style={{"padding-top": "4px","padding-bottom": "4px", "font-size": "18px", "color":"white", "background-color": "slateblue", "border-radius": "8px", "padding-left": "8px", "padding-right": "8px"}}>
                        Plazas limitadas
                    </span>
                </div>
            </div>

        </div>

        <div className="col-md-3 col-12" style={{height: "110px", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
            <div> <h5 className="mb-2" ><b> {!this.state.langUS ? "Duración": "Duration"} </b></h5> </div>
            <div> <p style={{fontSize: "18px"}}> {!this.state.langUS ? "7 meses": "5 months"}
            <span style={{display: "inline-block"}}></span>
            </p> </div>
            <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "16px"}}>
            <b>{item.fecha_start_end}</b>
            </span>
        </div>

        <div className="col-md-3 col-12" style={{height: "110px", marginTop: window.type_device ? "25px": "none", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
            <div> <h5 className="mb-2"><b> {!this.state.langUS ? "Días": "Days"} </b></h5> </div>
            <div> <p style={{fontSize: "18px"}}> {item.dias}  </p> </div>
        </div>

        <div className="col-md-3 col-12" style={{height: "110px", marginTop: "none"}}>
            <div> <h5 className="mb-2"><b> {!this.state.langUS ? `Horario (${item.turno})`: `Schedule (${item.turno})`} </b></h5> </div>

            <div className="row">
                <div className="col-12">
                {(item.turno == 'noche' || item.turno == 'night') && this.renderHorario() }
                {item.turno == 'mañana'  && this.renderHorarioFinSemana() }
                {item.turno == 'tarde'  && this.renderHorarioFinSemanaTarde() }
                </div>

            </div>
        </div>

        <div className="col-md-3 col-12" style={{marginTop: "30px"}}>
            <div>
            <a href={"#inscripcion"}>
            <button
            style={{lineHeight: "20px", fontSize: "18px", marginBottom: "13px", padding: "15px 30px" }}
            className=" btn-efect">
            ELEGIR FECHA
            </button>
            </a>
            </div>
        </div>

    </div>
    )
  }

renderFechas() {
    return (
        <div>
            {!this.state.select_mes && this.state.DataFechas.map((item, key) => (

                this.state.select_turno ?
                 this.state.select_turno == item.turno && this.renderFechaSelect(item, key)
                :
                this.renderFechaSelect(item, key)

            ))}

            {this.state.select_mes && this.state.DataFechas.map((item, key) => (

                this.state.select_turno ?
                this.state.select_turno == item.turno && this.state.select_mes == item.mes && this.renderFechaSelect(item, key)
               :
                this.state.select_mes == item.mes && this.renderFechaSelect(item, key)


            ))}
      </div>

    )}


  renderForm() {
    return (
      <div data-aos="fade-right" className="col-12 col-md-5 px-1" style={{marginBottom: "25px"}}  id="registro">
     

     <div className="px-1" style={{paddingTop: "10px", paddingBottom: "10px" }}>
{/* 
     <label class="col-12 text-left font-weight-bold" htmlFor="nameDojo">
  <span style={{fontSize: "20px", color: "black", padding: "5px"}}>
  👋 ¡Hola! ¿Cuál es tu nombre?
  </span>
  <input
  id='nameDojo'
  style={{background: "white", marginBottom: "10px"}}
  type="text"
  name="name"
  value={this.state.Name}
  onChange={(e) => this.setState({ Name: e.target.value})}
  placeholder= {"Nombre"}
  />
</label> 

 <label class="col-12 text-left font-weight-bold" htmlFor="nameDojo">
  <span style={{fontSize: "20px", color: "black", padding: "5px"}}>
  📱 Número de WhatsApp para recibir el enlace de Zoom:
  </span>
</label>  */}


    {/* <div className="d-flex justify-content-center mx-3">
                <div className="mx-2 mt-2">
                    <Selectrix
                    className="pais-inscription"
                    height={300}
                    onRenderOption={this.onRenderOption}
                    onRenderSelection={this.onRenderSelectionPais}
                    placeHolderInside={true}
                    placeholder={"País"}
                    customScrollbar={true}
                    searchable={false}
                    materialize={false}
                    defaultValue={this.state.countryCode}
                    options={KeyCountry}
                    />

                </div>

                <div style={{width: "80%"}}>
                <label htmlFor="Phone">
                    
                    <input
                    style={{background: "white"}}
                    type="text"
                    name="phone"
                    id="Phone"
                    value={this.state.Phone}
                    onChange={(e) => {
                        this.setState({ Phone: e.target.value });
                    }}
                    placeholder="N.º WhatsApp"
                    />
                 </label>
    </div>
              
  </div> */}

</div>




     <button
      onClick={() => {
        const link = this.GetLinkWS();
        window.fbq("track", "contact");
        window.open(link, '_blank').focus();
      }}
      disabled={this.state.loadingEnroll}
      id="submit-contacto"
className="btn-efect-live"
type="btn" name="submit-contacto"  style={{ width: "90%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>

{!this.state.loadingEnroll  && <b style={{FontFamily: "'Poppins',sans-serif", fontSize: "25px"}}>
<FaWhatsapp style={{fontSize: "25px", marginRight: "6px", marginBottom: "3px", textTransform: "uppercase"}} />
Acceder al Programa por WhatsApp
    </b> }

{this.state.loadingEnroll  &&
      <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
      style={{fontSize: "20px", marginRight: "8px"}}
      />
}

    </button>

   
  
    <div className="row mt-4 text-center px-3">
          <div className="col-12 mx-0" style={{color: "black", fontSize: "21px", textAlign: "center", fontStyle: "italic"}}>
          <label htmlFor="checkNotify">
              <span>
              Este curso es 100% online y Gratuito.
              </span>
          </label>
          </div>
      </div>


</div>

    )
  }


  render() {
    const TypingAnimation2 =  React.memo(()=>{
      return !this.state.langUS ?
      <Typical
      steps={['Aprende 100% online en vivo', 500 ,'con la guía de tu Mentor.', 1000]}
      loop={Infinity}
      wrapper="h2"/>
      :
      <Typical
      steps={['Learn 100% online live', 500 ,'with the guidance of your Mentor.', 1000]}
      loop={Infinity}
      wrapper="h2"
  />
    },(props,prevProp)=> true ); // this line prevent re rendering
    

    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Evento Online Gratuito - Bootcamp Web Full Stack" />
        {/* End Pagehelmet  */}


      <div className="w-100 dojo-mural">


        <div className="title-mural">



        <div  style={{paddingLeft: "1px",  paddingTop: "1px", textAlign: "center", maxWidth: "590px"}} >
    
    <div className="d-flex">
     <a href={'/'}>
        <img style={{borderRadius: "50%"}} height="45px" class="logo-1" src="/assets/images/logo/dojopy_software.webp" alt="DojoFullStack"/>
    </a>
    <div className="logo_dojopy" style={{color: "white", marginTop: "5px", marginBottom: "5px", fontFamily: "'El Messiri', sans-serif", lineHeight: "19px", "place-self": "center", "margin-left": "5px"}}>

<a href={'/'} style={{color: "white"}}>
<span style={{color: "white", fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "29px": "30px" }}>
  DOJO
</span>
<span style={{color: "white", marginLeft: "1px", fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "13px": "14px", display: "block"}}>
  FULLSTACK
</span>
</a>
</div>
    </div>
    
    {/* <div className="d-flex mt-2">
    <div style={{marginLeft: "5px"}}>
    <FlipCountdown
          size={'small'}
          yearTitle='Year'
          dayTitle='Días'
          hourTitle='Horas'
          minuteTitle='Min'
          secondTitle='Segs'
          monthTitle='Mes'
          hideYear
          hideMonth
          endAt={this.state.fechaLimite} // Date/Time
          />

    </div>
    <img className="live-zoom" src="/assets/images/dojopy/zoom_live.png"/>
    </div> */}

 
      </div>

           <h1 data-aos="fade-top"  className="dojo-h1 pb-4 title-gradient">¡Conviértete en Desarrollador Web Full Stack!</h1>

        </div>

      </div>



<div>

<div className="about-wrapper" style={{paddingTop: window.type_device ? "5px": "50px", paddingBottom: "70px" }} id="webinar">




<div data-aos="fade-right"  className={window.type_device ? "container-fluid ": "container"}>

<h2 className="dojo-h2" style={{color: "black", padding: "10px"}}>
  Obtén un plan probado paso a paso para iniciar tu carrera como Desarrollador Web Full Stack desde cero, incluso si no tienes experiencia previa.
  </h2>

{/* {window.type_device && 
<div style={{display: "flex", justifyContent: "center"}}>
  <video autoPlay height={"600px"} controls src="/assets/images/dojopy/ads1.mp4"> </video>
</div>
} */}


  {/* <div className="row mb-3">
      <div className="col-12 col-md-4 text-center p-3">
      <FaRegCalendarAlt style={{width: "90px", height: "90px"}}/>
      <p className="mt-1 p-2" style={{fontSize: "20px"}} > <b>Viernes 12 de Julio</b> <br/> (Único Día) </p>
      </div>

      <div className="col-12 col-md-4 text-center p-3">
      <img style={{height: window.type_device ? "65px": "80px"}} src="/assets/images/dojopy/zoom_live.png"/>
      <p className="mt-1 p-2" style={{fontSize: "20px"}} >Transmisión en <br/> <b>Vivo por ZOOM</b></p>
      </div>

      <div className="col-12 col-md-4 text-center p-3">
      <IoMdTime style={{width: "90px", height: "90px"}}/>
        <p className="mt-1 p-2" style={{fontSize: "20px"}} >
          <ReactCountryFlag
                countryCode={this.state.countryCode}
                svg
                style={{
                    width: '1.7em',
                    height: '1.7em',
                    padding: '1px'
                }}
                title="país"
            /> 
          {this.state.countryCode === 'MX' ?
            <b> 18:00 PM a 20:00 PM <br/> (2 Horas)</b>:
            <b> 19:00 PM a 21:00 PM <br/> (2 Horas)</b>
          }
          
        </p>
      </div>
  </div> */}

                     
<div className="inner">
    <div className="contact-form--1">
            <div className="row" data-aos="fade-right" >
                <div className="col-12 col-md-6"
                style={{paddingLeft: window.type_device ? "5px": "50px", paddingRight: window.type_device ? "5px": "50px"}}
                >
                <h3 className="mb-2 mt-1 mx-2" style={{fontSize: window.type_device ? "28px": "37px"}}>
                   Accede al Programa Online
                    <span
                     style={{padding: "3px", marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": window.type_device ? "27px": "30px" }}>
                    Gratuito
                    </span>
                </h3>
                <div className=" mx-2" style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>


                {window.type_device && this.renderForm()}


                    <div className="mx-2">
                    <p className="mt-3 mb-2 p-2" style={{fontSize: "28px", fontWeight: "bold", fontFamily: '"Poppins",sans-serif'}}>
                    ¿Qué aprenderás en este programa?
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                    Introducción al Desarrollo Web Full Stack.
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                    Los beneficios de aprender esta habilidad y como impacta tu vida.
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                    Pilares del Desarrollo Frontend y Backend
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                    Metodología de aprendizaje de un Bootcamp.
                    </p>
                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                    Ruta de aprendizaje De Cero a Web Full Stack
                    
                    </p>
              
                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                    Introducción a HTML, CSS Y JavaScript.
                    </p>



                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                    Proyecto final "Crea Tu Primera Página Web"
                    </p>
{/* 
                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "20px"}}>
                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "slateblue"}} /> </span>
                    ¡Obtén una Beca del 50%!
                    </p> */}

                    </div>
                </div>



       {!window.type_device && this.renderForm()}



                </div>
        </div>
        </div>
        

    </div>
  </div>

</div>



<Modal show={this.state.isModalWS} size={window.type_device ? "md": "lg"} onHide={() => this.setState({isModalWS: true})} style={{zIndex: "9999999999"}} >

                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                          <h3 className="mt-3 p-2 text-dark">¡{this.state.Name}, tu entrada está reservada 🎉!</h3>
                            <p className="p-2 text-dark">
                              ¡Gracias por registrarte!
                              Para confirmar tu participación y recibir el enlace de Zoom para el evento, por favor envíanos un mensaje de confirmación a nuestro WhatsApp. ¡Será genial contar contigo!
                            </p>

                            <div className="w-100 m-1">
                              <button onClick={() => {
                                 const link = this.GetLinkWS();
                                 window.open(link, '_blank').focus();
                              }} className="btn-grupo-ws">
                                <FaWhatsapp style={{marginRight: "5px", width: "25px", height: "25px", position: "relative", bottom: "3px"}}/>
                                Confirmar
                                </button>

                                <p>
                                  
                                </p>
                            </div>

                           
                    </div>
                    </div>
                    </Modal.Body>
                </Modal>





    {/* Start About Area */}
    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="benefits" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                            {!this.state.langUS ? "¿Para quién es este Evento?": "Who is this Bootcamp for?"}
                                            </h2>

                                        </div>


                                        <TouchCarousel
                                        component={Container2}
                                        cardSize={cardSize2}
                                        cardCount={data2.length}
                                        cardPadCount={cardPadCount}
                                        loop={true}
                                        autoplay={2e3}
                                        renderCard={this.renderCard2}
                                        /> 

                                    


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}


<div className="container px-1" data-aos="fade-right" >
            <h2 className="title mb-2 mt-1 mx-2" style={{fontSize: window.type_device ? "36px": "37px"}} >
            Testimonios de Nuestros Alumnos
                </h2>
                <span
                     style={{fontStyle: "italic", display: "block", padding: "3px", marginLeft: "7px", "border-radius": "7px", "color": "black", "font-size": window.type_device ? "19px": "30px" }}>
                          Más de 300 Alumnos Satisfechos
                    </span>

</div>
          




  {/* Start About Area */}
  <div className="about-area bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            
                            <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Judith</h3>
                                        <h3 className="title" style={{color: "orange"}}>⭐ Consiguió Empleo</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/alN6nU5a2Vo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>  
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Bladimir</h3>
                                        <h3 className="title" style={{color: "orange"}}>⭐ Consiguió Empleo</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/dDPWdY-S8-0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Tatiana</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/NhOFeo9_rA4?si=_LWhcwSgAOlmF0Od" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Harold Ivan</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/CJWjmlO3_7k?si=0VWDgUmLhp4pyNRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                              

                                {!this.state.showMore &&
                                            <div className="col-12 text-center" style={{marginBottom: "80px"}}>
                                                <button
                                                onClick={() => this.setState({showMore: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {!this.state.langUS ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}



{this.state.showMore &&
                                  <>

                                  <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Stephany</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/330Wxw_z4_0?si=Ms_tnorjQRspKGrw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Ricardo Osorio</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/ZaR2-71Kg9Q?si=pz_7oW3sL6gOP2oG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Diana</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/gpLkbRA4ZrE?si=UV0v9lnJQn2IdwAA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                            <h3 className="title">Testimonio Paul</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/81xtrnKV9Ss" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-6 col-12 mt-4 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title pt-4">Testimonio Oscar</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/qtu4qNnBZbI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-4 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title pt-4">Testimonio Andrés</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/Xxif1Dc_ifQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                  </>

}
                              




                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}






<div className="footer_banner" style={{borderTopLeftRadius: "30px", borderTopRightRadius: "30px", zIndex: "99", padding: "1px", background: this.props.FooterColor }}>
                    <div className="row dojofoter" style={{
                        marginTop: !window.type_device ? "3px" : "2px",
                        marginBottom: !window.type_device ? "3px": "3px",
                        paddingLeft: !window.type_device ? "250px" : "15px",
                        paddingRight: !window.type_device ? "250px": "15px",
                        paddingTop: !window.type_device ? "7px": "3px",
                        paddingBottom: !window.type_device ? "7px": "3px"
                        }}>
<div  className={"col-12 py-0 px-0 m-1"} style={{ alignItems: "center", textAlign: "center", alignSelf: "center"}} >
<a href={'#registro'}>
<button
 onClick={() => {
  const link = this.GetLinkWS();
  window.fbq("track", "contact");
  window.open(link, '_blank').focus();
}}
id="enroll_bootcamp_2"
style={{lineHeight: "25px", fontSize: "23px", padding: "12px 27px", textTransform: "uppercase", fontFamily: "'Poppins',sans-serif" }}
className="btn-efect-live">
<FaWhatsapp style={{fontSize: "25px", marginRight: "6px", marginBottom: "3px"}} />
Accede al Programa por WhatsApp
 </button>
</a>
                    </div>
                    </div>
                </div>





<ToastContainer
                position="bottom-center"
                autoClose={9000}
                zIndex={10000}
                />


      </React.Fragment>
    );
  }

  async componentDidMount() {
    // document.getElementById('whatsapp').style.display='block';

    // window.urlWasi = this.GetLinkWS();

    try {
      const responseIP = await axios.get("https://ipapi.co/json/");
      let dataPriceIP = responseIP.data;
      if (!dataPriceIP.country_code) throw "api error";

      this.setState({countryCode: dataPriceIP.country_code});

  } catch {
      axios.get('https://bdzpehnjfmdq56bcrp6gb4mcru0nxqci.lambda-url.us-east-1.on.aws/').then((response) => {
          let data = response.data;

          this.setState({countryCode: data.country_code});
          }).catch((error) => {
              console.log("error api");
          });
  }



  }
}

export default Funnel5;
