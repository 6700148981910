import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import axios from "axios";
import Selectrix from "react-selectrix";
import Modal from "react-bootstrap/Modal";
import {
  AiOutlineRight,
  AiFillPlayCircle,
  AiFillPauseCircle,
} from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import ReactCountryFlag from "react-country-flag";
import FlipCountdown from "@rumess/react-flip-countdown";
import { GoRocket } from "react-icons/go";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import TestimonialOne from "../../blocks/testimonial/TestimonialOne";
import Typical from 'react-typical';
import { FiCheck, FiClock, FiUser, FiMonitor, FiRadio, FiDownload, FiVideo, FiCheckCircle, FiPackage} from "react-icons/fi";
import { FaRegCalendarAlt, FaWhatsapp } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";
import cx from "classnames";
import NonPassiveTouchTarget from "../NonPassiveTouchTarget";
import TouchCarousel, { clamp } from "react-touch-carousel";
import touchWithMouseHOC from "react-touch-carousel/lib/touchWithMouseHOC";
import data8 from "../data8";



const Container2 = touchWithMouseHOC(CarouselContainer2);

const cardSize = 300;
const cardSize2 = 280;
const cardPadCount = 2;
const carouselWidth = clamp(window.innerWidth, 0, 960);


function CarouselContainer2(props) {
  const {
    cursor,
    carouselState: { active, dragging },
    ...rest
  } = props;
  let current = -Math.round(cursor) % data8.length;
  while (current < 0) {
    current += data8.length;
  }
  // Put current card at center
  const translateX =
    (cursor - cardPadCount) * 300 + (carouselWidth - 300) / 2;
  return (
    <NonPassiveTouchTarget
      className={cx("carousel-container", {
        "is-active": active,
        "is-dragging": dragging,
      })}
      style={{height: "450px"}}
    >
      <NonPassiveTouchTarget
        className="carousel-track"
        style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
        {...rest}
      />

      <div className="carousel-pagination-wrapper">
        <ol className="carousel-pagination">
          {data8.map((_, index) => (
            <li key={index} className={current === index ? "current" : ""} />
          ))}
        </ol>
      </div>
    </NonPassiveTouchTarget>
  );
}

const KeyCountry =  [
    {key: "PE", label: "Perú"},
    {key: "EC", label: "Ecuador"},
    {key: "MX", label: "México"},
    {key: "BO", label: "Bolivia"},
    {key: "CO", label: "Colombia"},
    {key: "PR", label: "Puerto Rico"},
    {key: "SV", label: "El Salvador"},
    {key: "CL", label: "Chile"},
    {key: "CR", label: "Costa Rica"},
    {key: "HN", label: "Honduras"},
    {key: "UY", label: "Uruguay"},
    {key: "GT", label: "Guatemala"},
    {key: "PA", label: "Panamá"},
    {key: "NI", label: "Nicaragua"},
    {key: "PY", label: "Paraguay"},
    {key: "DO", label: "República Dominicana"},
    {key: "BR", label: "Brasil"},
    {key: "US", label: "Estados Unidos"},
    {key: "CA", label: "Canada"},
]

class FunnelWasapBot extends Component {
  constructor() {
    super();

    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    this.state = {
      pricingAPI: {},
      slugBootcamp: "webfullstack",
      bootcampSlugMensual: "webfullstack-2p",
      Temario: "",
      Programa: "",
      isOpen: false,
      countryCode: "PE",
      Email: "",
      Phone: "",
      Name: "",
      isOpenTemario: false,
      isPdfPrice: false,
      langUS: false,
      isPlaying: false,
      isOpenPlanPay: false,
      price_mensual_regular: "US$ 159 mensual",
      price_completo_regular: "",
      price_completo: "",
      price_mensual: "US$ 125",
      titlePricePre: "",
      demoTitle: "",
      demoUrl: "https://dojofullstack.com/assets/video/demo_lite_1.mp4",
      demoPreview: "",
      fechaLimite:  '2024-05-27 22:00:00',
      DescuentoBody: "",
      visitasNumber: this.getRandomVisitasNumber(),
      videoRef: React.createRef(),
      isOpenLead: false,
      loadingEnroll: false,
      demo_alt: false,
      body_intro: false,
      select_mes: "",
      DataFechas: [],
      select_turno: "",
      isOpenResume: false,
      Turnos: [
        {key: "noche", label: "Noche"},
        {key: "tarde", label: "Tarde"},
    ],
      durationVideo: 0,
      durationPercen: 0,
      registerRequerid: false,
      videoInit: false,
      selectFecha: false,
      isModalWS: false,
      optionPlanPago: "0",
      KeysPlanes: [
        {"label": "Elegir Entrada", "key": "0"},
        {"label": "Entrada Gratuita", "key": "1"},
        {"label": "⭐ Entrada VIP ⭐ ", "key": "2"}
      ]
    };
    this.GetLinkWS = this.GetLinkWS.bind(this);
    this.openModalEmail = this.openModalEmail.bind(this);
    this.onRenderOption = this.onRenderOption.bind(this);
    this.DownloadPdf = this.DownloadPdf.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.DynamicCountry = this.DynamicCountry.bind(this);
    
  }


  onRenderOptionPlan ( option, index ) {
    return (<li key={index} style={{padding: "8px", fontSize: "20px", fontWeight: "bold"}}>
            { option.label }
            </li>)
}



onRenderSelectionPlan( selected, settings, deselect ){
    return 	(<span style={{ marginRight: 10, padding: 5 }}>
{ selected ?
        <span style={{"color": "white", padding: "10px", fontSize: "20px", fontWeight: "bold"}}>
           {selected.label}
        </span>
        :
           <span style={{padding: 5}}>Selecciona tu forma de pago</span>
    }
<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
</span>)
}


renderCard2(index, modIndex) {
  const item = data8[modIndex];
  return (
      <div
      key={index}
      className="carousel-card"
      >
      <div style={{width: "275px"}}>
         <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "23px", "background": "white"}}>
              <h4>
              {item.title}
              </h4>
              <img alt={item.title} height="130px" src={item.background} />
          </div>
      </div>
      </div>
  );
  }


  onSubmit(e) {
    e.preventDefault();
    if (this.state.Email.trim() === "") {
      toast.warning("El campo Email es requerido.");
      return;
    }

    if (this.state.Phone.trim() === "") {
      toast.warning("El campo WhatsApp es requerido.");
      return;
    }


    // if (this.state.optionPlanPago === "0") {
    //   toast.warning("Selecciona tu entrada");
    //   return;
    // }

    this.setState({ loadingEnroll: true, sendDataForm: true });

    localStorage.setItem("email", this.state.Email);
    localStorage.setItem('name', this.state.Name);
    // localStorage.setItem('phone', this.state.Phone);

    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: false,
        bootcamp: "Plataforma-WhatsApp",
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        phone: this.state.Phone,
        Token: "c3b4b89c",
        landing: 'funnel-wasap'
      })
      .then((res) => {

        const link = this.GetLinkWS();
        this.setState({loadingEnroll: false });
        window.fbq("track", "contact");
        window.open(link, '_blank').focus();
      });
  }

  getRandomVisitasNumber() {
    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    return rango[Math.floor(Math.random() * rango.length)];
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  DynamicCountry(country = "") {
    if (country) {
      this.setState({ countryCode: country });
    }
    this.setState({
      isPricePais: false,
      utc_offset: "-0500",
      utc_dif: 0,
      price_mensual:
        this.state.pricingAPI[this.state.bootcampSlugMensual]["price"]["usd"][
          "price_view_final"
        ],
      price_mensual_regular:
        this.state.pricingAPI[this.state.bootcampSlugMensual]["price"]["usd"][
          "price_view_base_regular"
        ],
      titlePrice: this.state.pricingAPI[this.state.slugBootcamp]["titlePrice"],
      price_completo:
        this.state.pricingAPI[this.state.slugBootcamp]["price"]["usd"][
          "price_view_base"
        ], //495
      price_completo_regular:
        this.state.pricingAPI[this.state.slugBootcamp]["price"]["usd"][
          "price_view_base_regular"
        ],
        DataFechas: this.state.pricingAPI[this.state.slugBootcamp]['fechas'],
        select_mes:  this.state.pricingAPI[this.state.slugBootcamp]['fechas'][0]['mes'],
        select_turno: this.state.pricingAPI[this.state.slugBootcamp]['fechas'][0]['turno'],
    });

    try {
      if (this.state.pricingAPI[this.state.slugBootcamp].price[country]) {
        this.setState({
          isPricePais: true,
          price_completo:
            this.state.pricingAPI[this.state.slugBootcamp]["price"][country][
              "price_view_base"
            ], //495
          price_completo_regular:
            this.state.pricingAPI[this.state.slugBootcamp]["price"][country][
              "price_view_base_regular"
            ],
          price_mensual:
            this.state.pricingAPI[this.state.bootcampSlugMensual]["price"][
              country
            ]["price_view_final"],
          price_mensual_regular:
            this.state.pricingAPI[this.state.bootcampSlugMensual]["price"][
              country
            ]["price_view_base_regular"],
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  renderPrice(price, prueba = false) {
    return (
      <>
        <div className="row" style={{ placeContent: "center" }}>
          <div className="col-12">
            <p
              style={{
                fontSize: "45px",
                color: prueba ? "black" : "white",
                fontWeight: "bold",
                paddingTop: "9px",
                paddingBottom: "9px",
              }}
            >
              {price}
              <span
                style={{
                  display: "inline-block",
                  fontSize: window.type_device ? "20px" : "25px",
                  marginLeft: "5px",
                }}
              >
                {" "}
              </span>
            </p>
          </div>
        </div>
      </>
    );
  }
  onRenderOption(option, complete) {
    return (
      <li>
        <ReactCountryFlag
          countryCode={option.key}
          svg
          style={{
            width: "2em",
            height: "2em",
            padding: "1px",
          }}
          title="país"
        />{" "}
        {complete ? option.label : option.key}
      </li>
    );
  }

  onRenderSelection(selected, complete) {
    return (
      <span style={{ padding: 2 }}>
        {selected ? (
          <span style={{ color: "black" }}>
            <ReactCountryFlag
              countryCode={selected.key}
              svg
              style={{
                width: "2.3em",
                height: "2.3em",
                padding: "2px",
              }}
              title="país"
            />{" "}
            {complete ? selected.label : selected.key}
          </span>
        ) : (
          <span style={{ padding: 5 }}></span>
        )}
      </span>
    );
  }

  GetLinkWS() {
    if (window.type_device) {
      return `https://api.whatsapp.com/send?phone=51935489552&text=Hola, información sobre la Plataforma para WhatsApp.`;
    } else {
      return `https://web.whatsapp.com/send?phone=51935489552&text=Hola, información sobre la Plataforma para WhatsApp.`;
    }
  }

  openModalEmail() {
    this.setState({ Phone: "" });
    this.setState({ isOpenTemario: !this.state.isOpenTemario });
  }

  DownloadPdf(e) {
    e.preventDefault();

    if (this.state.Email === "") {
      toast.warning("El campo Email es requerido.");
      return;
    }

    localStorage.setItem("email", this.state.Email);

    toast.success(
      !this.state.langUS
        ? "Hola!, enviamos el programa por Email, gracias!"
        : "Hello! We sent the program by Email, thank you!"
    );

    this.openModalEmail();

    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: true,
        bootcamp: this.state.slugBootcamp,
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        Token: "c3b4b89c",
      })
      .then((res) => {
        window.gtag_report_conversion();
        // window.location.href = `/inscripcion/fullstack/${this.state.countryCode}`;
        // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);
      });
  }


  onRenderOption ( option, complete ) {
    return (
        <li>
        <ReactCountryFlag
            countryCode={option.key}
            svg
            style={{
                width: '1.5em',
                height: '1.5em',
                padding: '1px'
            }}
            title="país"
        />  {option.key }
        </li>
            )
}

onRenderSelection( selected, complete ){
    return 	(<span style={{ padding: 2 }}>
    { selected ?
        <span style={{"color": "black"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2em',
                height: '2em',
                padding: '2px'
            }}
            title="país"
    />
        </span>
        :
           <span style={{padding: 5}}></span>
    }
</span>)
}

renderPais(complete=false){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > Zona horaria </span>
            <Selectrix
        height={300}
        onRenderOption={(option) => this.onRenderOption(option, complete)}
        onRenderSelection={(option) => this.onRenderSelection(option, complete)}
        placeHolderInside={true}
        placeholder={"País"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.countryCode}
        options={KeyCountry}
        onChange={value => {
            this.DynamicCountry(value.key)}
        }
    />
        </>

    )
}




renderMes(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > {!this.state.langUS ? "Mes:": "Month:"} </span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={"Todos"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_mes}
        options={this.state.DataFechas.map((item) => ({key: item.mes, label: item.mes})) }
        onChange={value => this.setState({select_mes: value.key})}
    />
        </>

    )
}

renderTurno(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} >{!this.state.langUS ? "Turno": "Shift"}:</span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={!this.state.langUS ? "Todos": "All" }
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_turno}
        options={this.state.Turnos}
        onChange={value => this.setState({select_turno: value.key})}
    />
        </>

    )
}

    renderHorario(){
    if (this.state.utc_offset === "-0500") {
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 9:35 PM</p>
    } else if (this.state.utc_offset === "-0600") {
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">6:00 PM a 8:35 PM</p>
    } else if (this.state.utc_offset === "-0400"){
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:35 PM</p>
    }  else {
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 9:35 PM</p>
    }
}
      renderHorarioFinSemana(){
        if (this.state.utc_offset === "-0500") {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 AM a 11:35 AM</p>
        } else if (this.state.utc_offset === "-0600"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 AM a 10:35 AM</p>
        } else if (this.state.utc_offset === "-0400"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:35 M</p>
        } else {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 AM a 11:35 AM</p>
        }
    }

    renderHorarioFinSemanaTarde(){
        return (
        <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">05:00 PM a 7:35 PM</p>
        )
    }

renderFechaSelect(item, key){
    return (
        <div key={key} className="row row--35 align-items-center rn-address" data-aos="fade-up"  style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}} >
        <div className="col-12 mb-3">

            <h2 style={{marginBottom: "0px", fontSize: window.type_device && "39px" }}>
                {item.fecha_inicio}
            </h2>
            <div style={{width: window.type_device ? "200px": "450px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "20px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

            <div className="row">
                <div className="col-lg-2 col-md-3 col-12  mt-2">
                <span style={{padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                <FiVideo/> {!this.state.langUS ? "En vivo Gratuito": "Live"}
                                </span>
                </div>
                <div className="col-md-4 col-lg-3 col-12  mt-2">
                    <span style={{"padding-top": "4px","padding-bottom": "4px", "font-size": "18px", "color":"white", "background-color": "slateblue", "border-radius": "8px", "padding-left": "8px", "padding-right": "8px"}}>
                        Plazas limitadas
                    </span>
                </div>
            </div>

        </div>

        <div className="col-md-3 col-12" style={{height: "110px", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
            <div> <h5 className="mb-2" ><b> {!this.state.langUS ? "Duración": "Duration"} </b></h5> </div>
            <div> <p style={{fontSize: "18px"}}> {!this.state.langUS ? "7 meses": "5 months"}
            <span style={{display: "inline-block"}}></span>
            </p> </div>
            <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "16px"}}>
            <b>{item.fecha_start_end}</b>
            </span>
        </div>

        <div className="col-md-3 col-12" style={{height: "110px", marginTop: window.type_device ? "25px": "none", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
            <div> <h5 className="mb-2"><b> {!this.state.langUS ? "Días": "Days"} </b></h5> </div>
            <div> <p style={{fontSize: "18px"}}> {item.dias}  </p> </div>
        </div>

        <div className="col-md-3 col-12" style={{height: "110px", marginTop: "none"}}>
            <div> <h5 className="mb-2"><b> {!this.state.langUS ? `Horario (${item.turno})`: `Schedule (${item.turno})`} </b></h5> </div>

            <div className="row">
                <div className="col-12">
                {(item.turno == 'noche' || item.turno == 'night') && this.renderHorario() }
                {item.turno == 'mañana'  && this.renderHorarioFinSemana() }
                {item.turno == 'tarde'  && this.renderHorarioFinSemanaTarde() }
                </div>

            </div>
        </div>

        <div className="col-md-3 col-12" style={{marginTop: "30px"}}>
            <div>
            <a href={"#inscripcion"}>
            <button
            style={{lineHeight: "20px", fontSize: "18px", marginBottom: "13px", padding: "15px 30px" }}
            className=" btn-efect">
            ELEGIR FECHA
            </button>
            </a>
            </div>
        </div>

    </div>
    )
  }

renderFechas() {
    return (
        <div>
            {!this.state.select_mes && this.state.DataFechas.map((item, key) => (

                this.state.select_turno ?
                 this.state.select_turno == item.turno && this.renderFechaSelect(item, key)
                :
                this.renderFechaSelect(item, key)

            ))}

            {this.state.select_mes && this.state.DataFechas.map((item, key) => (

                this.state.select_turno ?
                this.state.select_turno == item.turno && this.state.select_mes == item.mes && this.renderFechaSelect(item, key)
               :
                this.state.select_mes == item.mes && this.renderFechaSelect(item, key)


            ))}
      </div>

    )}


  renderForm() {
    return (
      <div data-aos="fade-right" className="col-12 px-1" style={{marginBottom: "25px", maxWidth: "500px"}} >
      <div style={{textAlign: "center", padding: "5px", boxShadow: "rgba(140, 82, 255, 0.9) 4px 4px 15px 3px", border: "none", background: "linear-gradient(135deg, #C56CD6 0%,#3425AF 100%)", borderRadius: "20px" }}>

  <form onSubmit={this.onSubmit} className="px-1" style={{paddingTop: "10px", paddingBottom: "10px" }}>

 <label class="col-12 text-left font-weight-bold" htmlFor="nameDojo">
  <span style={{fontSize: "19px", color: "white"}}>
  Nombre:
  </span>
  <input
  id='nameDojo'
  style={{background: "white", marginBottom: "10px"}}
  type="text"
  name="name"
  value={this.state.Name}
  onChange={(e) => this.setState({ Name: e.target.value})}
  placeholder= {"👋 Hola! ¿Cuál es tu nombre?"}
  />
</label> 

  <label className="col-12 text-left font-weight-bold" htmlFor="item02">
  <span style={{fontSize: "19px", color: "white"}}>
  Email:
  </span>
      <input
          style={{background: "white"}}
          type="email"
          name="email"
          id="item02"
          value={this.state.Email}
          onChange={(e)=>{this.setState({Email: e.target.value});}}
          placeholder="📧 ¿Cuál es tu Email principal?"
          />
  </label>




  <div className="text-left" >
    <b style={{fontSize: "19px", marginLeft: "20px", color: "white"}}>
        WhatsApp:
    </b>
    
</div>

<div className="d-flex justify-content-center mx-3">

<div className="mx-2 mt-2">
    <Selectrix
    className="wasap-form"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    />

</div>

<div style={{width: "100%"}}>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="text"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="N.º de WhatsApp"
    />
 </label>
</div>

</div>



      <button
      id="submit-contacto"
className="btn-efect-wasap"
type="submit" name="submit-contacto"  style={{color: "black", width: "90%", fontSize: "21px", display: "block", margin: "auto", textTransform: "capitalize"}}>

{!this.state.loadingEnroll  && <b style={{fontSize: "25px"}}>
Obtener más información </b> }

{this.state.loadingEnroll  &&
      <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
      style={{fontSize: "20px", marginRight: "8px"}}
      />
}

</button>






  </form>
          </div>
  

</div>

    )
  }


  render() {
    const TypingAnimation2 =  React.memo(()=>{
      return !this.state.langUS ?
      <Typical
      steps={['Aprende 100% online en vivo', 500 ,'con la guía de tu Mentor.', 1000]}
      loop={Infinity}
      wrapper="h2"/>
      :
      <Typical
      steps={['Learn 100% online live', 500 ,'with the guidance of your Mentor.', 1000]}
      loop={Infinity}
      wrapper="h2"
  />
    },(props,prevProp)=> true ); // this line prevent re rendering
    

    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Bootcamp de Marketing con Business Manager" />
        {/* End Pagehelmet  */}


      <div className="w-100 dojo-mural" style={{backgroundImage: 'linear-gradient(rgba(255, 87, 87, 0.2), rgba(140, 82, 255, 0.5)), url("")'}}>


        <div className="title-mural">



        <div  style={{paddingLeft: "1px",  paddingTop: "1px", textAlign: "center", maxWidth: "590px"}} >
    
    <div className="d-flex">
    <img style={{borderRadius: "50%"}} height="45px" class="logo-1" src="/assets/images/logo/dojopy_software.webp" alt="DojoFullStack"/>
    <div className="logo_dojopy" style={{color: "white", marginTop: "5px", marginBottom: "5px", fontFamily: "'El Messiri', sans-serif", lineHeight: "19px", "place-self": "center", "margin-left": "5px"}}>

<a href={'/'}>
<span style={{fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "29px": "30px" }}>
  DOJO
</span>
<span style={{marginLeft: "1px", fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "13px": "14px", display: "block"}}>
  FULLSTACK
</span>
</a>
</div>
    </div>

 
      </div>

    
      <div className="d-flex mt-3">
    <span style={{padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "rgba(88,136,255,1)", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                    {!this.state.langUS ? "CRM": "Live"}
                                    </span>
    </div>


           <h1 className="dojo-h1" style={{paddingTop: "5px"}}>
           Plataforma para <span style={{color: "#00e785", fontFamily: "'Poppins',sans-serif"}}>WhatsApp</span>  Marketing
           </h1>

           <h2 className="dojo-h2">

           Automatiza, personaliza y potencia tus ventas con nuestra plataforma que simplifica el marketing, las ventas, el servicio y la atención al cliente. ¡Haz que tu comunicación destaque y aumenta tus ventas de forma eficiente y efectiva!
            </h2>

        </div>

      </div>





<div>

<div className="about-wrapper" style={{paddingTop: window.type_device ? "25px": "50px", paddingBottom: "70px" }} id="webinar">


<div className="container">




<div className="row">

  <div className="col-12 col-md-7">

  <div className="inner" id="registro" style={{marginBottom: "20px"}}>
    <div className="contact-form--1">
            <div className="row" data-aos="fade-right" >
                <div className="col-12"
                style={{paddingLeft: window.type_device ? "5px": "50px", paddingRight: window.type_device ? "5px": "50px"}}
                >
                <h3 className="mb-2 mt-1" style={{fontSize: window.type_device ? "30px": "37px"}}>
                Solicita más información
                </h3>
                <div style={{width: "100%", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>


                {this.renderForm()}
                  


                
                </div>



            
       
            

                </div>
        </div>
        </div>

  </div>


  <div className="col-12 col-md-5">

  <div className="row mt-3">
                  <div className="col-12">
                      <h3 className="mb-2 mt-1" style={{fontSize: window.type_device ? "30px": "37px"}}>
                      ¿Tienes dudas? Escríbenos por WhatsApp
                    </h3>
                    <div style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                  </div>

                  <div className="col-12">
                             <button onClick={() =>  {
                                const link = this.GetLinkWS();
                                window.fbq("track", "contact");
                                window.open(link, '_blank').focus();
                             } } className="btn-grupo-ws" style={{fontSize: "25px"}}>
                                <FaWhatsapp style={{marginRight: "3px", width: "25px", height: "25px"}}/>
                                Enviar Mensaje Aquí
                                </button>

                                <p className="mt-2" style={{fontStyle: "italic"}}>Responderá de forma instantánea nuestro ChatBot.</p>
                </div>
                </div>


</div>


</div>






<div className="col-12 mt-3 mb-3">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                         
                                            <h2 className="title mb-0">Características</h2>

                                        </div>
                                    </div>
                                </div>


                {/* Start About Area */}
                <div data-aos="fade-left"  className="about-area ptb--10 bg_color--5" id="">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-md-5">
                                    <div className="thumbnail">
                                        <img height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/wasap-1.png" alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        <span style={{padding: "3px", marginLeft: "7px", "background-color": "rgb(88, 136, 255)", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            {!this.state.langUS ? "Plataforma": "Live"}
                                                            </span>
                                            <h2 className="title mb-0">Envía Campañas de Difusión</h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                        </div>

                                          <p style={{fontSize: "20px"}}>
                                            <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          <b>Envíe sus campañas y participe con mensajes de WhatsApp de alta respuesta.</b>
                                            </p>
                                            <p style={{fontSize: "20px"}}>
                                            <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          <b>Etiquete contactos, clasifíquelos y diríjase a ellos en grupos mediante comunicaciones personalizadas.</b>
                                            </p>
                                            <p style={{fontSize: "20px"}}>
                                            <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          <b>Construya y fomente las relaciones que generan ventas futuras interactuando con sus audiencias regularmente en WhatsApp.</b>
                                            </p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




   {/* Start About Area */}
   <div data-aos="fade-left"  className="about-area ptb--70 bg_color--5" id="">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-md-5">
                                    <div className="thumbnail">
                                        <img height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/wasap-2.png" alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        <span style={{padding: "3px", marginLeft: "7px", "background-color": "rgb(88, 136, 255)", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            {!this.state.langUS ? "Plataforma": "Live"}
                                                            </span>
                                            <h2 className="title mb-0">Crea Chatbots</h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                        </div>

                                          <p style={{fontSize: "20px"}}>
                                            <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          <b>Cree chatbots para proporcionar respuestas instantáneas a solicitudes comunes.</b>
                                            </p>
                                            <p style={{fontSize: "20px"}}>
                                            <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          <b>Simplifique la comunicación masiva y las respuestas personalizadas con herramientas automatizadas.</b>
                                            </p>
                                            <p style={{fontSize: "20px"}}>
                                            <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          <b>Aumente las ventas convirtiendo más directamente con comunicaciones con clientes en WhatsApp.</b>
                                            </p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}



                     


   {/* Start About Area */}
   <div data-aos="fade-left"  className="about-area ptb--70 bg_color--5" id="">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-md-5">
                                    <div className="thumbnail">
                                        <img src="/assets/images/dojopy/whatsapp-compatible.png" alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                     
                                            <h2 className="title mb-0">Compatible</h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                        </div>
                                        <p style={{fontSize: "20px"}}>
                                            <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          <b>No es necesario cambiar de aplicación, el proceso de vinculación con la plataforma es sencillo y seguro.</b>
                                            </p>

                                          <p style={{fontSize: "20px"}}>
                                            <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          <b>Compatible con WhatsApp Messenger.</b>
                                            </p>
                                            <p style={{fontSize: "20px"}}>
                                            <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                            <b>Compatible con WhatsApp Business.</b>
                                            </p>
                                           

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

        

    </div>
  </div>

</div>



<Modal show={this.state.isModalWS} size={window.type_device ? "md": "lg"} onHide={() => this.setState({isModalWS: true})} style={{zIndex: "9999999999"}} >

                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                          <h3 className="mt-3 p-2 text-white">¡{this.state.Name}, tu entrada está reservada 🎉!</h3>
                            <p className="p-2 text-white">
                              ¡Gracias por registrarte!
                             Para confirmar tu participación y recibir el enlace de Zoom para el evento, te invitamos a unirte al grupo  de WhatsApp. ¡Será genial contar contigo!
                            </p>

                            <div className="w-100 m-1">
                              <a  href="https://chat.whatsapp.com/KQrHlBfQXrSGZpP4V8NpoU">
                              <button className="btn-grupo-ws">
                                <FaWhatsapp style={{marginRight: "3px", width: "25px", height: "25px"}}/>
                                Unirme al grupo de WhatsApp
                                </button>
                              </a>
                            </div>

                           
                    </div>
                    </div>
                    </Modal.Body>
                </Modal>








<div className="footer_banner" style={{borderTopLeftRadius: "30px", borderTopRightRadius: "30px", zIndex: "99", padding: "1px", background: this.props.FooterColor }}>
                    <div className="row dojofoter" style={{
                        marginTop: !window.type_device ? "3px" : "2px",
                        marginBottom: !window.type_device ? "3px": "3px",
                        paddingLeft: !window.type_device ? "250px" : "15px",
                        paddingRight: !window.type_device ? "250px": "15px",
                        paddingTop: !window.type_device ? "7px": "3px",
                        paddingBottom: !window.type_device ? "7px": "3px"
                        }}>
<div onClick={() => this.setState({isOpenTemario: true})} className={"col-12 py-0 px-0 m-1"} style={{ alignItems: "center", textAlign: "center", alignSelf: "center"}} >
<a href={'#registro'}>
<button
onClick={this.TrackEventConversion}
id="enroll_bootcamp_2"
style={{lineHeight: "25px", fontSize: "20px", padding: "12px 27px" }}
className="btn-efect">
<GoRocket style={{fontSize: "20px", marginRight: "10px"}}/>
Solicita más información
 </button>
</a>
                    </div>
                    </div>
                </div>





<ToastContainer
                position="bottom-center"
                autoClose={9000}
                zIndex={10000}
                />


      </React.Fragment>
    );
  }

  async componentDidMount() {
    document.getElementById('whatsapp').style.display='block';

    window.urlWasi = this.GetLinkWS();

    try {
      const responseIP = await axios.get("https://ipapi.co/json/");
      let dataPriceIP = responseIP.data;
      if (!dataPriceIP.country_code) throw "api error";

      this.setState({countryCode: dataPriceIP.country_code});

  } catch {
      axios.get('https://bdzpehnjfmdq56bcrp6gb4mcru0nxqci.lambda-url.us-east-1.on.aws/').then((response) => {
          let data = response.data;

          this.setState({countryCode: data.country_code});
          }).catch((error) => {
              console.log("error api");
          });
  }



  }
}

export default FunnelWasapBot;
