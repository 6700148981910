import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>

                <div class="row">
                    <div class="col-lg-12 col-12" style={{marginBottom: "15px"}}>
                        <div class="section-title service-style--3 mb--25 mb_sm--0 text-center">
                            <h3 class="title">{!this.props.langUS ? "Empresas donde puedes trabajar": "Companies where you can work"} </h3>
                        </div>
                    </div>
                </div>

                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/google.webp" alt="google"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/aws.webp" alt="aws"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/airbnb.webp" alt="airbnb"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/ml.webp" alt="essence"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/telefonica.webp" alt="telefnocia"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;
