import {
    Accordion,
    AccordionItem as AccordionItemZero,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

import React, { Component ,Fragment } from "react";



class PreguntasEmpleo extends Component{
    render(){
        let bootcampName = 'Desarrollador Web Full Stack';
        if (this.props.bootcampSlug === 'fullstack-web-apps-completo'){
            bootcampName = 'Desarrollador Full Stack Web y Apps Móviles';
        } else if (this.props.bootcampSlug === 'frontend-completo' ){
            bootcampName = 'Desarrollador Frontend con React JS';
        } else if (this.props.bootcampSlug === 'backend-completo' ){
            bootcampName = 'Desarrollador Backend con Python';
        } else if (this.props.bootcampSlug === 'apps-moviles-flutter' ) {
            bootcampName = '';
        } else if (this.props.bootcampSlug === 'cloud-aws' ) {
            bootcampName = 'Desarrollador Cloud AWS';        
        } else {
            bootcampName = 'Desarrollador Web Full Stack';
        }

        return(
            <Fragment>
 <div className="row" id="preguntas-frecuentes">
        <div className="col-12">
            <div  className=" text-center mb--30" style={{marginRight: window.type_device ? "10px": "50px", marginLeft: window.type_device ? "10px": "50px" }}>
                <br/>
                <img height="70px" className="ayudar" src="/assets/images/featured/ayudar.webp" alt="ayudar"/>

                <h2 className="title mb-4 mt-4">{!this.props.langUS ? "Preguntas Frecuentes" : "Frequent questions"} </h2>

                <Accordion  className="accodion-style--1" preExpanded={''}>

<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
 {!this.props.langUS ? "¿Cómo es la metodología online en vivo?" : "How is the live online methodology?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? <>
    En este bootcamp online en vivo tendrás interacción instantánea con tu mentor y compañeros, <br/>
 para resolver todas tus dudas y avanzar en los proyectos. <br/>
 El Mentor como el alumno, deben conectarse ciertos días y horarios de la semana.
</> : <>
In this live online bootcamp you will have instant interaction with your mentor and peers, <br/>
  to solve all your doubts and advance in the projects. <br/>
  The Mentor, like the student, must connect on certain days and times of the week.
</>}

</p>
</AccordionItemPanel>
</AccordionItemZero>


<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "¿Hay algún requisito o necesito conocimientos previos? " : "Are there any requirements or do I need prior knowledge?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? <>
    No te preocupes. Nuestros programas son aptos para principiantes y no requieren ningún conocimiento previo ni hay limite de edad.<br/>
Además, no estarás solo. Nuestro equipo estará a tu lado para guiarte en el proceso, ayudarte a establecer objetivos y a tener éxito.

</> : <>
Don't worry. Our programs are suitable for beginners and do not require any previous knowledge and there is no age limit.<br/>
Plus, you won't be alone. Our team will be by your side to guide you through the process, help you set goals and help you succeed.
</>}

</p>
</AccordionItemPanel>
</AccordionItemZero>


<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
 {!this.props.langUS ? "Si falto a una clase ¿puedo recuperarla?" : "If I miss a class, can I make it up?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? <>
    ¡Por supuesto! Las sesiones son online en vivo, pero las sesiones quedan grabadas en nuestra plataforma <br/>
para que puedas verlas las veces que quieras, ademas tendrás acceso para siempre a la plataforma incluso
cuando finalice el BootCamp para que siga reforzando sus habilidades obtenidas.
</> : <>
Of course! The sessions are live online, but the sessions are recorded on our platform <br/>
so that you can see them as many times as you want, you will also have access to the platform forever even
when BootCamp is over so you can continue to build on your earned skills.
</>}

</p>
</AccordionItemPanel>
</AccordionItemZero>

<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
  {!this.props.langUS ? "¿Otorgan certificados?" : "Do you award certificates?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? <>
    Sí, el certificado es digital y para recibirlo tienes que haber asistido como mínimo <br/>
al 80% de las sesiones en vivo del Bootcamp o completarlo en la Plataforma a tu propio ritmo en el caso de no poder asistir a las sesiones en vivo,<br/> contamos con  múltiples empresas a nivel LATAM
que nos respaldan y avalan.
</> : <>
Yes, the certificate is digital and to receive it you must have attended at least <br/>
to 80% of the live sessions of the Bootcamp, we have multiple companies at the LATAM level
who support and endorse us.
</>}

</p>
</AccordionItemPanel>
</AccordionItemZero>




<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "¿Qué son los desafíos semanales y el proyecto final?" : "What are the weekly challenges and the final project?"} 
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? <>
    Los desafíos son actividades prácticas semanales que se te asignaran en la sesión <br/>
con respecto al tema que estés llevando.<br/><br/>

El proyecto final sirve para certificar el aprendizaje  <br/>
poniéndolo  en práctica creando una réplica de una empresa o Startup real como MercadoLibre o  <br/> Rappi, con tus mentores.  <br/>
El proyecto final es una herramienta increíble para agregar a tu portafolio y  <br/>
así mostrar tu trabajo en tus próximas oportunidades laborales 😎!
</> : <>
The challenges are weekly practical activities that will be assigned to you in the session <br/>
regarding the topic you are taking.<br/><br/>

The final project is developed in the fourth month and serves to certify learning <br/>
putting it into practice by creating a replica of a real company or Startup like MercadoLibre or <br/> Rappi, with your mentors. <br/>
The capstone project is an amazing tool to add to your portfolio and <br/>
so show your work in your next job opportunities 😎!
</>}


</p>
</AccordionItemPanel>
</AccordionItemZero>




<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "Genial, pero ¿me ayudarán a encontrar trabajo?" : "Great, but will you help me find a job?"} 
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>



<p style={{color:'black', cursor: "pointer"}}>
Estamos tan seguros de la calidad de nuestra formación que ofrecemos una garantía de empleabilidad en Tech. Creemos en el poder de nuestra educación y en tu potencial.
</p>


</AccordionItemPanel>
</AccordionItemZero>


<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "¿Qué trabajo podré conseguir después del bootcamp de Desarrollo web? " : "What job can I get after the Web Development bootcamp?"} 
</h3>                                         
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>

{!this.props.langUS ? <>
    El perfil que formamos con este programa es el {bootcampName} <br/>
Aquí listamos las oportunidades laborales más solicitadas segun este perfil: 
<a href="#profile">Ver</a>.
</> : <>
The profile that we form with this program is the Full Stack Web Developer <br/>
Here we list the most requested job opportunities according to this profile:
<a href="#profile">View</a>.
</>}

</p>
</AccordionItemPanel>
</AccordionItemZero>



<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "¿Cuál es el rol de los mentores?" : "What is the role of mentors?"} 
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? <>
    Se te asignará un mentor que estará disponible para responder a tus consultas,  <br/>
tanto en la clase en vivo como cuando finalice la misma, para que puedas acceder <br/>
a un asesoramiento personalizado. También se encarga de corregir tus desafíos semanales.
</> : <>
You will be assigned a mentor who will be available to answer your questions, <br/>
both in the live class and when it ends, so you can access <br/>
to personalized advice. It is also in charge of correcting your weekly challenges.
</>}

</p>
</AccordionItemPanel>
</AccordionItemZero>




<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "¿Inversión? " : "Investment?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
Nuestra tarifa es la más competitiva del mercado, queremos que todos tengan la oportunidad de acceder a una educación de calidad con asistencia personalizada y guiada en vivo por nuestros expertos Developers.

</p>
</AccordionItemPanel>
</AccordionItemZero>



<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "¿Dónde se realiza el pago de la inscripción? " : "Where is the registration payment made?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>

{!this.props.langUS ? <>
    Puedes pagar directamente desde el sitio web 100% seguro, aquí: <br/>
<a href="/#inversion" style={{color: "purple"}}>
Empezar inscripción
</a> <br/>
También a través del N.º WhatsApp:
(+51) 935 489 552
<br/>
<a target="blank" href="https://wa.me/51935489552?text=Hola%20DojoFullStack" style={{color: "purple"}}>
Inscripción por WhatsApp
</a>
</> : <>
You can pay directly from the 100% secure website, here: <br/>
<a href="/#inversion" style={{color: "purple"}}>
start registration
</a> <br/>
Also through WhatsApp No.:
(+51) 935 489 552
<br/>
<a target="blank" href="https://wa.me/51935489552?text=Hi%20DojoFullStack" style={{color: "purple"}}>
Registration by WhatsApp
</a>
</>}



<br/>
</p>
</AccordionItemPanel>
</AccordionItemZero>


<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
 {!this.props.langUS ? "¿Métodos de pago?" : "Payment methods?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "Formas de Pago con tarjeta de  débito o crédito, transferencia o depósito en cuenta, PayPal, Link de Pago, Virtual POS." :
 "Payment methods with debit or credit card, transfer or account deposit, PayPal, Payment Link, Virtual POS."}

</p>
</AccordionItemPanel>
</AccordionItemZero>


<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "¿Cuándo inicia el BootCamp?" : "When does the BootCamp start?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
	{!this.props.langUS ? <>
        Puedes ver todas las fechas disponibles <br/> <a href="#fechas" style={{color: "purple"}}>Ver fecha</a>
</> : <>
You can see all available dates <br/> <a href="#fechas" style={{color: "purple"}}>View date</a>
</>}

</p>
</AccordionItemPanel>
</AccordionItemZero>


<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
 {!this.props.langUS ? "¿Qué es DojoFullStack?" : "What is DojoFullStack?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<>

<>
    <p style={{color:'black', cursor: "pointer"}} >
🚀 Una startup educativa dedicada al desarrollo web Full Stack.
</p>
<p style={{color:'black', cursor: "pointer"}} >
🌏 Accesible desde cualquier lugar del mundo.
</p>
<p style={{color:'black', cursor: "pointer"}} >
💻 Clases 100% online y en vivo.
</p>
</>

</>

</AccordionItemPanel>
</AccordionItemZero>

</Accordion>


            </div>

        </div>
    </div>


            </Fragment>
        )
    }
}




export default PreguntasEmpleo;