import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu, FiVideo } from "react-icons/fi";
import { GiPlatform } from "react-icons/gi";
import Selectrix from "react-selectrix";
import ReactCountryFlag from "react-country-flag";

import { BsFillCaretDownFill } from "react-icons/bs";

const KeyCountry =  [
    {key: "PE", label: "Perú"},
    {key: "MX", label: "México"},
    {key: "BO", label: "Bolivia"},
    {key: "CO", label: "Colombia"},
    {key: "EC", label: "Ecuador"},
    {key: "CL", label: "Chile"},
    {key: "CR", label: "Costa Rica"},
    {key: "PR", label: "Puerto Rico"},
    {key: "SV", label: "El Salvador"},
    {key: "HN", label: "Honduras"},
    {key: "UY", label: "Uruguay"},
    {key: "PY", label: "Paraguay"},
    {key: "GT", label: "Guatemala"},
    {key: "PA", label: "Panamá"},
    {key: "ES", label: "España"},
    {key: "NI", label: "Nicaragua"},
    {key: "DO", label: "República Dominicana"},
    {key: "BR", label: "Brasil"},
    {key: "US", label: "Estados Unidos"}
]


class Header extends Component{
    constructor(props) {
        super(props);
        this.state = {
            activeCounter: false,
            countryCode: 'US'
        }
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
  



    }

    onRenderOption ( option, complete ) {
        return (
            <li>
            <ReactCountryFlag
                countryCode={option.key}
                svg
                style={{
                    width: '1.5em',
                    height: '1.5em',
                    padding: '1px'
                }}
                title="país"
            />  {option.key }
            </li>
                )
    }

    onRenderSelection( selected, isPhone=false ){
        return 	(<span style={{ padding: 2 }}>
		{ selected ?
            <span style={{"color": "black", fontSize: "17px"}}>
                <ReactCountryFlag
                countryCode={selected.key}
                svg
                style={{
                    width: '1.5em',
                    height: '1.5em',
                    padding: '1px'
                }}
                title="país"
        /> {window.type_device ? '': selected.key}
            </span>
            :
               <span style={{padding: 5}}></span>  
        }
	</span>)
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    clickMe = () => {
        this.props.clickMe()
    }

    render(){
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        let paddingConfig = '10px 0px';
        if (window.type_device){
            paddingConfig = '5px 0px';
        }


        return(
            <header className="header-area formobile-menu header--fixed default-color">
                <div className="header-wrapper" id="header-wrapper" style={{padding: this.props.register && paddingConfig}} >
                    <div className="header-left row">
                        <div className="logo">
                            <a href={this.props.langES ? "/es": "/"}>
                                {this.props.register ?
                                    <img className="logo-1" style={{height:"45px", width:"45px"}}  src="/assets/images/logo/dojopy_software.webp" alt="DojoFullStack"/>:
                                    <>
                            <img height={"45px"} className="logo-1" src="/assets/images/logo/dojopy_software.webp" alt="DojoFullStack"/>
                            <img height={"45px"} className="logo-2" src="/assets/images/logo/dojopy_software.webp" alt="DojoFullStack"/>
                                    </>
                                }
                            </a>
                        </div>
                        
                        <div className="logo_dojopy" style={{marginTop: "10px", fontFamily: "'El Messiri', sans-serif", lineHeight: "19px", "place-self": "center", "margin-left": "5px"}}>
                            <a href={this.props.langES ? "/es": "/"}>
                            <span style={{fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "29px": "30px" }}>
                                DOJO
                            </span>
                            <span style={{marginLeft: "1px", fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "13px": "14px", display: "block"}}>
                                FULLSTACK
                            </span>
                            </a>
                        </div>

                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                            
                            {this.props.countryCode && !this.props.langES &&
                            <div style={{width:  window.type_device ? "58px": "80px" , alignSelf: "center", marginRight: "3px" }}>
                            <Selectrix
                            height={300}
                            onRenderOption={(e) => this.onRenderOption(e)}
                            onRenderSelection={(e) => this.onRenderSelection(e)}
                            placeHolderInside={true}
                            placeholder={"País"}
                            customScrollbar={false}
                            searchable={false}
                            materialize={false}
                            defaultValue={this.props.countryCode}
                            options={KeyCountry}
                            onChange={
                                (value) => {
                                    this.props.DynamicCountry(value.key)
                                }
                            }
                            />
                        </div>}


                            {!this.props.langES &&
                            <li className="has-droupdown">
                                <Link to="#bootcamp" >
                                <span style={{paddingRight: "20px"}}>{" "}</span>
                                    Bootcamps
                                    {!window.type_device &&
                                    <BsFillCaretDownFill style={{color: "darkgray", fontSize: "16px", marginLeft: "3px"}}/>
                                    }
                                </Link>

                                <ul className={window.type_device? "submenu active": "submenu"}>

                                <li><a href="/">BootCamp Web Full Stack
                                <span style={{display: "inline-block", marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "17px"}}>
                                <FiVideo/> Live </span>
                                </a></li>

                                {/* <li><a href="https://api.whatsapp.com/send?phone=51935489552&text=Hola%2C%20informes%20sobre%20el%20Bootcamp%20%20Inteligencia%20Artificial%20con%20Python.">
                                    BootCamp Inteligencia Artificial con Python
                                <span style={{display: "inline-block", marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "17px"}}>
                                <FiVideo/> vivo</span>
                                </a></li>  */}

                                {/* <li><a href="/bootcamp-frontend">BootCamp Frontend ReactJS
                                <span style={{display: "inline-block", marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "17px"}}>
                                <FiVideo/> vivo</span>
                                </a></li> */}

                                <li><a href="/bootcamp-backend">BootCamp Backend
                           
                                </a></li>


                                {/* <li><a href="/bootcamp-saas-membresias">BootCamp SaaS & Avazando
                                <span style={{display: "inline-block", marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "17px"}}>
                                <FiVideo/> vivo</span>
                                </a></li> */}
                             
                                <li><a href="/bootcamp-aws">BootCamp Cloud AWS
                           
                                </a></li>


                                </ul>
                            </li>}


                            <li><a href="/testimonios">Testimonios</a></li>
                            <li><a href="/blog">Blog</a></li>
                            

                                <li>
                                        <a className="rn-btn" style={{fontWeight: "bold", fontSize: "15px" }} rel="noreferrer" target="_blank" href={'https://plataforma.dojofullstack.com/login'}>
                                        <GiPlatform style={{marginRight: "7px", fontSize: "23px"}} />
                                            MIEMBROS
                                        </a>
                                </li>


                            </ul>
                        </nav>

                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <div className="row">
                            {this.props.countryCode &&
                            <div style={{width: window.type_device ? "58px": "80px" , alignSelf: "center", marginRight: "3px"  }}>    
                            <Selectrix
                            height={300}
                            onRenderOption={(e) => this.onRenderOption(e, true)}
                            onRenderSelection={(e) => this.onRenderSelection(e, true)}
                            placeHolderInside={true}
                            placeholder={"País"}
                            customScrollbar={false}
                            searchable={false}
                            materialize={false}
                            defaultValue={this.props.countryCode}
                            options={KeyCountry}
                            onChange={
                                (value) => {
                                    this.props.DynamicCountry(value.key)
                                }
                            }
                            />
                        </div>}
                        <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu style={{fontSize: "40px"}} /></span>

                            </div>

                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
     
            </header>

        )
    }

}
export default Header;
