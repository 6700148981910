const data8 = [
  {
    title: "Emprendedores y visionarios",
    background: "/assets/images/icons/idea.webp",
  },
  {
    title: "Si aún no sabes sobre Marketing Digital",
    background: "/assets/images/dojopy/web.webp",
  },
  {
    title: "Quieres cambiar de carrera",
    background: "/assets/images/icons/change.webp",
  },
  {
    title: "Mejora tu currículo con nuevas habilidades",
    background: "/assets/images/icons/curriculum.webp",
  }
];

export default data8;