import React, { Component } from "react";
// import PageHelmet from "../component/common/Helmet";
// import Breadcrumb from "../elements/common/Breadcrumb";
// import Pagination from "../elements/common/Pagination";
// import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { Link } from "react-router-dom";
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { FaRegEye } from "react-icons/fa";
import axios from "axios";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactHtmlParser from 'react-html-parser';
import Moment from 'moment';
import 'moment/locale/es';  

Moment.locale('es');


class BlogDetalle extends Component{
    constructor () {
        super()
        this.state = {
            Post: []
        }
    
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    render(){
        return(
            <React.Fragment>


                <Helmet>
                    <title>{this.state.Post.title}</title>
                    <meta name="description" content={this.state.Post.meta_description}  />
                    <meta
                        property="og:description"
                        content={this.state.Post.meta_description} 
                    />
                   <meta
                    name="twitter:description"
                    content={this.state.Post.meta_description} 
                    />
                    <meta name="keywords" content={this.state.Post.keywords} />
                    <meta property="og:title" content={this.state.Post.title} />
                    <meta property="og:image" content={this.state.Post.meta_image_preview} />
                    <meta name="twitter:image" content={this.state.Post.meta_image_preview} />
                    <link rel="canonical" href={`https://dojofullstack.com/blog/${this.state.Post.slug}`} />

                </Helmet>

                <Header headertransparent="header--transparent" colorblack="color--black" />
                {/* Start Breadcrump Area */}
                <div className="breadcrumb-area rn-bg-color ptb--50 bg_image" data-black-overlay="6" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(243, 17, 224, 0.3)), url(${this.state.Post.image_large})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--70" style={{color: "white"}}>
                                <header>
                                    <h1 className="blog-h1" style={{fontSize: window.type_device ? "33px" : "49px", color: "white" }} >{this.state.Post.title}</h1>
                                </header>
                                    <ul className="page-list">  
                                        <li className="breadcrumb-this.state.Post"><Link to='/blog'>Inicio</Link></li>
                                    </ul>


                                    {this.state.Post.time_read &&
                                    <ul className="page-list">
                                    <li style={{margin: "7px"}}><FiClock style={{fontSize: "20px"}} /> {this.state.Post.time_read} de lectura</li>
                                    {/* <li style={{margin: "7px"}}><FaRegEye style={{fontSize: "20px"}} /> {this.state.Post.counter_views} vistas</li> */}
                                    </ul>                                
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}



                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--25 bg_color--1">
                    <div className="container">

                    <div class="testimonial-thumbnai d-flex">
                                <div class="thumb" ><img style={{borderRadius: "50%"}} height="45px" src={'/assets/images/dojopy/henry_face.webp'} alt="Autor Henry"/></div>
                                <div style={{fontSize: "17px", marginLeft: "15px"}}>
                                <div> <span style={{fontWeight: 600}}>Henry</span> </div>
                                <div> <span style={{fontSize: "17px", fontStyle: "italic", marginRight: "15px"}} >{Moment(this.state.date_created).format('dddd DD MMMM YYYY')}   </span>
                                <span style={{fontSize: "17px", fontStyle: "italic", display: "inline-block" }}><FaRegEye style={{fontSize: "20px", fontStyle: "italic"}} /> {this.state.Post.counter_views}</span>
                                 </div>
                                </div>
                            </div>
                
                <div className="mt-4">
                     <div>{ ReactHtmlParser(this.state.Post.body_content) }</div>
                </div>

                    </div>
                </div>
                {/* End Blog Area */}
                
         
                
            <FooterTwo/>
            </React.Fragment>
        )
    }

    async componentDidMount() {
        const slug = this.props.match.params.slug;
        const response = await axios.get(`https://api.dojopy.com/api/blog/detail/${slug}`);
        this.setState({Post: response.data.data.data});

        document.title = response.data.data.data.title;

        this.sleep(1000).then(r => {
            document.getElementById('whatsapp').style.display='block';
            document.getElementById('iframe_chat').src = 'https://ai.dojopy.com/?config=dojopy-asesor&agente=1&corpus_id=dojopy_data.txt';
            document.getElementById('chat-ai').style.display='block'}
            )

        if (response.data){
            axios.get(`https://api.dojopy.com/api/blog/hit/${slug}`).then((r) => {
                console.log('hit!');
            });
        }
    }
}
export default BlogDetalle;
