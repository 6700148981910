import React, { Component } from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

let TeamContent = [
    {
        images: 'henry_founder.webp',
        title: 'Henry',
        designation: 'FULL STACK DEVELOPER - PERÚ',
        socialNetwork: [
        ]
    },
    {
        images: '18.webp',
        title: 'Camila H.',
        designation: 'FRONTEND DEVELOPER - COLOMBIA',
        socialNetwork: [
        ]
    },
    {
        images: '20.webp',
        title: 'Erick G.' ,
        designation: 'FULL STACK DEVELOPER - MÉXICO',
        socialNetwork: [
        ]
    }
    
];


class TeamFull extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {TeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img height="30%" src={`/assets/images/team/${value.images}`} alt="Blog Images"/>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>

                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default TeamFull;
