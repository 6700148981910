import React ,{ Component } from "react";


const ServiceList = [
    {
        icon: <img className="logo-aws" src="/assets/images/dojopy/undraw_progressive_app_m9ms.svg" alt=""/>,
    title: 'Plataforma virtual',
        description: 'Accede a la plataforma Bootcamp Full stack donde encuentras el RoadMap, módulos y desafíos que se liberan cada semana, además tendrás acceso a todos los módulos para siempre'
    },
    {
        icon: <img className="logo-aws" src="/assets/images/dojopy/undraw_real_time_collaboration_c62i.svg" alt=""/>,
        title: 'Metodología Bootcamp',
        description: 'Esta metodología es con el fin de motivarte a seguir aprendiendo y te garantiza completar el Bootcamp con éxito, no te preocupes si te trabas en las actividades tendrás una sesión personalizada cada semana para completarlo con éxito.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , 15);

        const style = {
            marginTop: '12px',
            color: '#2F2F2F',
            border: '2px solid #2F2F2F'
          };

        return (
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>

                                </div>
                        </div>
                    ))}


                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
