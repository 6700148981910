const data2 = [
    {
      title: "¿Quieres cambiar de carrera?",
      background: "/assets/images/icons/change.webp",
    },
    {
      title: "¿Quieres mejorar tu currículum con nuevas habilidades?",
      background: "/assets/images/icons/curriculum.webp",
    },
    {
      title: "¿Eres emprendedor o visionario?",
      background: "/assets/images/icons/idea.webp",
    },
    {
      title: "¿No sabes mucho sobre tecnologías web?",
      background: "/assets/images/dojopy/web.webp",
    },
    {
      title: "¿Buscas generar más ingresos con una profesión rentable?",
      background: "/assets/images/dojopy/dolar.png",
    }
  ];
  
  export default data2;