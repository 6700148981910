// React Required
import React, { Component, render } from 'react';
import ReactDOM from "react-dom";
// import loadable from '@loadable/component'

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

import BootcampFullStackLive from './home/BootcampFullStackLive';
// import BootcampFullStackLiveWebApp from './home/BootcampFullStackLiveWebApp';
import BootcampFrontend from './home/BootcampFrontend';

// Element Layout
import Blog from "./elements/Blog";
import BlogDetalle from "./elements/BlogDetalle";
// import ServiceDetails from "./elements/ServiceDetails";
import LandingTestimonial from "./elements/LandingTestimonial";
import LandingAWS from "./home/LandingAWS";
import PolicePrivacy from "./elements/PolicePrivacy";
import Inscripcion from "./elements/Inscripcion";
import CertifcateDojopy from "./elements/CertifcateDojopy";
import VisorTemario from "./elements/VisorTemario";
import PoliceReembolso from "./elements/PoliceReembolso";
import WebinarLive from "./elements/WebinarLive";
import error404 from "./elements/error404";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import BootcampBackend from './home/BootcampBackend';
import Funnel4 from './home/funnels/Funnel4';
// import FunnelAWS from './home/funnels/FunnelAWS';
import BootcampFlex from './home/BootcampFlex';
import FunnelWasapBot from './home/funnels/FunnelWasapBot';
import Funnel5 from './home/funnels/Funnel5';
import FunnelStart from './home/funnels/FunnelStart';
import BootcampFullStackLiveEmpleo from './home/BootcampFullStackLiveEmpleo';

serviceWorker.unregister();

// const BootcampFullStackLive = loadable(() => import('./home/BootcampFullStackLive'));


class Root extends Component {

    render(){

        return(
            <HelmetProvider>
            <BrowserRouter basename={'/'}>
                <Switch>
                <Route exact path={`${process.env.PUBLIC_URL}/`} component={BootcampFullStackLiveEmpleo}/>
                <Route exact path={`${process.env.PUBLIC_URL}/garantia-empleabilidad`} component={BootcampFullStackLiveEmpleo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog/:slug`} component={BlogDetalle}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/bootcamp-aws`} component={LandingAWS}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/bootcamp-frontend`} component={BootcampFrontend}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/bootcamp-backend`} component={BootcampBackend}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/testimonios`} component={LandingTestimonial}/>
                    <Route path={`${process.env.PUBLIC_URL}/inscripcion/:bootcamp/:country`} component={Inscripcion}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/politicas-privacidad`} component={PolicePrivacy}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/politicas-reembolso`} component={PoliceReembolso}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/flex`} component={BootcampFlex}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/demostracion-zoom/es`} component={WebinarLive}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/proyecto-rappi`} component={ProjectCourse}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/demo`} component={WebinarLive}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/documental`} component={WebinarLive}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/evento`} component={Funnel4}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/evento-fullstack`} component={Funnel4}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/webinar`} component={Funnel5}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/evento-aws`} component={FunnelAWS}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/whatsapp`} component={FunnelWasapBot}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/marketing-digital`} component={FunnelMk}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/@:user`} component={CertifcateDojopy}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/visor/:slug`} component={VisorTemario}/>
                    <Route component={error404}/>
                </Switch>
            </BrowserRouter>
            </HelmetProvider>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
