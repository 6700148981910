import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp, FiBookOpen } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { GiHamburgerMenu } from "react-icons/gi";
import { GoRocket } from "react-icons/go";
import {FiMail , FiSmartphone, FiCheck, FiClock, FiUser, FiMonitor, FiRadio, FiDownload, FiVideo} from "react-icons/fi";
import { FaGraduationCap, FaWhatsapp, FaCalendarCheck, FaRunning } from "react-icons/fa";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



class LandingTestimonial extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          Email: ''
        }
        this.openModal = this.openModal.bind(this)
        this.onSubmit = this.onSubmit.bind(this);
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    onSubmit(e) {
        e.preventDefault();
        // this.setState({isOpenEmail: !this.state.isOpenEmail});
        // this.openModalCheckout();
        
        if(this.state.Email == ""){
            return;
        }

        toast.success("Te enviaremos el Ebook por Email pronto, gracias!");

        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        {type_email: 'ebook', email: this.state.Email, name: this.state.Name, phone: this.state.Phone, country: this.state.countryCode},)
        .then(res => {
            console.log('ok sent')
        })
    }


    openModal () {
        this.setState({isOpen: true})
    }
    OpenWS(){
        if (window.type_device){
            window.open("https://api.whatsapp.com/send?phone=51935489552&text=Hola%20Dojopy%2C%20informes%20sobre%20el%20BootCamp%20FullStack.%20" + this.state.grupoSelect, '_blank').focus();
        } else {
            window.open("https://web.whatsapp.com/send?phone=51935489552&text=Hola%20Dojopy%2C%20informes%20sobre%20el%20BootCamp%20FullStack.%20" + this.state.grupoSelect, '_blank').focus();
        }
    }
    OpenMeet(){
        window.open("https://calendly.com/dojopy/bootcamp-full-stack", '_blank').focus();
    }
    render(){
        return(
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Testimonios Bootcamp Dojopy' urlSeo="/testimonios" />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--130 pb--70 bg_image bg_image--27"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center">
                                    <h3 style={{color: 'white'}} className="title">
                                    Testimonios
                                    </h3>
                                    <h3 style={{color: 'white'}} className="title pb-4">
                                        ¿Qué dicen nuestros alumnos?
                                    </h3>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}






  {/* Start About Area */}
  <div className="about-area ptb--70 bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            
                            <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Judith</h3>
                                        <h3 className="title" style={{color: "orange"}}>⭐ Consiguió Empleo</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/alN6nU5a2Vo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Bladimir</h3>
                                        <h3 className="title" style={{color: "orange"}}>⭐ Consiguió Empleo</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/dDPWdY-S8-0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Tatiana</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/NhOFeo9_rA4?si=_LWhcwSgAOlmF0Od" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Harold Ivan</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/CJWjmlO3_7k?si=0VWDgUmLhp4pyNRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Stephany</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/330Wxw_z4_0?si=Ms_tnorjQRspKGrw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Ricardo Osorio</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/ZaR2-71Kg9Q?si=pz_7oW3sL6gOP2oG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Diana</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/gpLkbRA4ZrE?si=UV0v9lnJQn2IdwAA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                            <h3 className="title">Testimonio Paul</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/81xtrnKV9Ss" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-6 col-12 mt-4 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title pt-4">Testimonio Oscar</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/qtu4qNnBZbI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-4 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title pt-4">Testimonio Andrés</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/Xxif1Dc_ifQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




                    {/* Start Team Area  */}
  <div className="rn-team-area ptb--100 bg_color--1" id="team" data-aos="fade-right" >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                        <h2 className="title">¿Ya conoces nuestro Bootcamp? </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3" style={{justifyContent: "center"}}>
                                <a className="rn-btn" href="/" style={{fontSize: "18px", padding: "8px"}} onClick={this.DownloadPdf}>
                                <GoRocket style={{fontSize: "18px", marginRight: "10px"}}/>
                                    Visitar Bootcamp
                                </a>
                            </div>
                        </div>
                </div>
                {/* End Team Area  */}



              
                <ToastContainer
                                                    position="bottom-center"
                                                    autoClose={9000}
                                                    />
              

                {/* Start Back To Top */}
                {/* <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div> */}
                {/* End Back To Top */}


                <FooterTwo />

            </React.Fragment>
        )
    }

    async componentDidMount() {
        this.sleep(1000).then(r => {
            document.getElementById('whatsapp').style.display='block';
            }
            )

    }
}
export default LandingTestimonial;
